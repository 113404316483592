import React from 'react'
import { Redirect } from 'react-router-dom'

export const generateRoutes = (routes) =>
  routes.map((route, i) => {
    const RouteTypeComponent = route.type
    switch (RouteTypeComponent) {
      case Redirect:
        return <RouteTypeComponent key={`redirect-${i}`} exact={!!route.exact} from={route.from} to={route.to} />
      default:
        return route.component ? (
          <RouteTypeComponent
            key={[route.component]}
            exact={!!route.exact}
            path={route.path}
            component={route.component}
          />
        ) : (
          <RouteTypeComponent key={[route.component]} exact={!!route.exact} path={route.path} render={route.render} />
        )
    }
  })
