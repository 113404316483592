import React, { useRef, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import menu from 'assets/menu.svg'
import icoEmail from 'assets/ico-email.svg'
import icoName from 'assets/ico-name.svg'
import icoLogin1 from 'assets/ico-login-1.svg'
import icoLogin2 from 'assets/ico-login-2.svg'
import arrow from 'assets/arrow-left.svg'
import logout from 'assets/logout.svg'
import kids from 'assets/kids.svg'
import add from 'assets/add.svg'
import edit from 'assets/edit.svg'
import alert from 'assets/alert.svg'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { authActions, menuActions, userActions } from '_actions'
import { LoginContainer, RegisterContainer } from 'pages'
import { useSelector } from 'react-redux'
import { useOnClick } from '_helpers/useOnClick'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import { authHeader } from '_helpers/authHeader'
import { AlertDialog } from 'components/AlertDialog'
import { SoundToggle } from 'components/SoundToggle'

function Header() {
  const handleToggleMenu = () => {
    dispatch(menuActions.toggleMobileNavigationMenu())
    dispatch(menuActions.hideMobileNavigationLogin())
    dispatch(menuActions.hideMobileNavigationRegister())
    document.body.classList.toggle('overlay')
  }

  const closeOverlay = () => {
    if (document.body.classList.contains('overlay')) {
      dispatch(menuActions.hideMobileNavigationMenu())
      dispatch(menuActions.hideMobileNavigationLogin())
      dispatch(menuActions.hideMobileNavigationRegister())
      setIsShown(false)
      document.body.classList.remove('overlay', 'overlay--zi3')
    }
  }


  const handleToggleLogin = (role) => {
    dispatch(menuActions.toggleMobileNavigationLogin())

    dispatch(menuActions.setRoles(role))
    if (!document.querySelector('.login-bar').classList.contains('open')) {
     document.body.classList.remove('overlay')
    }
  }
  const handleToggleRegister = () => dispatch(menuActions.toggleMobileNavigationRegister())

  const ref = useRef()
  useOnClick(ref, () => closeOverlay())

  const dispatch = useDispatch()
  const history = useHistory()
  const handleLogout = () => {
    dispatch(authActions.logout())
    dispatch(menuActions.toggleMobileNavigationMenu())
    document.body.classList.remove('overlay', 'overlay--zi3')
    setIsShown(false)
    history.push('/')
  }
  useEffect(() => {
    if (history.location.pathname.split('/')[1] === 'security') {
      dispatch(menuActions.toggleMobileNavigationMenu())
      dispatch(menuActions.showMobileNavigationRegister())
      document.body.classList.add('overlay')
      history.location.pathname.split('/')[3] === 'parent' && dispatch(menuActions.setRoles('parent'))
    }
  }, [])

  const [isShown, setIsShown] = useState(false)
  const handleToggleButtonClick = () => {
    if (isShown) return
    setIsShown(true)
    document.body.classList.add('overlay--zi3')
  }

  const handleCloseButtonClick = () => {
    setIsShown(false)
    document.body.classList.remove('overlay--zi3')
  }

  const [children, setChildren] = useState([])
  const [childId, setChildId] = useState(null)

  const parentId = useSelector((state) => state.user.profile?.id)

  const childName = useSelector((state) => state.user.profile?.SelectedChild?.firstName)
  const lastName = useSelector((state) => state.user.profile?.SelectedChild?.lastName)
  const paymentValidUntil = useSelector((state) => state.user.profile?.paymentValidUntil)

  const childrenEndpoint = hasRole(authConstants.ROLE_PARENT)
    ? `${process.env.REACT_APP_API_ENTRYPOINT}/parentChilds/${parentId}`
    : `${process.env.REACT_APP_API_ENTRYPOINT}/therapistOwnTherapies`

  const getChildren = () => {
    fetch(childrenEndpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setChildren(res)
      })
  }

  useEffect(() => {
    parentId && getChildren()
  }, [parentId])

  const handleChange = (e) => {
    setChildId(e.target.value)
    localStorage.setItem(
      'SelectedChild',
      JSON.stringify({
        firstName: e.target.options[e.target.selectedIndex].dataset.firstname,
        lastName: e.target.options[e.target.selectedIndex].dataset.lastname,
        id: e.target.value,
        ageMatchedInquiryId: parseInt(e.target.options[e.target.selectedIndex].dataset.inquiryid),
        therapyId: parseInt(e.target.options[e.target.selectedIndex].dataset.therapyid),
      })
    )
    dispatch(
      userActions.setChildId_R({
        firstName: e.target.options[e.target.selectedIndex].dataset.firstname,
        lastName: e.target.options[e.target.selectedIndex].dataset.lastname,
        id: e.target.value,
        ageMatchedInquiryId: parseInt(e.target.options[e.target.selectedIndex].dataset.inquiryid),
        therapyId: parseInt(e.target.options[e.target.selectedIndex].dataset.therapyid),
      })
    )
  }

  const setChild = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/user/${parentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        selectedChildId: childId,
      }),
    })
  }
  useEffect(() => {
    childId && setChild()
  }, [childId])

  const { isLogged } = useSelector((state) => state.auth)
  const isLogging = useSelector((state) => state.auth.isLogging)

  const profile = useSelector((state) => state.user.profile)
  const isMenuOpen_R = useSelector((state) => state.menu.visibleMobileMenu)
  const isLoginOpen_R = useSelector((state) => state.menu.visibleMobileLogin)
  const isRegisterOpen_R = useSelector((state) => state.menu.visibleMobileRegister)
  const isPremium = useSelector((state) => state.user.profile?.isPremium)
  const roles = useSelector((state) => state.menu.roles)

  return (
    <header className="app-bar" ref={ref}>
      <div className="app-bar__area">
        {!hasRole(authConstants.ROLE_TESTER) && (
          <img className="app-bar__menu-trigger" onClick={handleToggleMenu} src={menu} alt="" />
        )}
        {isLogged && profile ? (
          <>
            <div className="app-bar__user-data">
              <span className="app-bar__user">
                <img src={icoEmail} alt="" />
                {profile?.email}
              </span>
              {!hasRole(authConstants.ROLE_TESTER) && (
                <span className="app-bar__user">
                  <img src={icoName} alt="" />
                  {childName ? `${childName} ${lastName}` : 'brak wybranego dziecka'}
                </span>
              )}
            </div>
            {!hasRole(authConstants.ROLE_TESTER) && <SoundToggle />}
          </>
        ) : null}
        <h1 className="app-bar__title">
          <Link to="/">AsdHelp</Link>
        </h1>

        <div className={classNames('login-bar', isMenuOpen_R && 'open')}>
          {isLogged ? (
            <>
              <div className="form">
                <div className="profile">
                  <div
                    className={classNames(
                      'box__visuals--square-sm box__visuals box',
                      hasRole(authConstants.ROLE_PARENT) ? 'box__visuals--orange' : 'box__visuals--purple'
                    )}
                  >
                    <img className="box__ico" src={hasRole(authConstants.ROLE_PARENT) ? icoLogin1 : icoLogin2} alt="" />
                  </div>
                  <button className="logout-btn" onClick={handleToggleButtonClick}>
                    <img src={logout} alt="" /> <span>Wyloguj </span>
                  </button>
                  <Link to="/profile" className="profile__edit" onClick={handleToggleMenu}>
                    edycja <img src={edit} alt="" />
                  </Link>
                </div>
                <p className="login-content__link">{profile?.email}</p>

                <>
                  <div className="profile__info">
                    <span>Konto:</span> {!isPremium ? 'brak abonamentu' : 'premium'}
                  </div>
                  <div className="profile__info">
                    <span> Ważność: </span>{' '}
                    {!isPremium ? (
                      <Link to="/profile" onClick={handleToggleMenu}>
                        dotknij aby aktywować
                      </Link>
                    ) : (
                      `abonament do ${new Date(paymentValidUntil).toLocaleDateString('pl-PL')}`
                    )}
                  </div>
                  <div className="profile__info-ico">
                    <img src={kids} alt="" /> Dzieci:
                  </div>

                  <div className="profile__info"> Wybrane: </div>

                  <select className="custom-select profile__select" onChange={(e) => handleChange(e)}>
                    <option selected hidden>
                      {childName ? childName : 'Wybierz dziecko:'}
                    </option>
                    {children?.content?.map((item) => (
                      <option
                        key={item.id}
                        value={hasRole(authConstants.ROLE_PARENT) ? item.id : item.Child?.id}
                        data-firstname={hasRole(authConstants.ROLE_PARENT) ? item.firstName : item.Child?.firstName}
                        data-lastname={hasRole(authConstants.ROLE_PARENT) ? item.lastName : item.Child?.lastName}
                        data-inquiryid={item.ageMatchedInquiryId}
                        data-therapyid={item.id}
                      >
                        {hasRole(authConstants.ROLE_PARENT) ? item.firstName : item.Child?.firstName}
                      </option>
                    ))}
                  </select>

                  {children?.content?.map((item) => (
                    <div className="profile__kid" key={item.id}>
                      {hasRole(authConstants.ROLE_PARENT) ? item.firstName : item.Child?.firstName}{' '}
                      {item.therapyConfirmationRequired && <img src={alert} alt="" />}
                      {hasRole(authConstants.ROLE_PARENT) && <Link
                        to={`/profile/add-child/${hasRole(authConstants.ROLE_PARENT) ? item.id : item.Child?.id}`}
                        className="profile__edit"
                        onClick={handleToggleMenu}
                      >
                        edycja <img src={edit} alt="" />
                      </Link>}
                    </div>
                  ))}
                  {hasRole(authConstants.ROLE_PARENT) && (
                    <Link to="/profile/add-child" className="profile__add" onClick={handleToggleMenu}>
                      <img src={add} alt="" /> dodaj dziecko
                    </Link>
                  )}
                </>
              </div>
            </>
          ) : isMenuOpen_R && !isLogging ? (
            <>
              <h2 className="login-bar__title">Zaloguj się</h2>
              <Link
                to
                className="boxes-grid__item box login-content-trigger"
                onClick={() => handleToggleLogin('parent')}
              >
                <div className="box__visuals box__visuals--orange box__visuals--square">
                  <img className="box__ico" src={icoLogin1} alt="" />
                </div>
                <h2 className="box__title">
                  Zaloguj/Zarejestruj się jako <span className="role">rodzic</span>
                </h2>
              </Link>
              <Link to className="boxes-grid__item box">
                <div
                  className="box__visuals box__visuals--purple box__visuals--square"
                  onClick={() => handleToggleLogin('therapist')}
                >
                  <img className="box__ico" src={icoLogin2} alt="" />
                </div>
                <h2 className="box__title">
                  Zaloguj/Zarejestruj się jako <span className="role">terapeuta</span>
                </h2>
              </Link>
            </>
          ) : (
            <>
              <h2 className="login-bar__title">Logowanie...</h2>
              <img className="spinner" src="/images/logo192.png" alt="loading icon" />
            </>
          )}
        </div>

        <div className={classNames('login-content', isLoginOpen_R && 'open')}>
          {isLoginOpen_R ? (
            <>
              <Link to className="login-content__link login-bar-back" onClick={handleToggleLogin}>
                <img className="arrow" src={arrow} alt="" /> Logowanie
                <span className="role">{roles === 'parent' ? 'rodzica' : 'terapeuty'}</span>
              </Link>
              <div
                className={classNames(
                  'box__visuals--square-sm box__visuals box',
                  roles === 'parent' ? 'box__visuals--orange' : 'box__visuals--purple'
                )}
              >
                <img className="box__ico" src={roles === 'parent' ? icoLogin1 : icoLogin2} alt="" />
              </div>
              <LoginContainer />
              <div>
                <p>Nie masz konta ?</p>
                <Link
                  to
                  className={classNames('form__btn btn mt-sm', roles === 'parent' ? 'btn--light' : 'btn--green')}
                  onClick={handleToggleRegister}
                >
                  Zarejestruj się
                </Link>
              </div>
            </>
          ) : null}
        </div>
        <div className={classNames('login-content', isRegisterOpen_R && 'open')}>
          {isRegisterOpen_R ? (
            <>
              <RegisterContainer roles={roles} />
            </>
          ) : null}
        </div>
      </div>

      <AlertDialog
        open={isShown}
        handleClose={handleCloseButtonClick}
        handleAgree={() => {
          handleLogout()
        }}
        title={'Wylogowywanie'}
        text={'Czy na pewno chcesz się wylogować?'}
      />
    </header>
  )
}

export default Header
