import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import reducers from '_reducers'
import { authFlow } from '_sagas/authFlow.saga'

const sagaMiddleware = createSagaMiddleware()
const loggerMiddleware = createLogger()

export const configureStore = () => {
  const store = createStore(reducers, applyMiddleware(sagaMiddleware, thunkMiddleware, loggerMiddleware))
  sagaMiddleware.run(authFlow)

  return store
}
