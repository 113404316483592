import { soundConstants } from '_constants'
import sound1 from 'assets/sound/rising-pops.mp3'
import sound3 from 'assets/sound/disable-sound.mp3'
import sound5 from 'assets/sound/calming-sea-sounds.mp3'
const voice = localStorage.getItem('voice')
const volume = localStorage.getItem('volume')
const playbackRate = localStorage.getItem('playbackRate')
const failureSound = localStorage.getItem('failureSound')
const successSound = localStorage.getItem('successSound')
const backgroundSound = localStorage.getItem('backgroundSound')

const DEFAULT_STATE = {
  voice: voice ? voice : 'female',
  volume: volume ? volume : 0.5,
  playbackRate: playbackRate ? playbackRate : 1,
  successSound: successSound ? successSound : sound1,
  failureSound: failureSound ? failureSound : sound3,
  backgroundSound: backgroundSound ? backgroundSound : sound5,
}

export const soundReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case soundConstants.SET_VOICE:
      return { ...state, voice: action.payload }
    case soundConstants.SET_VOLUME:
      return { ...state, volume: action.payload }
    case soundConstants.SET_PLAYBACK_RATE:
      return { ...state, playbackRate: action.payload }
    case soundConstants.SET_FAILURE_SOUND:
      return { ...state, failureSound: action.payload }
    case soundConstants.SET_SUCCESS_SOUND:
      return { ...state, successSound: action.payload }
    case soundConstants.SET_BACKGROUND_SOUND:
      return { ...state, backgroundSound: action.payload }
    default:
      return state
  }
}
