import React, { useState, useEffect } from 'react'

import { validate } from '_helpers/validate'

export const StringType = ({
  name,
  type,
  label,
  hint,
  value,
  disabled,
  validate: validators,
  setValue,
  setValidator,
  setErrorMessageSetter,
}) => {
  const [state, setState] = useState({
    value: value || '',
    error: { status: false, message: false },
  })

  const handleChange = (e) => {
    const value = e.target.value
    setState((state) => ({ ...state, value }))

    validator(value)
  }

  const validator = (value) => {
    const valid = validate(validators, value)
    setState((state) => ({
      ...state,
      error: { status: !valid.result, message: valid.message },
    }))
    setValue(name, !valid.result ? new Error(valid.message) : value)

    return valid.result
  }

  const setErrorMessage = (message) => {
    setState((state) => ({
      ...state,
      error: { status: true, message },
    }))
  }

  useEffect(() => {
    setValue(name, value)
    setValidator(name, validator)
    setErrorMessageSetter(name, setErrorMessage)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <input
        className="form__input"
        name={name}
        type={type === 'string' ? 'text' : type}
        label={label + (validators.includes('required') ? ' *' : '')}
        value={state.value}
        disabled={disabled}
        onChange={handleChange}
      />
      <label className="form__label">
        {(name === 'firstName' && 'imię') ||
          (name === 'lastName' && 'nazwisko') ||
          (name === 'oldPassword' && 'Akutalne hasło') ||
          (name && name)}
      </label>
      <label className="form__label error">{state.error.status}</label>
      <label className="form__label helperText">{state.error.status ? state.error.message : hint}</label>
    </>
  )
}
