import React from 'react'
import ico from 'assets/ico-1-orange.svg'
import ico2 from 'assets/ico-5-orange.svg'
import ico3 from 'assets/ico-3.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Link } from 'react-router-dom'
export const ResultsIndex = () => {
  return (
    <>
      <Breadcrumbs color="orange" ico={ico} title="Wyniki i historia" backHome={true} />
      <div className="boxes-list">
        <Link to="survey" className="boxes-list__item box">
          <div className="box__visuals box__visuals--orange">
            <img className="box__ico" src={ico} alt="" />
          </div>
          <h3 className="box__title box__title--bold">Ankieta obszarów ryzyka</h3>
        </Link>
        <Link to="game" className="boxes-list__item box">
          <div className="box__visuals box__visuals--orange">
            <img className="box__ico" src={ico2} alt="" />
          </div>
          <h3 className="box__title box__title--bold">Moje dźwięki</h3>
        </Link>
        <Link to="therapy" className="boxes-list__item box">
          <div className="box__visuals box__visuals--orange">
            <img className="box__ico" src={ico3} alt="" />
          </div>
          <h3 className="box__title box__title--bold">Gry terepeutyczne</h3>
        </Link>
      </div>
    </>
  )
}
