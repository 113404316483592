import React from 'react'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-3.svg'
import { useHistory } from 'react-router-dom'
import { restart, selectStartedAt, selectFinishedAt, selectResults, selectGameContent } from '_reducers/newGameSlice'
import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'

export const Results = ({ match }) => {
  const {
    params: { id },
  } = match
  const dispatch = useDispatch()

  const startedAt = useSelector(selectStartedAt)
  const finishedAt = useSelector(selectFinishedAt)
  const results = useSelector(selectResults)
  const gameContent = useSelector(selectGameContent)
  const childId_R = useSelector((state) => state.user.profile?.SelectedChild?.id)

  const history = useHistory()
  const diffInSeconds = Math.abs(finishedAt - startedAt) / 1000
  const seconds = Math.floor(diffInSeconds % 60)

  const handleSolution = (solved) => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/practiceSolution`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        Practice: `/practice/${id}`,
        Child: `/child/${childId_R}`,
        solved: solved,
        solvedAt: new Date(finishedAt),
        startedAt: new Date(startedAt),
      }),
    }).then((res) => {
      if (res.ok) {
        dispatch(restart())
        history.push('/therapeutic-games')
        return res.json()
      } else {
        notification('error', 'Błąd, spróbuj ponownie', 'Błąd')
      }
      throw res.json()
    })
  }

  const handleSubmit = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/practice/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        passed: true,
      }),
    }).then((res) => {
      if (res.ok) {
        notification('success', 'Dodano do opanowanych', 'Komunikat')

        handleSolution(true)
        return res.json()
      } else {
        notification('error', 'Błąd, spróbuj ponownie', 'Błąd')
      }
      throw res.json()
    })
  }

  return (
    <>
      <div className="game-area">
        <Breadcrumbs color="blue" ico={ico} title="Wyniki" grandChild={true} />

        <div className="container container--center">
          <h2 className="page-heading">{gameContent.Practice?.Game.title}</h2>
          {finishedAt !== null && <div className="stats">czas wykonania : {seconds} sec.</div>}
          <div>{results.map((item) => (item === true ? <span>🟢</span> : <span>🔴</span>))}</div>
          <div className="buttons">
            <button className="buttons__item btn btn--green" onClick={() => handleSubmit(true)}>
              zaliczone
            </button>
            <button className="buttons__item btn btn--no-fill" onClick={() => handleSolution(false)}>
              nie zaliczone
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
