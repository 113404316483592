import React, { useState, useEffect } from 'react'
//import { Link, useLocation, Redirect } from 'react-router-dom'
import { Link, useLocation } from 'react-router-dom'
import ico from 'assets/ico-2-4.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import FaceRecognition from 'components/FaceRecognition'
import ErrorBoundary from 'components/ErrorBoundary'
import Timer from 'components/Timer'
import {
  selectGameContent,
  restart,
  toggleAnswer,
  fetchGameContent,
  selectTotalItems,
  next,
  selectCurrentStage,
  start,
  finish,
} from '_reducers/newGameSlice'
import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'

export const GameMimicImitation = ({ match }) => {
  const {
    params: { id },
  } = match
  const location = useLocation()
  const fromSurvey = location.state?.fromSurvey
  const dispatch = useDispatch()
  const therapeutic = location.state?.therapeutic
  const testMode = location.state?.testMode

  const gameContent = useSelector(selectGameContent)
  const totalItems = useSelector(selectTotalItems)
  const currentStage = useSelector(selectCurrentStage)

  function getData() {
    return (dispatch) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/practiceStage/${id}/listAll`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const content = res.content?.[currentStage]
          const totalItems = res.totalItems
          dispatch(fetchGameContent({ content, totalItems }))
        })
    }
  }
  useEffect(() => {
    dispatch(getData())
  }, [currentStage])

  useEffect(() => {
    dispatch(start())
  }, [])

  const [clear, setClear] = useState(false)

  const handleNext = () => {
    dispatch(next())
    setValue(0)
    setClear(true)
    setEnded(false)
    setStarted(true)
  }

  const [value, setValue] = useState(0)
  const [started, setStarted] = useState(false)
  const [ended, setEnded] = useState(false)

  const onchange = () => {
    setValue(1)
  }

  const onstart = () => {
    setStarted(true)
  }

  const score = gameContent?.score

  const onend = () => {
    const result = false
    dispatch(toggleAnswer({ result, id, score }))
    totalItems - 1 === currentStage && testMode && dispatch(finish())
    setEnded(true)
  }

  useEffect(() => {
    const result = true
    if (value === 1) {
      dispatch(toggleAnswer({ result, id, score }))
      totalItems - 1 === currentStage && testMode && dispatch(finish())
      setEnded(true)
    }
  }, [value])

  return (
    <>
      <div className="game-area mb-big">
        <Breadcrumbs color="green" ico={ico} title={gameContent?.Practice?.title} description={gameContent?.Practice?.Game?.title} grandChild={true} />
        <p className="game-description">{gameContent?.Practice?.Game?.text}</p>
        <ErrorBoundary>
          {gameContent?.timeLimit && started && (
            <Timer
              initialSeconds={gameContent?.timeLimit}
              onend={() => {
                onend()
              }}
              ended={ended}
            />
          )}
          <FaceRecognition
            emotion={gameContent?.text}
            fromSurvey={fromSurvey}
            onchange={() => {
              onchange()
            }}
            onstart={() => {
              onstart()
            }}
            ended={ended}
            started={started}
            clear={clear}
          />
        </ErrorBoundary>
        {value === 1 && (
          <>
            {!therapeutic && (
              <Link
                to={fromSurvey ? '/diagnostic-games/survey-games' : '/diagnostic-games/imitation'}
                className="btn survey__btn"
                onClick={() => dispatch(restart())}
              >
                Zakończ
              </Link>
            )}
            {therapeutic && totalItems - 1 > currentStage && (
              <button onClick={handleNext} className="btn survey__btn">
                Następny etap
              </button>
            )}
            {therapeutic && totalItems - 1 === currentStage && !testMode && (
              <Link to={'/therapeutic-games'} className="btn survey__btn" onClick={() => dispatch(restart())}>
                Zakończ ćwiczenie
              </Link>
            )}
            {therapeutic && totalItems - 1 === currentStage && testMode && (
              <Link to={`/therapeutic-games/results/${id}`} className="btn survey__btn">
                Zobacz wyniki
              </Link>
            )}
          </>
        )}
      </div>
    </>
  )
}
