import React, { useState, useEffect } from 'react'
import { authHeader } from '_helpers/authHeader'
import { Add } from './Add'
import { Loader } from 'components/Loader'

export const Edit = ({ match }) => {
  const {
    params: { childId },
  } = match

  const [child, setChild] = useState(null)
  const getChild = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/child/${childId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setChild(res)
      })
  }

  useEffect(() => {
    getChild()
  }, [childId])

  if (child) return <Add match={match} dataResource={child} />
  else
    return (
      <div className="profile-form form">
        <Loader align="center" />
      </div>
    )
}
