import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import Header from 'components/Header'
import { DemoInfo } from 'components/DemoInfo'

export const Page = ({ routes }) => {
  return (
    <>
      <Header />
      <Switch>{routes}</Switch>
      <DemoInfo />
    </>
  )
}

Page.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
}
