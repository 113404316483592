import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs } from 'components/Breadcrumbs'
import arrowRight from 'assets/arrow-right.svg'
import more from 'assets/more.svg'
import ico from 'assets/ico-3.svg'
import { useSelector } from 'react-redux'
import { fetchData } from '_helpers/fetchData'
import { formatRoute } from 'react-router-named-routes'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import classNames from 'classnames'

const tabItems = [
  {
    title: 'Do opanowania',
    passed: null,
  },
  {
    title: 'Opanowane',
    passed: true,
  },
]
const TabItemComponent = ({
  title = '',
  onItemClicked = () => console.error('You passed no action to the component'),
  isActive = false,
}) => {
  return (
    <button className={isActive ? 'tab-menu-link active' : 'tab-menu-link'} onClick={onItemClicked}>
      <span>{title}</span>
    </button>
  )
}
export const TherapeuticGamesIndex = () => {
  const therapyId = localStorage.getItem('therapyId')

  const [isOpen, setIsOpen] = useState(parseInt(therapyId))

  const [active, setActive] = useState(null)

  const childId = useSelector((state) => state.user.profile?.SelectedChild?.id)

  const endPoint = childId ? `/childTherapies/${childId}` : null

  const [state, setState] = useState({
    practices: [],
    therapists: [],
  })

  useEffect(() => {
    loadTherapists()
  }, [childId])

  useEffect(() => {
    therapyId && loadPractices(therapyId)
  }, [])

  const loadPractices = (therapyId) => {
    fetchData(
      `/practice/listAllByTherapy/${therapyId}?order[ord]=ASC&page=1&perPage=24`,
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      setState((state) => ({ ...state, practices: response.content }))
    })
  }

  const handleToggle = (therapyId) => {
    setActive(null)
    setIsOpen(therapyId)
    loadPractices(therapyId)
    localStorage.setItem('therapyId', therapyId)
  }

  const loadTherapists = () => {
    fetchData(endPoint, 'GET', {}, (resp) => resp.json(), false).then((response) => {
      setState((state) => ({ ...state, therapists: response.content }))
    })
  }

  const routes = {
    GAME_IDENTIFICATION: {
      url: '/diagnostic-games/observation/game-identification/:id',
    },
    GAME_SORTING: {
      url: '/diagnostic-games/observation/game-sorting/:id',
    },
    GAME_MAPPING: {
      url: '/diagnostic-games/observation/game-mapping/:id',
    },
    GAME_UNDERSTANDING_SPEECH: {
      url: '/diagnostic-games/recognition/game-understanding-speech/:id',
    },
    GAME_SPEAKING: {
      url: '/diagnostic-games/recognition/game-speaking/:id',
    },
    GAME_EMOTIONS_IDENTIFICATION: {
      url: '/diagnostic-games/identification/game-emotions-identification/:id',
    },
    GAME_MIMIC_IMITATION: {
      url: '/diagnostic-games/imitation/game-mimic-imitation/:id',
    },
    GAME_GESTURES_IMITATION: {
      url: '/diagnostic-games/imitation/game-gestures-imitation/:id',
    },
    GAME_SUBIECTS_IMITATION: {
      url: '/diagnostic-games/imitation/game-subjects-imitation/:id',
    },
    GAME_VERBAL_COMMUNICATION: {
      url: '/diagnostic-games/recognition/game-verbal-communication/:id',
    },
  }

  function getKeyByValue(object, val, type) {
    for (const [key, value] of Object.entries(object)) {
      if (key === val) {
        return value[type]
      }
    }
  }

  const passedCount = state.practices.filter((val) => {
    return val.passed
  })

  return (
    <>
      <Breadcrumbs color="blue" ico={ico} title="Gry terapeutyczne" backHome={true} />
      {!childId && (
        <div className="container">
          <h2 className="page-heading">Brak wybranego dziecka</h2>
        </div>
      )}
      <div className="games-list">
        {state.therapists.map((i, j) => (
          <>
            <div className="games-list__item gl-item" key={`${j}-gl`}>
              <h3 className="gl-item__title">
                {i.Therapist.firstName} {i.Therapist.lastName}
              </h3>
              <p className="gl-item__description">Specjalizacja</p>
              <div className="gl-item__expand" onClick={() => handleToggle(i.id)}>
                <img src={more} alt="" />
                <img className="gl-item__arrow" src={arrowRight} alt="" />
              </div>
            </div>
            {isOpen === i.id ? (
              <div className="games-list__item-content open">
                <div className="tab-menu">
                  {tabItems.map(({ passed, title }) => (
                    <TabItemComponent
                      key={title}
                      title={title}
                      onItemClicked={() => setActive(passed)}
                      isActive={active === passed}
                    />
                  ))}
                </div>
                <div className="tab-bar-content active">
                  {state.practices.map((i, j) => {
                    return active === i?.passed ? (
                      <>
                        <div className={classNames('gl-item', !i?.passed && 'to-do')} key={j}>
                          <h3 className="gl-item__title">{i.title}</h3>
                          <p className="gl-item__description">Ilość etapów : {i.stagesCount}</p>
                          <div className="gl-item__buttons">
                            <Link
                              className="gl-item__btn"
                              to={{
                                pathname: formatRoute(getKeyByValue(routes, i.Game.idname, 'url'), {
                                  id: i.id,
                                }),
                                state: {
                                  fromSurvey: hasRole(authConstants.ROLE_THERAPIST) ? true : false,
                                  therapeutic: true,
                                },
                              }}
                            >
                              Ćwicz
                            </Link>

                            {!i?.passed && hasRole(authConstants.ROLE_THERAPIST) && (
                              <Link
                                className="gl-item__btn gl-item__btn--red"
                                to={{
                                  pathname: formatRoute(getKeyByValue(routes, i.Game.idname, 'url'), {
                                    id: i.id,
                                  }),
                                  state: { fromSurvey: false, therapeutic: true, testMode: true },
                                }}
                              >
                                Test
                              </Link>
                            )}
                          </div>
                        </div>
                        {i?.passed && passedCount.length > 19 && (
                          <div className="gl-item">
                            <Link className="gl-item__btn" to={'/results/therapy'}>
                              Zobacz więcej
                            </Link>
                          </div>
                        )}
                      </>
                    ) : (
                      ''
                    )
                  })}
                </div>
              </div>
            ) : null}
          </>
        ))}
      </div>
    </>
  )
}
