import React from 'react'
import { useSelector } from 'react-redux'

export const DemoInfo = () => {
  const { isLogged } = useSelector((state) => state.auth)
  const isPremium = useSelector((state) => state.user.profile?.isPremium)

  return (
    <>
      {isPremium !== undefined && !isPremium && isLogged && (
        <div className="demo-bar">wersja demonstracyjna aplikacji. Wykup dostęp aby skorzystać z pełni możliwości</div>
      )}
      {!isLogged && (
        <div className="demo-bar">wersja demonstracyjna aplikacji. Wykup dostęp aby skorzystać z pełni możliwości</div>
      )}
    </>
  )
}
