import React, { useState, useEffect } from 'react'
import { authHeader } from '_helpers/authHeader'
import { DropTarget } from 'react-drag-drop-container'
import { notification } from '_helpers/notification'
import useSound from 'use-sound'
import { useSelector } from 'react-redux'
export const AnimalSound = (props) => {
  const gender_R = useSelector((state) => state.sound.voice)
  const volume_R = useSelector((state) => state.sound.volume)
  const playbackRate_R = useSelector((state) => state.sound.playbackRate)
  const [state, setState] = useState({ thankYouMessage: '' })

  const dropped = (e) => {
    e.containerElem.style.visibility = 'hidden'
    setState({
      thankYouMessage: `Chcę zjeść ${e.dragData.label}!`,
    })
    props.onchange()
  }

  const [sound, setSound] = useState()
  const getSound = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/voice/${gender_R}/${state.thankYouMessage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw res.json()
      })
      .then((res) => {
        setSound(`data:audio/mpeg;base64,${res?.content?.[0]?.audio}`)
      })
  }

  useEffect(() => {
    state.thankYouMessage && getSound()
  }, [state.thankYouMessage])

  const [play] = useSound(sound, {
    volume: volume_R,
  })

  useEffect(() => {
    if (sound) {
      !props.diagnostic && notification('success', state.thankYouMessage, 'Komunikat')
      play({ playbackRate: playbackRate_R })
    }
  }, [play])

  return (
    <DropTarget onHit={dropped} targetKey={props.targetKey} dropData={{ name: props.name }}>
      <div className="animal">{props.children}</div>
    </DropTarget>
  )
}
