import { fetchData } from '_helpers/fetchData'

const get = () =>
  fetchData(`${process.env.REACT_APP_API_SCHEMA_PATH}`, 'GET', {}, null, false, {}, false).then(
    (response) => ({ response }),
    (error) => ({ error })
  )

export const schemaService = {
  get,
}
