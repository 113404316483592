import React, { useState, useEffect } from 'react'
import { Link, useLocation, Redirect } from 'react-router-dom'
import { Boxable } from '../../components/DragThingsToBoxes/Boxable'
import { Box } from '../../components/DragThingsToBoxes/Box'
import ico from 'assets/ico-2-1.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { notification } from '_helpers/notification'
import useSound from 'use-sound'
import {
  selectGameContent,
  restart,
  toggleAnswer,
  fetchGameContent,
  selectTotalItems,
  next,
  selectCurrentStage,
  start,
  finish,
} from '_reducers/newGameSlice'
import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'

export const GameMapping = ({ match }) => {
  const {
    params: { id },
  } = match

  const location = useLocation()

  const fromSurvey = location.state?.fromSurvey
  const therapeutic = location.state?.therapeutic
  const testMode = location.state?.testMode
  const gameContent = useSelector(selectGameContent)
  const totalItems = useSelector(selectTotalItems)
  const currentStage = useSelector(selectCurrentStage)

  function getData() {
    return (dispatch) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/practiceStage/${id}/listAll`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const content = res.content?.[currentStage]
          const totalItems = res.totalItems
          dispatch(fetchGameContent({ content, totalItems }))
        })
    }
  }
  useEffect(() => {
    dispatch(getData())
  }, [currentStage])

  useEffect(() => {
    dispatch(start())
  }, [])

  const [array, setArray] = useState([])
  const [clear, setClear] = useState(false)
  const initialArray = ['box1', 'box2', 'box3']

  const onchange = (data) => {
    const temp = [...array]

    data.index === 1 && JSON.stringify(temp) === JSON.stringify(['box3'])
      ? temp.splice(0, 0, data.name)
      : temp.splice(data.index, 0, data.name)

    setArray(temp)
  }

  const successSound_R = useSelector((state) => state.sound.successSound)
  const failureSound_R = useSelector((state) => state.sound.failureSound)
  const [playSuccess] = useSound(successSound_R)
  const [playFailure] = useSound(failureSound_R)

  const score = gameContent?.score

  useEffect(() => {
    if (array.length === 3) {
      if (JSON.stringify(initialArray) === JSON.stringify(array)) {
        const result = true
        if (!fromSurvey) {
          notification('success', 'Dobrze!', 'Komunikat')
          playSuccess()
        }
        dispatch(toggleAnswer({ result, id, score }))
      } else {
        const result = false
        if (!fromSurvey) {
          notification('error', 'Źle!', 'Błąd')
          playFailure()
        }
        dispatch(toggleAnswer({ result, id, score }))
      }
      totalItems - 1 === currentStage && testMode && dispatch(finish())
    }
  }, [array])

  const dispatch = useDispatch()

  const handleNext = () => {
    dispatch(next())
    setArray([])
    setClear(true)
  }

  return (
    <>
      <div className="game-area">
        <Breadcrumbs color="green" ico={ico} title={gameContent?.Practice?.title} description={gameContent?.Practice?.Game?.title} grandChild={true} />
        <p className="game-description">{gameContent?.Practice?.Game?.text}</p>
        <div className="drag_things_to_boxes">
          <div className="boxes">
            <div className="box">
              {gameContent?.ElementB1?.photoThumbs && (
                <img
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementB1?.photoThumbs?.['128x128']}`}
                  alt={gameContent?.ElementB1?.title}
                />
              )}
            </div>

            <div className="box">
              {gameContent?.ElementB3?.photoThumbs && (
                <img
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementB3?.photoThumbs?.['128x128']}`}
                  alt={gameContent?.ElementB3?.title}
                />
              )}
            </div>

            <div className="box">
              {gameContent?.ElementB2?.photoThumbs && (
                <img
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementB2?.photoThumbs?.['128x128']}`}
                  alt={gameContent?.ElementB2?.title}
                />
              )}
            </div>
          </div>
          <div className="boxes">
            <Box
              targetKey="box"
              name="box1"
              data={array}
              index={0}
              onchange={(e) => {
                onchange(e)
              }}
              clear={clear}
            />
            <Box
              targetKey="box"
              name="box2"
              data={array}
              index={1}
              onchange={(e) => {
                onchange(e)
              }}
              clear={clear}
            />
            <Box
              targetKey="box"
              name="box3"
              data={array}
              index={2}
              onchange={(e) => {
                onchange(e)
              }}
              clear={clear}
            />
          </div>
        </div>
        <div className="things_to_drag">
          {gameContent?.ElementD2?.photoThumbs && (
            <Boxable
              targetKey="box"
              label={gameContent?.ElementD2?.title}
              image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementD2?.photoThumbs?.['128x128']}`}
              name="box3"
              clear={clear}
            />
          )}
          {gameContent?.ElementD1?.photoThumbs && (
            <Boxable
              targetKey="box"
              label={gameContent?.ElementD1?.title}
              image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementD1?.photoThumbs?.['128x128']}`}
              name="box1"
              clear={clear}
            />
          )}
          {gameContent?.ElementD3?.photoThumbs && (
            <Boxable
              targetKey="box"
              label={gameContent?.ElementD3?.title}
              image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementD3?.photoThumbs?.['128x128']}`}
              name="box2"
              clear={clear}
            />
          )}
        </div>

        {array.length === 3 && (
          <>
            {!therapeutic && (
              <Link
                to={fromSurvey ? '/diagnostic-games/survey-games' : '/diagnostic-games/observation'}
                className="btn survey__btn survey__btn--mt0"
                onClick={() => dispatch(restart())}
              >
                Zakończ
              </Link>
            )}
            {therapeutic && totalItems - 1 > currentStage && (
              <button onClick={handleNext} className="btn survey__btn survey__btn--mt0">
                Następny etap
              </button>
            )}
            {therapeutic && totalItems - 1 === currentStage && !testMode && (
              <Link
                to={'/therapeutic-games'}
                className="btn survey__btn survey__btn--mt0"
                onClick={() => dispatch(restart())}
              >
                Zakończ ćwiczenie
              </Link>
            )}
            {therapeutic && totalItems - 1 === currentStage && testMode && <Redirect to={`/therapeutic-games/results/${id}`} />}
          </>
        )}
        {therapeutic && (
          <nav className="dots">
            <p className="game-text">Postęp</p>
            {[...Array(totalItems)].map((item, i) =>
              currentStage === i ? <Link to className="dots__item active" /> : <Link to className="dots__item" />
            )}
          </nav>
        )}
      </div>
    </>
  )
}
