import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import TableList from './TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { translate } from '_helpers/translate'
import add from 'assets/add.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-3.svg'

const typingTimeout = 1000

export const List = ({ match }) => {
  const {
    params: { id },
  } = match

  const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/element/listOwn`

  const listqueryParams = {
    search: '',
    stat: '',
    gameId: id,
    page: 1,
    perPage: 12,
    'order[name]': 'ASC',
  }

  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState(listqueryParams)
  const [typingState, setTypingState] = useState({ timeoutId: null })
  const [searchText, setSearchText] = useState(listqueryParams.search ? listqueryParams.search : '')

  const [type, setType] = useState(listqueryParams.type ? listqueryParams.type : '')

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  const handleTypeChange = (e) => {
    const type = e.target.value
    setType(type)
    setQueryParams({
      ...queryParams,
      type: type,
      page: 1,
    })
  }

  return (
    <>
      <Breadcrumbs color="blue-green" ico={ico} title="Biblioteka" grandChild={true} />

      <div className="container-full">
        <div>
          <FormControl style={{ minWidth: '243px', marginRight: '10px' }}>
            <InputLabel>{translate('T_TYPE')}</InputLabel>
            <Select value={type} onChange={handleTypeChange}>
              <MenuItem value="">{translate('T_ALL')}</MenuItem>
              <MenuItem value="TYPE_PHOTO">{translate('T_TYPE_PHOTO')}</MenuItem>
              <MenuItem value="TYPE_AUDIO">{translate('T_TYPE_AUDIO')}</MenuItem>
              <MenuItem value="TYPE_VIDEO">{translate('T_TYPE_VIDEO')}</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label={translate('T_SEARCH')}
            value={searchText}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <TableList
          data={res.state.data ? (res.state.data.content ? res.state.data.content : []) : []}
          isLoading={res.state.isLoading}
          dataLength={res.state.data?.totalItems}
          pagination={true}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          gameId={id}
        />
        <div>
          <Link to={`/media-library/elements/${id}/add/new`} className="profile__add profile__add--fixed">
            <img src={add} alt="" />
          </Link>
        </div>
      </div>
    </>
  )
}
