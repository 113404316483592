import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { useDispatch } from 'react-redux'
import { userActions } from '_actions'
import classNames from 'classnames'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-login-1.svg'

export const TherapistTherapiesList = () => {
  const typingTimeout = 1000

  const listqueryParams = {
    search: '',
    page: 1,
    perPage: 12,
  }
  const dispatch = useDispatch()
  const [queryParams, setQueryParams] = useState(listqueryParams)
  const [typingState, setTypingState] = useState({ timeoutId: null })
  const [searchText, setSearchText] = useState(listqueryParams.search ? listqueryParams.search : '')
  const [searchProgressInfo, setSearchProgressInfo] = useState('')

  const [therapistTherapies, setTherapistTherapies] = useState([])

  const therapistId = useSelector((state) => state.user.profile?.id)

  const [selectedChildId, setSelectedChildId] = useState('')

  const selectedChild = useSelector((state) => state.user.profile?.SelectedChild)

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)

    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  const getTherapistOwnTerapies = () => {
    setSearchProgressInfo('szukam...')
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/therapistOwnTherapies/?search=${queryParams.search}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setSearchProgressInfo('')
        setTherapistTherapies(res.content)
      })
  }

  useEffect(() => {
    getTherapistOwnTerapies()
  }, [queryParams])

  const saveSelectedChildId = (childId) => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/user/${therapistId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        selectedChildId: childId,
      }),
    })
  }

  const handleSetActiveChild = (therapy) => {
    saveSelectedChildId(therapy.Child.id)
    setSelectedChildId(therapy.Child.id)
    localStorage.setItem(
      'SelectedChild',
      JSON.stringify({
        firstName: therapy.Child.firstName,
        lastName: therapy.Child.lastName,
        id: therapy.Child.id,
        therapyId: therapy.id,
      })
    )
    dispatch(
      userActions.setChildId_R({
        firstName: therapy.Child.firstName,
        lastName: therapy.Child.lastName,
        id: therapy.Child.id,
        therapyId: therapy.id,
      })
    )
  }

  const getSelectedChildId = () => {
    if (selectedChildId) {
      return selectedChildId
    }
    return selectedChild?.id
  }

  return (
    <>
      <Breadcrumbs color="dark-purple" ico={ico} title="Dzieci" />
      <form action className="form">
        <div className="form__item">
          <input type="text" className="form__input" value={searchText} onChange={handleSearchChange} />
          <label className="form__label">Filtruj {searchProgressInfo}</label>
        </div>
      </form>

      <div className="results">
        {therapistTherapies.map((therapy, key) => {
          return (
            <div
              className={classNames(
                'results__item r-item checkbox-tile',
                getSelectedChildId() === therapy.Child.id && 'checked'
              )}
              key={therapy.id}
              onClick={() => handleSetActiveChild(therapy)}
            >
              <h4 className="r-item__title">
                {therapy.id}. {therapy.Child.firstName} {therapy.Child.lastName}
              </h4>
              <p className="r-item__description">
                Terapeuta potwierdzony: {therapy.therapistConfirmed && 'TAK'}
                <br />
                Rodzic potwierdzony: {therapy.parentConfirmed && 'TAK'}
                <br />
                Terapia od: {therapy.createdAt.slice(0, 10)}
                <br />
              </p>
            </div>
          )
        })}
      </div>
    </>
  )
}
