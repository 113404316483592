const required = (value) => {
  const result = !!value
  return { result, message: !result && 'Pole wymagane' }
}

const email = (value) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const result = re.test(String(value).toLowerCase())
  return { result, message: !result && 'Niepoprawny adres e-mail' }
}

const password = (value) => {
  const result = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value)
  return {
    result,
    message: !result && 'Hasło musi składać się z min. 8 znaków (duża litera, mała litera, cyfra, znak specjalny)',
  }
}

export const validators = {
  required,
  email,
  password,
}
