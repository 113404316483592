import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import arrow from 'assets/arrow-left.svg'

export const Breadcrumbs = ({
  margin = '',
  color,
  ico,
  title,
  description = null,
  backHome = false,
  grandChild = false,
  doubleBack = false,
}) => {
  let history = useHistory()
  return (
    <div className={`page-bar page-bar--${color} ${margin}`}>
      {backHome ? (
        <Link to="/">
          <img className="page-bar__arrow" src={arrow} alt="" />
        </Link>
      ) : grandChild ? (
        <button onClick={history.goBack}>
          <img className="page-bar__arrow" src={arrow} alt="" />
        </button>
      ) : doubleBack ? (
        <Link to="..">
          <img className="page-bar__arrow" src={arrow} alt="" />
        </Link>
      ) : (
        <Link to=".">
          <img className="page-bar__arrow" src={arrow} alt="" />
        </Link>
      )}
      <img className="page-bar__ico" src={ico} alt="" />
      <div>
        <h2 className="page-bar__title">{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  )
}
