import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { validate } from '_helpers/validate'

export const CheckboxType = ({
  name,
  label,
  hint,
  checked,
  disabled,
  validators,
  setValue,
  setValidator,
  setErrorMessageSetter,
}) => {
  const [state, setState] = useState({
    checked: !!checked,
    error: { status: false, message: false },
  })

  const handleChange = (e) => {
    const checked = !!e.target.checked
    setState((state) => ({ ...state, checked }))

    validator(checked)
  }

  const validator = (value) => {
    const valid = validate(validators, value)
    setState((state) => ({
      ...state,
      error: { status: !valid.result, message: valid.message },
    }))
    setValue(name, !valid.result ? new Error(valid.message) : value)

    return valid.result
  }

  const setErrorMessage = (message) => {
    setState((state) => ({
      ...state,
      error: { status: true, message },
    }))
  }

  useEffect(() => {
    setValue(name, checked)
    setValidator(name, validator)
    setErrorMessageSetter(name, setErrorMessage)
    // eslint-disable-next-line
  }, [])

  return (
    <FormControlLabel
      label={label + (validators.includes('required') ? ' *' : '')}
      control={
        <Checkbox name={name} checked={state.checked} disabled={disabled} onChange={handleChange} color="primary" />
      }
    />
  )
}
