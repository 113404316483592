import { put, call } from 'redux-saga/effects'
import { schemaConstants } from '_constants'
import { schemaService } from '_services'
import { handleAuthError } from '_helpers/handleAuthError'
import { processError } from '_helpers/processError'

function* fetch() {
  yield put({ type: schemaConstants.SCHEMA_REQUEST })

  const { response, error } = yield call(schemaService.get)

  if (response) {
    yield put({
      type: schemaConstants.SCHEMA_SUCCESS,
      payload: { schema: response },
    })

    return
  }

  yield call(handleAuthError.saga, schemaConstants.SCHEMA_FAILURE, processError(error).response)

  return
}

function* erase() {
  yield put({ type: schemaConstants.SCHEMA_ERASE })

  return
}

export const schemaActions = {
  fetch,
  erase,
}
