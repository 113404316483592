import React from 'react'
import icosm from 'assets/ico-2.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Maintenance } from 'components/Maintenance'
export const DiagnosticGamesInProgress = () => {
  return (
    <>
      <Breadcrumbs color="green" ico={icosm} title="Przykładowe gry" />
      <Maintenance />
    </>
  )
}
