import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'

import { StringType } from './fields/StringType'
import { PasswordRepeatType } from './fields/PasswordRepeatType'

import { Loader } from 'components/Loader'

const fieldTypes = {
  string: StringType,
  password: StringType,
  passwordRepeat: PasswordRepeatType,
}

export const FormFactory = ({
  title = null,
  url,
  method = 'PUT',
  properties,
  resource = null,
  handleSuccess = null,
  submitFunction = null,
  disabledInput = false,
}) => {
  const [state, setState] = useState({
    values: {},
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
    url: url.includes(':id') && resource ? url.replace(':id', resource.id) : url,
  })

  const setValue = (name, value) => {
    const isInvalid = value instanceof Error
    setState((state) => ({
      ...state,
      values: { ...state.values, [name]: !isInvalid && value },
      errors: { ...state.errors, [name]: isInvalid },
      invalid: state.invalid + (!!state.errors[name] === !isInvalid && -1 + isInvalid * 2),
    }))
  }

  const setValidator = (name, validator, values) => {
    setState((state) => ({
      ...state,
      validators: {
        ...state.validators,
        [name]: { validator, values: values || [name] },
      },
    }))
  }

  const setErrorMessageSetter = (name, errorMessageSetter) => {
    setState((state) => ({
      ...state,
      errorMessageSetters: {
        ...state.errorMessageSetters,
        [name]: errorMessageSetter,
      },
    }))
  }
  const dispatch = useDispatch()
  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      Object.keys(state.validators).length &&
      !Object.keys(state.validators).reduce(
        (status, name) =>
          state.validators[name].validator(
            ...Object.keys(state.values)
              .filter((item) => state.validators[name].values.includes(item))
              .map((item) => state.values[item])
          ) && status,
        true
      )
    ) {
      return
    }

    if (submitFunction) {
      submitFunction(state.values)
      return
    }

    setState((state) => ({ ...state, isProcessing: true }))

    fetchDataHandleAuthError(
      state.url,
      method,
      { body: JSON.stringify(state.values) },
      () => {
        setState((state) => ({ ...state, isProcessing: false }))

        notification('success', method === 'PUT' ? 'Rekord zaktualizowany' : 'Rekord dodany', 'Zapisano')
        handleSuccess && handleSuccess(state.values)
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        error.response.violations.map((item) => state.errorMessageSetters[item.propertyPath](item.message))
        notification(
          'error',
          error.response.violations.length ? 'Incorrect form' : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      ''
    )
  }

  return (
    <>
      {title && <h4 className="login-content__link">{title}</h4>}

      <form onSubmit={handleSubmit}>
        {Object.keys(properties).map((name) => {
          const FieldComponent = fieldTypes[properties[name].type]
          return (
            <div key={name} className="form__item">
              <FieldComponent
                name={name}
                type={properties[name].type}
                label={properties[name].description}
                hint={properties[name].hint}
                value={resource && resource[name]}
                disabled={state.isProcessing || disabledInput}
                validate={properties[name].validate || []}
                setValue={setValue}
                setValidator={setValidator}
                setErrorMessageSetter={setErrorMessageSetter}
              />
            </div>
          )
        })}
        {!disabledInput && (
          <button type="submit" className="btn form__btn" disabled={state.isProcessing || !!state.invalid}>
            {!state.isProcessing ? 'Zapisz' : <Loader />}
          </button>
        )}
      </form>
    </>
  )
}
