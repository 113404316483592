import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { Route, Redirect } from 'react-router-dom'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { AccessDenied } from 'pages/AccessDenied'
import { MediaLibraryIndex } from './MediaLibraryIndex'
import { routePath } from '_constants/routes.constants.js'

import { List as ElementsList } from './Elements/List'
import { Addto as ElementsAddto } from './Elements/Addto'
import { Editto as ElementsEditto } from './Elements/Editto'

import { translate } from '_helpers/translate'

const routes = () => [
  {
    title: 'Biblioteka',
    type: Route,
    render: () => <MediaLibraryIndex />,
    path: routePath.medialibrary.index(''),
    exact: true,
  },

  {
    title: 'Biblioteka',
    type: Redirect,
    from: '/',
    to: routePath.medialibrary.index(''),
    exact: true,
  },

  // GAME ELEMENTS
  {
    title: translate('T_GAME_ELEMENTS_LIST'),
    type: Route,
    component: ElementsList,
    path: routePath.medialibrary.list(':id', ''),
    exact: true,
  },
  {
    title: translate('T_GAME_ELEMENT_EDIT'),
    type: Route,
    component: ElementsEditto,
    path: routePath.medialibrary.edit(':gameId', ':id', ''),
    exact: true,
  },
  {
    title: translate('T_GAME_ELEMENT_ADD'),
    type: Route,
    component: ElementsAddto,
    path: routePath.medialibrary.add(':gameId', ''),
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const MediaLibrary = (props) => {
  const { match } = props

  if (hasRole(authConstants.ROLE_THERAPIST) || hasRole(authConstants.ROLE_PARENT)) {
    return <Page routes={generateRoutes(prefixPaths(match.url, routes(), ['path', 'from', 'to']))} />
  } else {
    return <AccessDenied />
  }
}
