import React from 'react'
import close from 'assets/close.svg'
export const AlertDialog = ({ open, handleClose, handleAgree, title = '', text = '' }) => {
  return (
    <div className={`popup ${open ? 'shown' : ''}`}>
      <img className="popup__close" src={close} alt="" onClick={handleClose} />
      <p className="popup__text">
        <b>{title}</b>
        <br />
        {text}
      </p>
      <div className="popup__btns">
        <button className="btn btn--no-fill" onClick={handleClose}>
          Anuluj
        </button>
        <button className="btn" onClick={handleAgree}>
          Potwierdź
        </button>
      </div>
    </div>
  )
}
