import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from 'components/Header'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-3.svg'
import { authHeader } from '_helpers/authHeader'

export const AccessDenied = () => {
  const { isLogged } = useSelector((state) => state.auth)

  const [config, setConfig] = useState([])

  const getConfig = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/configs/1`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw res.json()
      })
      .then((res) => {
        setConfig(res)
      })
  }

  useEffect(() => {
    getConfig()
  }, [])

  return (
    <>
      <Header />
      <Breadcrumbs color="dark" ico={ico} title="Dostęp zablokowany" backHome={true} />
      <div className="container">
        <h2 className="page-heading">{config?.gamesAccessMessage ? config?.gamesAccessMessage : 'Funkcjonalność dostępna dla kont z wykupionym abonamentem.'}</h2>
        <Link to={isLogged ? '/profile' : '/'} className="btn form__btn">
          Wykup dostęp
        </Link>
      </div>
    </>
  )
}
