import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Header from 'components/Header'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-3.svg'

export const AccessDeniedGames = () => {
  const { isLogged } = useSelector((state) => state.auth)

  return (
    <>
      <Header />
      <Breadcrumbs color="dark" ico={ico} title="Dostęp zablokowany" backHome={true} />
      <div className="container">
        <h2 className="page-heading">
          Gry terapeutyczne dostępne są dla kont z wykupionym abonamentem. Pamiętaj również, że gry przygotowywane są
          przez terapeutę za pośrednictwem aplikacji.
        </h2>
        <Link to={isLogged ? '/profile' : '/'} className="btn form__btn">
          Wykup dostęp
        </Link>
      </div>
    </>
  )
}
