import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'

export const SubscriptionForm = () => {
  const isPremium = useSelector((state) => state.user.profile?.isPremium)
  const paymentValidUntil = useSelector((state) => state.user.profile?.paymentValidUntil)
  const userId = useSelector((state) => state.user.profile?.id)
  const date = paymentValidUntil ? new Date(paymentValidUntil) : new Date()
  const [checkedValue, setIsChecked] = useState(null)
  const data = [
    {
      id: 1,
      title: '1 miesiąc',
      description: '69zł /mies.',
      value: 69,
      time: 1,
    },
    {
      id: 2,
      title: '3 miesiące',
      description: '59zł /mies.',
      value: 59,
      time: 3,
    },
    {
      id: 3,
      title: '12 miesięcy',
      description: '49zł /mies.',
      value: 49,
      time: 12,
    },
  ]

  const handlePay = (e) => {
    e.preventDefault()
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/user/${userId}/payment`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        productId: checkedValue,
        invoiceAddress: input.invoiceAddress,
        invoicePostcode: input.invoicePostcode,
        invoiceCity: input.invoiceCity,
      }),
    })
      .then((res) => {
        if (res.ok) {
          notification('success', 'Trwa przekierowanie do płatności', 'Komunikat')
          return res.json()
        } else {
          notification('error', 'Błąd, spróbuj ponownie', 'Błąd')
        }
        throw res.json()
      })
      .then((res) => {
        window.location.href = res.redirectUri
      })
  }

  const [input, setInput] = useState({
    invoicePostcode: '',
    invoiceCity: '',
    invoiceAddress: '',
  })

  const handleInputChange = (event) => {
    let value = event.target.value
    const name = event.target.name
    if (name === 'invoicePostcode' && value.length === 2) {
      value += '-'
    }
    setInput({
      ...input,
      [name]: value,
    })
  }

  return (
    <>
      <div className="profile__info">
        <span>Konto:</span> {!isPremium ? 'brak abonamentu' : 'premium'}
      </div>
      <div className="profile__info">
        <span> Ważność: </span>{' '}
        {!isPremium ? '---' : `abonament do ${new Date(paymentValidUntil).toLocaleDateString('pl-PL')}`}
      </div>

      <div className="boxes-grid-subscription">
        {data.map((row, index) => {
          return (
            <div className="boxes-grid__item box" key={`${index}_link`}>
              <label
                htmlFor={`${index}_checkbox`}
                className="box__visuals--square-sm box__visuals box box__visuals--green"
              >
                <span>{row.title}</span>
                <span className="box__checkbox">
                  <input
                    id={`${index}_checkbox`}
                    type="radio"
                    value={row.id}
                    checked={checkedValue === row.id}
                    onChange={() => setIsChecked(row.id)}
                    required
                  />
                </span>
              </label>
              <h2 className="box__title">{row.description}</h2>
            </div>
          )
        })}
      </div>
      <form onSubmit={handlePay}>
        {checkedValue !== null && (
          <>
            <div className="form__item">
              <input
                required
                type="text"
                className="form__input"
                name="invoiceAddress"
                onChange={handleInputChange}
                value={input.invoiceAddress}
              />
              <label className="form__label">adres</label>
            </div>
            <div className="form__item">
              <input
                required
                type="text"
                className="form__input"
                name="invoicePostcode"
                onChange={handleInputChange}
                pattern="[0-9]{2}[-][0-9]{3}"
                value={input.invoicePostcode}
              />
              <label className="form__label">kod pocztowy</label>
            </div>
            <div className="form__item">
              <input
                required
                type="text"
                className="form__input"
                name="invoiceCity"
                onChange={handleInputChange}
                value={input.invoiceCity}
              />
              <label className="form__label">miasto</label>
            </div>
            do zapłaty: <b>{data[checkedValue - 1].value * data[checkedValue - 1].time} zł</b> <br />
            aby aktywować abonament do:{' '}
            {new Date(date.setMonth(date.getMonth() + data[checkedValue - 1].time)).toLocaleDateString('pl-PL')} (
            {data[checkedValue - 1].time * 30} dni)
          </>
        )}
        <button type="submit" className={classNames('btn survey__btn', checkedValue === null && 'disabled')}>
          Zapłać
        </button>
      </form>
    </>
  )
}
