import { translations } from 'translations'

export const translate = (message, values = [], locale = 'pl') =>
  Object.keys(translations[locale]).includes(message)
    ? values.length
      ? translations[locale][message]
          .split('%')
          .map((value, index) => value + (values[index] ? values[index] : ''))
          .join('')
      : translations[locale][message]
    : message
