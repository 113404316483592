export const routePath = {
  home: '/home',
  login: '/login',
  survey: {
    main: '/survey',
    index: (p) => `${p ?? routePath.survey.main}/index`,
    tempresults: (p) => `${p ?? routePath.survey.main}/results`,
  },
  dgames: {
    main: '/diagnostic-games',
    index: (p) => `${p ?? routePath.dgames.main}/index`,
    SurveyGames: (p) => `${p ?? routePath.dgames.main}/survey-games`,
    Observation: (p) => `${p ?? routePath.dgames.main}/observation`,
    GameIdentification: (id, p) => `${p ?? routePath.dgames.main}/observation/game-identification/${id}`,
    GameSorting: (id, p) => `${p ?? routePath.dgames.main}/observation/game-sorting/${id}`,
    GameMapping: (id, p) => `${p ?? routePath.dgames.main}/observation/game-mapping/${id}`,
    Recognition: (p) => `${p ?? routePath.dgames.main}/recognition`,
    GameUnderstandingSpeech: (id, p) => `${p ?? routePath.dgames.main}/recognition/game-understanding-speech/${id}`,
    GameSpeaking: (id, p) => `${p ?? routePath.dgames.main}/recognition/game-speaking/${id}`,
    GameVerbalCommunication: (id, p) => `${p ?? routePath.dgames.main}/recognition/game-verbal-communication/${id}`,
    Identification: (p) => `${p ?? routePath.dgames.main}/identification`,
    GameEmotionsIdentification: (id, p) =>
      `${p ?? routePath.dgames.main}/identification/game-emotions-identification/${id}`,
    Imitation: (p) => `${p ?? routePath.dgames.main}/imitation`,
    GameMimicImitation: (id, p) => `${p ?? routePath.dgames.main}/imitation/game-mimic-imitation/${id}`,
    GameGesturesImitation: (id, p) => `${p ?? routePath.dgames.main}/imitation/game-gestures-imitation/${id}`,
    GameSubjectsImitation: (id, p) => `${p ?? routePath.dgames.main}/imitation/game-subjects-imitation/${id}`,
    inprogress: (p) => `${p ?? routePath.dgames.main}/inprogress`,
  },
  tgames: {
    main: '/therapeutic-games',
    index: (p) => `${p ?? routePath.tgames.main}/index`,
    results: (id, p) => `${p ?? routePath.tgames.main}/results/${id}`,
  },
  institutions: {
    main: '/institutions',
    index: (p) => `${p ?? routePath.institutions.main}/index`,
  },
  settings: {
    main: '/settings',
    index: (p) => `${p ?? routePath.settings.main}/index`,
  },
  children: {
    main: '/children',
    index: (p) => `${p ?? routePath.children.main}/index`,
    list: (p) => `${p ?? routePath.children.main}/list`,
    therapy: (p) => `${p ?? routePath.children.main}/therapy`,
  },
  results: {
    main: '/results',
    index: (p) => `${p ?? routePath.results.main}/index`,
    survey: (p) => `${p ?? routePath.results.main}/survey`,
    game: (p) => `${p ?? routePath.results.main}/game`,
    therapy: (p) => `${p ?? routePath.results.main}/therapy`,
  },
  about: {
    main: '/about',
    index: (p) => `${p ?? routePath.about.main}/index`,
  },
  program: {
    main: '/program',
    list: (p) => `${p ?? routePath.program.main}/list`,
    add: (p) => `${p ?? routePath.program.main}/add`,
  },
  practices: {
    main: '/program/list/practices',
    list: (gameId, p) => `${p ?? routePath.practices.main}/${gameId}/list`,
    edit: (gameId, id, p) => `${p ?? routePath.practices.main}/${gameId}/list/edit/${id}`,
    add: (gameId, p) => `${p ?? routePath.practices.main}/${gameId}/list/add`,
  },
  medialibrary: {
    main: '/media-library',
    index: (p) => `${p ?? routePath.medialibrary.main}/index`,
    list: (id, p) => `${p ?? routePath.medialibrary.main}/elements/${id}`,
    edit: (gameId, id, p) => `${p ?? routePath.medialibrary.main}/elements/${gameId}/${id}/edit`,
    add: (gameId, p) => `${p ?? routePath.medialibrary.main}/elements/${gameId}/add/new`,
  },
}
