import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { notification } from '_helpers/notification'
import { useDispatch } from 'react-redux'
import { menuActions } from '_actions'
import arrow from 'assets/arrow-left.svg'

export const Activate = () => {
  const history = useHistory()
  const isPassword = history.location.pathname.includes('startResettingPassword')
  const initialState = {
    password: '',
    login: '',
    isSubmitting: false,
    errorMessage: null,
  }

  const [data, setData] = useState(initialState)

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    })
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}${history.location.pathname}`, {
      method: isPassword ? 'POST' : 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: isPassword
        ? JSON.stringify({
            login: data.login,
          })
        : JSON.stringify({ plainPassword: data.password }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw res.json()
      })
      .then(() => {
        if (history.location.pathname.includes('startResettingPassword')) {
          notification('success', 'Jeśli Twój mail jest zarejestrowany w aplikacji, na Twoją skrzynkę wysłaliśmy dalsze instrukcje.', 'Sprawdź maila')
        } else {
          notification('success', 'Zaloguj się', 'Aktywowano')
        }
        handleToggleRegister()
      })
      .catch((errorPromise) => {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: errorPromise.message || errorPromise.statusText,
        })

        errorPromise.then((error) => {
          if (error.violations) {
            let errors = error.violations.map((e) => {
              return JSON.stringify(`${e.propertyPath} : ${e.message}`)
            })

            errors = errors.join('\r\n')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  useEffect(() => {
    if (history.location.pathname.includes('activation')) {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}${history.location.pathname}`, {
        method: 'GET',
      })
    }
  }, [])

  const dispatch = useDispatch()
  const handleToggleRegister = () => {
    dispatch(menuActions.toggleMobileNavigationRegister())
    history.push('/')
  }

  return (
    <>
      <Link to className="login-content__link login-bar-back" onClick={handleToggleRegister}>
        <img className="arrow" src={arrow} alt="" />
        {history.location.pathname.includes('startResettingPassword') && 'Przypomnienie hasła'}
        {history.location.pathname.includes('resetPassword') && 'Ustawienie nowego hasła'}
        {history.location.pathname.includes('activation') && 'Aktywacja konta'}
      </Link>
      <form className="form" noValidate onSubmit={handleFormSubmit}>
        {history.location.pathname.includes('startResettingPassword') &&
          'Wprowadź adres email na który zostało założone konto. Wyślemy wiadomość z instrukcjami dotyczącymi resetowania hasła.'}
        <div className="form__item">
          {history.location.pathname.includes('startResettingPassword') && (
            <>
              <input
                type="text"
                className="form__input"
                required
                id="login"
                label="login"
                name="login"
                value={data.login}
                onChange={handleInputChange}
              />
              <label className="form__label">e-mail</label>
            </>
          )}
          {history.location.pathname.includes('resetPassword') && (
            <>
              <input
                className="form__input"
                required
                name="password"
                label="Hasło"
                type="password"
                id="password"
                autoComplete="current-password"
                value={data.password}
                onChange={handleInputChange}
              />
              <label className="form__label">hasło</label>
            </>
          )}
        </div>
        {history.location.pathname.includes('activation') ? (
          <>
            <p>Twoje konto zostało aktywowane.</p>
            <button className="form__btn btn btn--no-fill" onClick={handleToggleRegister}>
              Zamknij
            </button>
          </>
        ) : (
          <button className="btn form__btn" disabled={data.isSubmitting} type="submit">
            {data.isSubmitting ? (
              <img className="spinner spinner--sm" src="/images/logo192.png" alt="loading icon" />
            ) : (
              'Zmień hasło'
            )}
          </button>
        )}
      </form>
    </>
  )
}
