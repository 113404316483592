import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import { selectDataG, selectChildId, selectInquiryId } from '_reducers/newGameSlice'
import { formatRoute } from 'react-router-named-routes'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'

export const GamesCategoryList = ({ ids, routes, idsValues }) => {
  const dispatch = useDispatch()

  const data = useSelector(selectDataG)
  const childId_G = useSelector(selectChildId)
  const inquiryId_G = useSelector(selectInquiryId)

  const inquiryId_R = useSelector((state) => state.user.profile?.SelectedChild?.ageMatchedInquiryId)
  const childId_R = useSelector((state) => state.user.profile?.SelectedChild?.id)

  const [inquiries, setInquiries] = useState([])
  const [inquiryId, setInquiryId] = useState(inquiryId_G ? inquiryId_G : inquiryId_R)

  const getInquiries = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/inquiries/active`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setInquiries(res)
      })
  }

  useEffect(() => {
    getInquiries()
    if (inquiryId || childId) {
      dispatch(getData())
    } else {
      notification('warning', 'Wybierz opcję', 'Komunikat')
    }
  }, [])

  useEffect(() => {
    setInquiryId(inquiryId)
    dispatch({
      type: 'newGame/inquiryId',
      payload: parseInt(inquiryId),
    })
    setChildId(childId)
    dispatch({
      type: 'newGame/childId',
      payload: childId,
    })
    inquiryId && dispatch(getData())
  }, [inquiryId])

  const handleChangeAge = (value) => {
    setInquiryId(parseInt(value))
    dispatch({
      type: 'newGame/inquiryId',
      payload: parseInt(value),
    })
  }

  function getKeyByValue(object, val, type) {
    for (const [key, value] of Object.entries(object)) {
      if (key === val) {
        return value[type]
      }
    }
  }

  function getData() {
    return (dispatch) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/inquiries/whole/${inquiryId}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            type: 'newGame/fetchData',
            payload: res?.InquiryPractices,
          })
        })
    }
  }

  const [children, setChildren] = useState([])
  const [childId, setChildId] = useState(childId_G ? childId_G : childId_R)

  const parentId = useSelector((state) => state.user.profile?.id)

  const getChildren = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/parentChilds/${parentId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setChildren(res)
      })
  }

  useEffect(() => {
    parentId && getChildren()
  }, [parentId])

  const handleChange = (e) => {
    setChildId(e.target.value)
    dispatch({
      type: 'newGame/childId',
      payload: e.target.value,
    })
    setInquiryId(parseInt(e.target.options[e.target.selectedIndex].dataset.id))
    dispatch({
      type: 'newGame/inquiryId',
      payload: parseInt(e.target.options[e.target.selectedIndex].dataset.id),
    })
  }

  const filtredData = data?.filter((i) => ids.includes(i.Practice.Game.idname))

  const filtredInquiries = inquiries?.content?.filter((a) =>
    a.InquiryPracticeCategoryIds.some((v) => idsValues.includes(v))
  )

  return (
    <>
      {hasRole(authConstants.ROLE_PARENT) ? (
        <select className="custom-select survey__select" onChange={(e) => handleChange(e)}>
          <option hidden>Wybierz dziecko:</option>
          {children?.content?.map((item) => (
            <option
              key={item.id}
              value={item.id}
              data-id={item.ageMatchedInquiryId}
              selected={item.id === childId ? 'selected' : ''}
            >
              {item.firstName}
            </option>
          ))}
        </select>
      ) : (
        <select className="custom-select survey__select" onChange={(e) => handleChangeAge(e.target.value)}>
          <option selected hidden>
            Wybierz wiek dziecka:
          </option>
          {filtredInquiries?.map((item) => (
            <option
              key={item.id}
              value={item.id}
              hidden={!item.InquiryPracticeCategoryIds.length > 0}
              selected={item.id === inquiryId ? 'selected' : ''}
            >
              {item.label}
            </option>
          ))}
        </select>
      )}

      <ol>
        {filtredData?.length ? (
          filtredData?.map((i, j) => (
            <li key={j} className="list-item">
              <span className="btn-start">
                {/*getKeyByValue(routes, i.Practice.Game.idname, 'name')*/}
                {i.Practice.title}
                <Link
                  className="btn btn--green"
                  to={formatRoute(getKeyByValue(routes, i.Practice.Game.idname, 'url'), { id: i.Practice.id })}
                >
                  Rozpocznij
                </Link>
              </span>
            </li>
          ))
        ) : (
          <p>Wybierz przedział wiekowy</p>
        )}
      </ol>
    </>
  )
}
