import React from 'react'
/*import React, { useState, useEffect } from 'react'
import { authHeader } from '_helpers/authHeader'*/
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-4.svg'
export const InstitutionsIndex = () => {
  /*const typingTimeout = 1000

  const listqueryParams = {
    search: '',
    province_id: '',
    city: '',
    page: 1,
    perPage: 12,
  }

  const [queryParams, setQueryParams] = useState(listqueryParams)
  const [typingState, setTypingState] = useState({ timeoutId: null })
  const [searchText, setSearchText] = useState(listqueryParams.search ? listqueryParams.search : '')

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)
    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  const handleSelectChange = (event) => {
    setQueryParams({
      ...queryParams,
      [event.target.name]: event.target.value,
      page: 1,
    })
  }

  const [healthCenter, setHealthCenter] = useState([])

  const getHealthCenter = () => {
    fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/healthCenter/list?search=${queryParams.search}&province_id=${queryParams.province_id}&city=${queryParams.city}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(),
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setHealthCenter(res)
      })
  }

  useEffect(() => {
    getHealthCenter()
  }, [queryParams])

  const [province, setProvince] = useState([])

  const getProvince = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/province/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setProvince(res)
      })
  }

  useEffect(() => {
    getProvince()
  }, [])

  const [cities, setCities] = useState([])

  const getCities = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/healthCenter/cityList`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setCities(res)
      })
  }

  useEffect(() => {
    getCities()
  }, [])*/

  return (
    <>
      <Breadcrumbs color="yellow" ico={ico} title="Lista instytucji i osób" backHome={true} />
      <iframe title="Institutions" src="https://oozio.co.uk/baza-lekarzy/" style={{ border: 0, minHeight: "calc(100vh - 191px)"}}/>
      {/*<form action className="form">
        <div className="form__item">
          <select className="custom-select form__select" name="province_id" onChange={handleSelectChange}>
            <option selected value="">
              Wszystkie
            </option>
            {province?.content?.map((item) => (
              <option key={item.id} value={item.id}>
                {item.title}
              </option>
            ))}
          </select>
          <label className="form__label">Województwo</label>
        </div>
        <div className="form__item">
          <select className="custom-select form__select" name="city" onChange={handleSelectChange}>
            <option selected value="">
              Wszystkie
            </option>
            {cities?.cityList?.content?.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <label className="form__label">Miasto / Miejscowość</label>
        </div>
        <div className="form__item">
          <input type="text" className="form__input" value={searchText} onChange={handleSearchChange} />
          <label className="form__label">Filtruj</label>
        </div>
      </form>
      
      <div className="results">
        <p className="results__total">Liczba ośrodków: <span>{healthCenter.totalItems}</span></p>
        {healthCenter?.content?.map((item) => (
          <div className="results__item r-item" key={item.id}>
            <h4 className="r-item__title">{item.name}</h4>
            <p className="r-item__description">
              {item.postcode} {item.city} {item.address}
              <br />
              tel. {item.phone}
            </p>
          </div>
        ))}
        </div>*/}
    </>
  )
}
