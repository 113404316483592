import './scss/main.scss'
import React from 'react'
import { HashRouter as Router, Switch } from 'react-router-dom'
import ReactNotification from 'react-notifications-component'
import { isLogged } from '_helpers/isLogged'
import routes from 'routes'
import { generateRoutes } from '_helpers/generateRoutes'
import { store } from '_store'
import { authActions } from '_actions/index'

isLogged(store.dispatch, authActions.restore, authActions.logout)

function App() {
  return (
    <>
      <ReactNotification />
      <Router>
        <div className="App">
          <Switch>{generateRoutes(routes())}</Switch>
        </div>
      </Router>
    </>
  )
}

export default App
