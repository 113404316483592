import React, { useRef, useEffect } from 'react'
import { DragDropContainer } from 'react-drag-drop-container'

export const Boxable = (props) => {
  const fooRef = useRef(null)

  useEffect(() => {
    if (props.clear) {
      fooRef.current.containerElem.style.visibility = 'visible'
    }
  }, [props.clear])

  return (
    <div className="boxable_component">
      <DragDropContainer targetKey={props.targetKey} dragData={{ image: props.image, name: props.name }} ref={fooRef}>
        <img src={props.image} alt={props.name} />
      </DragDropContainer>
    </div>
  )
}
