export const translations = {
  pl: {
    T_GAME_PRACTICES_LIST: 'Ćwiczenia',
    T_GAME_PRACTICE_ADD: 'Dodaj ćwiczenie',
    T_GAME_PRACTICE_EDIT: 'Edycja ćwiczenia',
    T_NAME_LABEL: 'Nazwa',
    T_NAME_HINT: 'Podaj nazwę',
    T_DESCRIPTION_LABEL: 'Opis',
    T_DESCRIPTION_HINT: 'Wpisz opis',
    T_GAME_PRACTICE_CATEGORY_LABEL: 'Kategoria',
    T_GAME_PRACTICE_CATEGORY_HINT: 'Wybierz kategorię',
    T_STATUS_LABEL: 'aktywny',
    T_RECORD_UPDATED: 'Rekord zaktualizowany',
    T_RECORD_ADDED: 'Rekord dodany',
    T_RECORD_CREATED: 'Rekord dodany',
    T_SAVED: 'Zapisano poprawnie',
    T_INCORRECT_FORM: 'Niepoprawny formularz',
    T_ADD_SAVE: 'zapisz',
    T_SAVE: 'Zapisz',
    T_GAME_PRACTICE_TITLE: 'Tytuł ćwiczenia',
    T_DESCRIPTION: 'Opis',
    T_CATEGORY: 'Kategoria',
    T_STAT: 'Status',
    T_SEARCH: 'Szukaj...',
    T_SCORE_LABEL: 'Ilość punktów',
    T_SCORE_HINT: 'Podaj ilość punktów',
    T_GAME_EMOTION_LABEL: 'Emocja',
    T_GAME_EMOTION_HINT: 'Wybierz emocję',
    T_TIME_SECONDS_LIMIT_LABEL: 'Limit czasu (sekundy)',
    T_TIME_SECONDS_LIMIT_HINT: 'Podaj limit czasu (0-bez limitu)',
    T_GAME_ELEMENT_LABEL: 'Element',
    T_GAME_ELEMENT_HINT: 'Wybierz element',
    T_GAME_PRACTICE_STAGE_CONFIGURATION: 'Konfiguracja etapu gry',
    T_GAME_PRACTICE_STAGE_PREVIEW: 'Podgląd',
    T_GAME_PRACTICE_STAGES_LIST: 'Lista etapów gry',
    T_CLOSE: 'Zamknij',
    T_RECORD_DELETE_ERROR: 'Nie usunięto rekordu',
    T_ID: 'ID',
    T_TITLE: 'Tytuł',
    T_SCORE: 'Ilość punktów',
    T_ALERT_DELETE_TITLE: 'Usuwanie',
    T_ALERT_DELETE_TEXT: 'Czy na pewno chcesz usunać wybrany rekord ?',
    T_SORT_ASC_DESC: 'Sortowanie rosnąco/malejąco',
    T_SHOW_ON_PAGE: 'Pokaż',
    T_EDIT: 'Edycja',
    T_DELETE: 'Usuwanie',
    T_ADD_GAME_PRACTICE_STAGE: 'dodaj etap',
    T_GAME_PRACTICE_OF_GAME: 'Ćwiczenie w grze',
    T_TYPE: 'Typ',
    T_ALL: 'wszystko',
    T_TYPE_PHOTO: 'Photo',
    T_TYPE_AUDIO: 'Audio',
    T_TYPE_VIDEO: 'Video',
    T_TITLE_LABEL: 'Tytuł',
    T_CONTENT_LABEL: 'Treść',
    T_TYPE_LABEL: 'Typ',
    T_TITLE_HINT: 'Wpisz tytuł',
    T_CONTENT_HINT: 'Wpisz treść',
    T_TYPE_HINT: 'Wybierz rodzaj',
    T_SUBMIT_UPLOAD: 'Wgraj',
    T_FILE_UPLOADED_SUCCESS: 'Wgrano plik',
    T_PRACTICE_CATEGORY: 'Kategoria ćwiczenia',
    T_LOGIN_ERROR: 'błędny login',
    T_PASSWORD_ERROR: 'błędne hasło',
    Error: 'Błąd',
    'Bad credentials.': 'Niepoprawne dane',
    'Invalid credentials.': 'Niepoprawne dane',
    'This value should not be blank.': 'Wartość nie może być pusta',
    'This value is too short. It should have 6 characters or more.':
      'Wartość powinna mieć conajmniej 6 znaków',
    'This value is already used.': 'Ta wartość jest już zajęta, wybierz inną',
    'Bad resetting password token': 'Twój link wygasł',
    'Expired resetting password token': 'Twój link wygasł',
    'User with this login doesnt exist!': 'Nie znaleziono takiego użytkownika!',
  },
  en: {
    test: '',
  },
}
