import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { StringType } from 'components/form/StringType'
import { SelectType } from 'components/form/SelectType'
import Grid from '@material-ui/core/Grid'
import { dataToObj } from '_helpers/tableProps'
import { getProps } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import { useStyles } from 'components/form/FormStyles'
import { useHistory } from 'react-router-dom'
import { element as schema } from '_schema/element'
import { MediaUpload } from './MediaUpload'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-3.svg'

export const Addto = ({ match, dataResource }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    params: { gameId, id },
  } = match

  const url = id ? schema.set : schema.add
  const method = id ? 'PUT' : 'POST'
  const data = id
    ? dataResource
    : {
        stat: true,
      }

  const [state, setState] = useState({
    values: {},
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
    canUpload: false,
    url: url.includes('{id}') ? url.replace('{id}', id) : url,
    games: [],
  })

  const fields = {
    title: {
      type: 'string',
      validate: ['required'],
      label: translate('T_TITLE_LABEL'),
      hint: translate('T_TITLE_HINT'),
    },
    text: {
      type: 'string',
      validate: ['required'],
      label: translate('T_CONTENT_LABEL'),
      hint: translate('T_CONTENT_HINT'),
    },
    type: {
      type: 'select',
      validate: ['required'],
      options: [
        { id: 'TYPE_PHOTO', title: 'obrazek' },
        { id: 'TYPE_AUDIO', title: 'dźwięk' },
        { id: 'TYPE_VIDEO', title: 'wideo' },
      ],
      label: translate('T_TYPE_LABEL'),
      hint: translate('T_TYPE_HINT'),
    },

    stat: {
      type: 'check',
      validate: ['required'],
      label: translate('T_STATUS_LABEL'),
    },
  }

  const handleSuccess = (resp) => {
    if (resp.photoThumbs.length !== 0 || resp.audioUrl != null || resp.videoUrl != null) {
      history.push(`/media-library/elements/${gameId}`)
    } else {
      history.push(`/media-library/elements/${gameId}/${resp.id}/edit`)
    }

    setState({ ...state, canUpload: (resp.type = !null) })
  }

  const handleSubmit = (e) => {
    e && e.preventDefault()

    if (
      Object.keys(state.validators).length &&
      !Object.keys(state.validators).reduce(
        (status, name) =>
          state.validators[name].validator(
            ...Object.keys(state.values)
              .filter((item) => state.validators[name].values.includes(item))
              .map((item) => state.values[item])
          ) && status,
        true
      )
    ) {
      return
    }

    setState((state) => ({ ...state, isProcessing: true }))
    dispatch(requestActions.start(state.url))

    const requestData = state.values
    requestData.Game = `/games/${gameId}`

    fetchDataHandleAuthError(
      state.url,
      method,
      { body: JSON.stringify(dataToObj(requestData)) },
      (resp) => {
        setState((state) => ({ ...state, isProcessing: false }))
        dispatch(requestActions.success())
        notification(
          'success',
          method === 'PUT' ? translate('T_RECORD_UPDATED') : translate('T_RECORD_ADDED'),
          translate('T_SAVED')
        )
        handleSuccess && handleSuccess(resp)
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        error.response.violations.map((item) => state.errorMessageSetters[item.propertyPath](translate(item.message)))
        notification(
          'error',
          error.response.violations.length ? translate('T_INCORRECT_FORM') : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
    return false
  }

  const setValue = (name, value) => {
    const isInvalid = value instanceof Error
    setState((state) => ({
      ...state,
      values: { ...state.values, [name]: !isInvalid && value },
      errors: { ...state.errors, [name]: isInvalid },
      invalid: state.invalid + (!!state.errors[name] === !isInvalid && -1 + isInvalid * 2),
    }))
  }

  const setValidator = (name, validator, values) => {
    setState((state) => ({
      ...state,
      validators: {
        ...state.validators,
        [name]: { validator, values: values || [name] },
      },
    }))
  }

  const setErrorMessageSetter = (name, errorMessageSetter) => {
    setState((state) => ({
      ...state,
      errorMessageSetters: {
        ...state.errorMessageSetters,
        [name]: errorMessageSetter,
      },
    }))
  }

  const notEmpty =
    JSON.stringify(state.values) !== '{}' && Object.values(state.values).every((x) => x !== undefined) && !id

  useEffect(() => {
    notEmpty && handleSubmit()
  }, [notEmpty])

  return (
    <>
      <Breadcrumbs color="blue-green" ico={ico} title="Biblioteka" doubleBack={true} />

      <div className="container-full">
        <Grid container spacing={0}>
          <form className={classes.root}>
            <Grid container spacing={3}>
              {Object.keys(fields).map((name, key) => {
                if (!fields[name]) return null

                return (
                  <Grid item xs={12} key={`${key}_grid`}>
                    {fields[name].type === 'string' && (
                      <StringType
                        key={key}
                        name={fields[name].name ? fields[name].name : name}
                        label={fields[name].label}
                        type={fields[name].type}
                        hint={fields[name].hint}
                        value={
                          data && (fields[name]['resource'] ? getProps(data, fields[name]['resource']) : data[name])
                        }
                        disabled={fields[name].disabled === true ? true : state.isProcessing}
                        validators={fields[name].validate || []}
                        setValue={setValue}
                        setValidator={setValidator}
                        setErrorMessageSetter={setErrorMessageSetter}
                      />
                    )}

                    {fields[name].type === 'select' && (
                      <SelectType
                        value={
                          data && (fields[name]['resource'] ? getProps(data, fields[name]['resource']) : data[name])
                        }
                        options={fields[name].options}
                        addEmpty={fields[name].addEmpty}
                        name={name}
                        disabled={data.type != null && name === 'type'}
                        validators={fields[name].validate || []}
                        label={fields[name].label}
                        hint={fields[name].hint}
                        setValue={setValue}
                        setValidator={setValidator}
                        setErrorMessageSetter={setErrorMessageSetter}
                      />
                    )}
                  </Grid>
                )
              })}
            </Grid>
          </form>
        </Grid>

        {(state.canUpload || data.id > 0) && (
          <>
            <MediaUpload
              uploadUrl={schema.mediaUpload.replace('{id}', data.id)}
              uploadName={'mediaFile'}
              mediaType={data.type}
              photoUrl={data.photoThumbs.full ? data.photoThumbs.full : ''}
              audioUrl={data.audioUrl}
              videoUrl={data.videoUrl}
            />

            <Grid item xs={12}>
              <button onClick={handleSubmit} className="btn btn--game" disabled={state.isProcessing || !!state.invalid}>
                {id ? translate('T_SAVE') : translate('T_ADD_SAVE')}
              </button>
            </Grid>
          </>
        )}
      </div>
    </>
  )
}
