import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  startedAt: null,
  finishedAt: null,
  data: [],
  gamesContent: [],
  gameStatus: true,
  points: 0,
  gamesPlayed: 0,
  currentStage: 0,
  results: [],
}

export const slice = createSlice({
  name: 'newGame',
  initialState,
  reducers: {
    start: (state) => {
      state.startedAt = Date.now()
      //state.data = initialState.data
    },
    clearData: () => initialState,
    finish: (state) => {
      state.finishedAt = Date.now()
    },
    restart: (state) => {
      state.gameStatus = initialState.gameStatus
      state.gamesPlayed += 1
      state.gamesContent = initialState.gamesContent
      state.currentStage = initialState.currentStage
      state.data = state.data.map((item) => {
        return item.Practice.id === state.gameId ? { ...item, active: false } : item
      })
      state.results = initialState.results
    },
    next: (state) => {
      state.currentStage += 1
    },
    toggleAnswer: (state, action) => {
      const { result, id, score } = action.payload
      state.gameStatus = false
      state.gameId = parseInt(id)
      if (result === true) {
        state.points += score
      }
      state.results = [...state.results, result]
    },
    fetchData: (state, action) => {
      state.data = action.payload
      state.data = state?.data?.map((item) => {
        return { ...item, active: true }
      })
      state.gamesContent = initialState.gamesContent
    },
    fetchGameContent: (state, action) => {
      const { content, totalItems } = action.payload
      state.gamesContent = content
      state.totalItems = totalItems
    },
    childId: (state, action) => {
      state.childId = action.payload
    },
    inquiryId: (state, action) => {
      state.inquiryId = action.payload
    },
  },
})

export const {
  start,
  clearData,
  finish,
  restart,
  toggleAnswer,
  fetchData,
  fetchGameContent,
  childId,
  inquiryId,
  next,
} = slice.actions

export const selectStartedAt = (state) => state.newGame.startedAt

export const selectFinishedAt = (state) => state.newGame.finishedAt

export const selectDataG = (state) => state.newGame.data

export const selectResults = (state) => state.newGame.results

export const selectGameContent = (state) => state.newGame.gamesContent

export const selectTotalItems = (state) => state.newGame.totalItems

export const selectCurrentStage = (state) => state.newGame.currentStage

export const selectGameStatus = (state) => state.newGame.gameStatus

export const selectGamesPlayed = (state) => state.newGame.gamesPlayed

export const selectPointsG = (state) => state.newGame.points

export const selectChildId = (state) => state.newGame.childId

export const selectInquiryId = (state) => state.newGame.inquiryId

export default slice.reducer
