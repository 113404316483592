import React from 'react'
import { GamesCategoryList } from 'components/GamesCategoryList'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-2-4.svg'
export const Imitation = () => {
  const ids = ['GAME_MIMIC_IMITATION', 'GAME_GESTURES_IMITATION', 'GAME_SUBIECTS_IMITATION']
  const idsValues = [7, 8, 9]

  const routes = {
    GAME_MIMIC_IMITATION: { url: '/diagnostic-games/imitation/game-mimic-imitation/:id', name: 'Naśladowanie mimiki' },
    GAME_GESTURES_IMITATION: {
      url: '/diagnostic-games/imitation/game-gestures-imitation/:id',
      name: 'Naśladowanie gestów',
    },
    GAME_SUBIECTS_IMITATION: {
      url: '/diagnostic-games/imitation/game-subjects-imitation/:id',
      name: 'Naśladowanie z przedmiotami',
    },
  }

  return (
    <div className="game-area">
      <Breadcrumbs color="green" ico={ico} title="Naśladowanie emocji" />
      <GamesCategoryList ids={ids} routes={routes} idsValues={idsValues} />
    </div>
  )
}
