import React, { useState } from 'react'
import ico from 'assets/ico-1-orange.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Link } from 'react-router-dom'
import { authHeader } from '_helpers/authHeader'
import arrowRight from 'assets/arrow-right.svg'
import TablePagination from '@material-ui/core/TablePagination'
import { translate } from '_helpers/translate'
import { useFetch } from '_helpers/useFetch'
import { useSelector, useDispatch } from 'react-redux'

export const SurveyResults = () => {
  const parentId = useSelector((state) => state.user.profile?.id)

  const [activeIndex, setActiveIndex] = useState(null)

  const listqueryParams = {
    search: '',
    page: 1,
    perPage: 12,
  }
  const [queryParams, setQueryParams] = useState(listqueryParams)

  const endPoint = parentId ? `${process.env.REACT_APP_API_ENTRYPOINT}/parentInquiry/${parentId}/list` : null

  const auth = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  const [page, setPage] = useState(queryParams.page > 0 ? queryParams.page - 1 : 0)
  const [rowsPerPage, setRowsPerPage] = useState(queryParams.perPage ? queryParams.perPage : 12)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({
      ...queryParams,
      page: newPage + 1,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }

  return (
    <>
      <Breadcrumbs color="orange" ico={ico} title="Wyniki i historia" />

      <div className="games-list">
        {res.state.data?.content?.length !== 0 ? (
          res.state.data?.content?.map((item, index) => (
            <>
              <div className="games-list__item gl-item gl-item--results" key={index}>
                <h3 className="gl-item__title">
                  {item.Child.firstName} -{' '}
                  {new Date(item.createdAt).toLocaleDateString('pl-PL', { hour: '2-digit', minute: '2-digit' })}
                </h3>

                <div
                  className="gl-item__expand"
                  onClick={(event) => setActiveIndex(activeIndex === index ? null : index)}
                >
                  Zobacz wyniki
                  <img className="gl-item__arrow" src={arrowRight} alt="" />
                </div>
              </div>

              {activeIndex === index ? (
                <div className="games-list__item-content open">
                  <div className="gl-item gl-item--results">
                    <ol>
                      {item?.Answers.map((element, i) => (
                        <li className="list-item" key={i}>
                          <h3 className="gl-item__title"> {element.question} </h3>
                          <p className="gl-item__description"> {element.answer}</p>
                        </li>
                      ))}
                    </ol>

                    <h3 className="gl-item__title"> Interpretacja wyników: </h3>
                    <p className="gl-item__description"> {item.verdictText} </p>
                  </div>
                </div>
              ) : null}
            </>
          ))
        ) : (
          <div className="games-list__item gl-item gl-item--results">
            <h3 className="gl-item__title">Brak wypełnionych ankiet</h3>
            <Link to="/survey" className="gl-item__expand">
              Dodaj ankietę
            </Link>
          </div>
        )}
        <TablePagination
          rowsPerPageOptions={[1, 2, 12]}
          labelRowsPerPage={translate('T_SHOW_ON_PAGE')}
          component="div"
          count={res.state.data?.totalItems !== undefined ? res.state.data?.totalItems : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  )
}
