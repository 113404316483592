import React from 'react'
import { Link } from 'react-router-dom'
import Header from 'components/Header'
import ico1 from 'assets/ico-1.svg'
import ico1orange from 'assets/ico-1-orange.svg'
import ico2 from 'assets/ico-2.svg'
import ico3 from 'assets/ico-3.svg'
import ico4 from 'assets/ico-4.svg'
import ico5 from 'assets/ico-5.svg'
import ico6 from 'assets/ico-login-1.svg'
import ico7 from 'assets/ico-login-2.svg'
import lock from 'assets/lock.svg'
import arrow from 'assets/arrow-right.svg'
import icoLogin1 from 'assets/ico-login-1.svg'
import icoLogin2 from 'assets/ico-login-2.svg'
import { useSelector } from 'react-redux'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import { useDispatch } from 'react-redux'
import { menuActions } from '_actions'
import { DemoInfo } from 'components/DemoInfo'

export const Home = () => {
  const { isLogged } = useSelector((state) => state.auth)
  const childName = useSelector((state) => state.user.profile?.SelectedChild?.firstName)
  const lastName = useSelector((state) => state.user.profile?.SelectedChild?.lastName)
  const isPremium = useSelector((state) => state.user.profile?.isPremium)

  const dispatch = useDispatch()

  const handleToggleMenu = () => {
    dispatch(menuActions.toggleMobileNavigationMenu())
    document.body.classList.toggle('overlay')
  }

  const handleToggleLogin = (role) => {
    dispatch(menuActions.toggleMobileNavigationLogin())
    document.body.classList.toggle('overlay')
    dispatch(menuActions.setRoles(role))
  }
  return (
    <>
      <Header />
      {isPremium !== undefined && !isPremium && isLogged && (
        <Link to="/profile" className="payment-bar">
          Kliknij <span>tutaj</span> aby wykupić dostęp
        </Link>
      )}
      {!isLogged && (
        <div className="payment-bar" onClick={handleToggleMenu}>
          Kliknij <span>tutaj</span> aby zalogować się i wykupić dostęp
        </div>
      )}
      <div className="boxes-grid">
        {!hasRole(authConstants.ROLE_TESTER) && (
          <Link to="/survey" className="boxes-grid__item box">
            <div className="box__visuals box__visuals--purple">
              <img className="box__ico" src={ico1} alt="" />
              <img className="box__arrow" src={arrow} alt="" />
            </div>
            <h2 className="box__title">Ankieta obszarów ryzyka</h2>
          </Link>
        )}
        <Link to="/diagnostic-games" className="boxes-grid__item box">
          <div className="box__visuals box__visuals--green">
            <img className="box__ico" src={ico2} alt="" />
            <img className="box__arrow" src={arrow} alt="" />
          </div>
          <h2 className="box__title">Przykładowe gry</h2>
        </Link>
        {!isLogged && (
          <>
            <div className="boxes-grid__item box" onClick={() => handleToggleLogin('parent')}>
              <div className="box__visuals box__visuals--orange">
                <img className="box__ico" src={icoLogin1} alt="" />
                <img className="box__arrow" src={arrow} alt="" />
              </div>
              <h2 className="box__title">
                Zaloguj/Zarejestruj się jako <span className="role">rodzic</span>
              </h2>
            </div>
            <div className="boxes-grid__item box" onClick={() => handleToggleLogin('therapist')}>
              <div className="box__visuals box__visuals--purple">
                <img className="box__ico" src={icoLogin2} alt="" />
                <img className="box__arrow" src={arrow} alt="" />
              </div>
              <h2 className="box__title">
                Zaloguj/Zarejestruj się jako <span className="role">terapeuta</span>
              </h2>
            </div>
          </>
        )}
        {!hasRole(authConstants.ROLE_TESTER) && isLogged && hasRole(authConstants.ROLE_THERAPIST) && (
          <Link to="/children" className="boxes-grid__item box">
            <div className="box__visuals box__visuals--dark-purple">
              <img className="box__ico" src={ico6} alt="" />
              <img className="box__arrow" src={arrow} alt="" />
            </div>
            <h2 className="box__title">
              Dzieci <br />
              <b>{childName ? `${childName} ${lastName}` : 'brak wybranego dziecka'}</b>
              <br />
              {!isPremium ? 'brak abonamentu' : 'premium'}
            </h2>
          </Link>
        )}
        {!hasRole(authConstants.ROLE_TESTER) && isLogged && (
          <Link to={isPremium ? '/therapeutic-games' : '/access-denied-games'} className="boxes-grid__item box">
            <div className="box__visuals box__visuals--blue">
              {!isPremium && <img className="box__lock" src={lock} alt="" />}
              <img className="box__ico" src={ico3} alt="" />
              <img className="box__arrow" src={arrow} alt="" />
            </div>
            <h2 className="box__title">Gry terapeutyczne</h2>
          </Link>
        )}
        {!hasRole(authConstants.ROLE_TESTER) && isLogged && hasRole(authConstants.ROLE_THERAPIST) && (
          <Link to={isPremium ? '/program' : '/access-denied'} className="boxes-grid__item box">
            <div className="box__visuals box__visuals--grass-green">
              {!isPremium && <img className="box__lock" src={lock} alt="" />}
              <img className="box__ico" src={ico7} alt="" />
              <img className="box__arrow" src={arrow} alt="" />
            </div>
            <h2 className="box__title">Program terapeutyczny</h2>
          </Link>
        )}
        {!hasRole(authConstants.ROLE_TESTER) && isLogged && (
          <Link to="/results" className="boxes-grid__item box">
            <div className="box__visuals box__visuals--orange">
              <img className="box__ico" src={ico1orange} alt="" />
              <img className="box__arrow" src={arrow} alt="" />
            </div>
            <h2 className="box__title">Wyniki i historia</h2>
          </Link>
        )}
        {!hasRole(authConstants.ROLE_TESTER) && isLogged && (
          <Link to="/media-library" className="boxes-grid__item box">
            <div className="box__visuals box__visuals--blue-green">
              <img className="box__ico" src={ico3} alt="" />
              <img className="box__arrow" src={arrow} alt="" />
            </div>
            <h2 className="box__title">Biblioteka</h2>
          </Link>
        )}
        {!hasRole(authConstants.ROLE_TESTER) && (
          <Link to="/institutions" className="boxes-grid__item box">
            <div className="box__visuals box__visuals--yellow">
              <img className="box__ico" src={ico4} alt="" />
              <img className="box__arrow" src={arrow} alt="" />
            </div>
            <h2 className="box__title">Lista instytucji i osób</h2>
          </Link>
        )}
        {!hasRole(authConstants.ROLE_TESTER) && (
          <>
            <Link to="/settings" className="boxes-grid__item box">
              <div className="box__visuals box__visuals--red">
                <img className="box__ico" src={ico5} alt="" />
                <img className="box__arrow" src={arrow} alt="" />
              </div>
              <h2 className="box__title">Ustawienia dźwięków</h2>
            </Link>
            <a href="https://asdhelp.pl/" className="boxes-grid__item box">
              <div className="box__visuals box__visuals--dark">
                <img className="box__ico" src={ico3} alt="" />
                <img className="box__arrow" src={arrow} alt="" />
              </div>
              <h2 className="box__title">Informacje o aplikacji</h2>
            </a>
          </>
        )}
      </div>
      <DemoInfo />
    </>
  )
}
