import { menuConstants } from '_constants'

const DEFAULT_STATE = {
  visibleMobileMenu: false,
  visibleMobileLogin: false,
  visibleMobileRegister: false,
  roles: null
}

export const menuReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case menuConstants.TOOGLE_MOBILE_NAVIGATION_MENU:
      return { ...state, visibleMobileMenu: !state.visibleMobileMenu }
    case menuConstants.HIDE_MOBILE_NAVIGATION_MENU:
      return { ...state, visibleMobileMenu: false }
    case menuConstants.TOOGLE_MOBILE_NAVIGATION_LOGIN:
      return { ...state, visibleMobileLogin: !state.visibleMobileLogin }
    case menuConstants.HIDE_MOBILE_NAVIGATION_LOGIN:
      return { ...state, visibleMobileLogin: false }
    case menuConstants.TOOGLE_MOBILE_NAVIGATION_REGISTER:
      return { ...state, visibleMobileRegister: !state.visibleMobileRegister }
    case menuConstants.HIDE_MOBILE_NAVIGATION_REGISTER:
      return { ...state, visibleMobileRegister: false }
    case menuConstants.SHOW_MOBILE_NAVIGATION_REGISTER:
      return { ...state, visibleMobileRegister: true }
    case menuConstants.SET_ROLES:
      return { ...state, roles: action.payload }
    default:
      return state
  }
}
