import React, { useState, useEffect } from 'react'
import { Link, useLocation, Redirect } from 'react-router-dom'
import { Animal } from '../../components/DragFoodToAnimals/Animal'
import { Food } from '../../components/DragFoodToAnimals/Food'
import ico from 'assets/ico-2-1.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import {
  selectGameContent,
  restart,
  toggleAnswer,
  fetchGameContent,
  selectTotalItems,
  next,
  selectCurrentStage,
  start,
  finish,
} from '_reducers/newGameSlice'
import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
export const GameSorting = ({ match }) => {
  const {
    params: { id },
  } = match
  const location = useLocation()
  const fromSurvey = location.state?.fromSurvey
  const therapeutic = location.state?.therapeutic
  const testMode = location.state?.testMode

  const gameContent = useSelector(selectGameContent)
  const totalItems = useSelector(selectTotalItems)
  const currentStage = useSelector(selectCurrentStage)

  const scrollBoxStyle = {
    margin: '40px 0',
    display: 'flex',
    justifyContent: 'space-evenly',
  }
  const [hit, setHit] = useState(0)
  const [attempt, setAttempt] = useState(0)
  const [clear, setClear] = useState(false)

  const onchange = (result) => {
    if (result) {
      setHit((dataHit) => dataHit + 1)
    }
    setAttempt((dataAttempt) => dataAttempt + 1)
  }

  const score = gameContent?.score

  useEffect(() => {
    const result = hit === number ? true : false
    if (attempt !== 0 && attempt === number) {
      dispatch(toggleAnswer({ result, id, score }))
      totalItems - 1 === currentStage && testMode && dispatch(finish())
    }
  }, [attempt])

  const dispatch = useDispatch()

  function getData() {
    return (dispatch) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/practiceStage/${id}/listAll`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const content = res.content?.[currentStage]
          const totalItems = res.totalItems
          dispatch(fetchGameContent({ content, totalItems }))
        })
    }
  }

  const [number, setNumber] = useState(0)

  function countElements() {
    let number = 0
    for (const [key, value] of Object.entries(gameContent)) {
      if (/^ElementB/.test(key) || /^ElementD/.test(key)) {
        value !== null && number++
      }
    }
    return number
  }

  useEffect(() => {
    dispatch(getData())
  }, [currentStage])

  useEffect(() => {
    dispatch(start())
  }, [])

  useEffect(() => {
    setNumber(countElements())
  }, [gameContent])

  const handleNext = () => {
    dispatch(next())
    setAttempt(0)
    setHit(0)
    setClear(true)
    setNumber(0)
  }

  return (
    <>
      <div className="game-area">
        <Breadcrumbs color="green" ico={ico} title={gameContent?.Practice?.title} description={gameContent?.Practice?.Game?.title} grandChild={true} />
        <p className="game-description">{gameContent?.Practice?.Game?.text}</p>
        <div className="drag_food_to_animals">
          <div className="foods" style={scrollBoxStyle}>
            {gameContent?.ElementB1?.photoThumbs && (
              <Food
                targetKey="data"
                label={gameContent?.ElementB1?.title}
                image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementB1?.photoThumbs?.['128x128']}`}
                name={gameContent?.ElementB1?.text}
                clear={clear}
              />
            )}
            {gameContent?.ElementD1?.photoThumbs && (
              <Food
                targetKey="data"
                label={gameContent?.ElementD1?.title}
                image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementD1?.photoThumbs?.['128x128']}`}
                name={gameContent?.ElementD1?.text}
                clear={clear}
              />
            )}
            {gameContent?.ElementB2?.photoThumbs && (
              <Food
                dragClone={true}
                targetKey="data"
                label={gameContent?.ElementB2?.title}
                image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementB2?.photoThumbs?.['128x128']}`}
                name={gameContent?.ElementB2?.text}
                clear={clear}
              />
            )}
            {gameContent?.ElementD2?.photoThumbs && (
              <Food
                targetKey="data"
                label={gameContent?.ElementD2?.title}
                image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementD2?.photoThumbs?.['128x128']}`}
                name={gameContent?.ElementD2?.text}
                clear={clear}
              />
            )}
            {gameContent?.ElementB3?.photoThumbs && (
              <Food
                targetKey="data"
                label={gameContent?.ElementB3?.title}
                image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementB3?.photoThumbs?.['128x128']}`}
                name={gameContent?.ElementB3?.text}
                clear={clear}
              />
            )}
            {gameContent?.ElementD3?.photoThumbs && (
              <Food
                targetKey="data"
                label={gameContent?.ElementD3?.title}
                image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementD3?.photoThumbs?.['128x128']}`}
                name={gameContent?.ElementD3?.text}
                clear={clear}
              />
            )}
            {gameContent?.ElementB4?.photoThumbs && (
              <Food
                targetKey="data"
                label={gameContent?.ElementB4?.title}
                image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementB4?.photoThumbs?.['128x128']}`}
                name={gameContent?.ElementB4?.text}
                clear={clear}
              />
            )}
            {gameContent?.ElementD4?.photoThumbs && (
              <Food
                targetKey="data"
                label={gameContent?.ElementD4?.title}
                image={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementD4?.photoThumbs?.['128x128']}`}
                name={gameContent?.ElementD4?.text}
                clear={clear}
              />
            )}
          </div>
          <div className="animals">
            {gameContent?.ElementA1?.photoThumbs && (
              <Animal
                targetKey="data"
                name={gameContent?.ElementA1?.text}
                dataAttempt={attempt}
                dataHit={hit}
                diagnostic={fromSurvey}
                onchange={(e) => {
                  onchange(e)
                }}
              >
                <img
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementA1.photoThumbs['128x128']}`}
                  width="100"
                  alt=""
                />
              </Animal>
            )}
            {gameContent?.ElementC1?.photoThumbs && (
              <Animal
                targetKey="data"
                name={gameContent?.ElementC1?.text}
                dataAttempt={attempt}
                dataHit={hit}
                diagnostic={fromSurvey}
                onchange={(e) => {
                  onchange(e)
                }}
              >
                <img
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementC1.photoThumbs['128x128']}`}
                  width="100"
                  alt=""
                />
              </Animal>
            )}
          </div>
        </div>

        {attempt !== 0 && attempt === number && (
          <>
            {!therapeutic && (
              <Link
                to={fromSurvey ? '/diagnostic-games/survey-games' : '/diagnostic-games/observation'}
                className="btn survey__btn"
                onClick={() => dispatch(restart())}
              >
                Zakończ
              </Link>
            )}
            {therapeutic && totalItems - 1 > currentStage && (
              <button onClick={handleNext} className="btn survey__btn">
                Następny etap
              </button>
            )}
            {therapeutic && totalItems - 1 === currentStage && !testMode && (
              <Link to={'/therapeutic-games'} className="btn survey__btn" onClick={() => dispatch(restart())}>
                Zakończ ćwiczenie
              </Link>
            )}
            {therapeutic && totalItems - 1 === currentStage && testMode && <Redirect to={`/therapeutic-games/results/${id}`} />}
          </>
        )}
        {therapeutic && (
          <nav className="dots">
            <p className="game-text">Postęp</p>
            {[...Array(totalItems)].map((item, i) =>
              currentStage === i ? <Link to className="dots__item active" /> : <Link to className="dots__item" />
            )}
          </nav>
        )}
      </div>
    </>
  )
}
