import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico1 from 'assets/ico-2-1.svg'
import ico2 from 'assets/ico-2-2.svg'
import ico3 from 'assets/ico-2-3.svg'
import ico4 from 'assets/ico-2-4.svg'
import icosm from 'assets/ico-2.svg'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'

export const DiagnosticGamesIndex = () => {
  return (
    <>
      <Breadcrumbs color="green" ico={icosm} title="Przykładowe gry" backHome={true} />
      <div className="boxes-list">
        {!hasRole(authConstants.ROLE_TESTER) && (
          <>
            <Link to="observation" className="boxes-list__item box">
              <div className="box__visuals box__visuals--green">
                <img className="box__ico" src={ico1} alt="" />
              </div>
              <h3 className="box__title box__title--mt0 box__title--bold">Bystre oko</h3>
              <p className="box__description">Identyfikacja, sortowanie przedmiotów i odwzorowywanie układów</p>
            </Link>
            <Link to="recognition" className="boxes-list__item box">
              <div className="box__visuals box__visuals--green">
                <img className="box__ico" src={ico2} alt="" />
              </div>
              <h3 className="box__title box__title--mt0 box__title--bold">
                Rozpoznawanie mowy, werbalizacja i komunikacja
              </h3>
              <p className="box__description">za pomocą symboli</p>
            </Link>
            <Link to="identification" className="boxes-list__item box">
              <div className="box__visuals box__visuals--green">
                <img className="box__ico" src={ico3} alt="" />
              </div>
              <h3 className="box__title box__title--mt0 box__title--bold">Identyfikacja emocji</h3>
              <p className="box__description">emocje na zdjęciach filmach</p>
            </Link>
          </>
        )}
        <Link to="imitation" className="boxes-list__item box">
          <div className="box__visuals box__visuals--green">
            <img className="box__ico" src={ico4} alt="" />
          </div>
          <h3 className="box__title box__title--mt0 box__title--bold">Naśladowanie emocji</h3>
          <p className="box__description">z wykorzystaniem sztucznej inteligencji</p>
        </Link>
      </div>
      {!hasRole(authConstants.ROLE_TESTER) && (
        <p className="info-text">Gry sa przenaczone dla dzieci - do 2 do 5 lat</p>
      )}
    </>
  )
}
