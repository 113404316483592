import React, { useState } from 'react'
import { authHeader } from '_helpers/authHeader'
import classNames from 'classnames'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-login-1.svg'
import TablePagination from '@material-ui/core/TablePagination'
import { translate } from '_helpers/translate'
import { useFetch } from '_helpers/useFetch'
import { useSelector, useDispatch } from 'react-redux'

export const ChildrenList = () => {
  const typingTimeout = 1000

  const listqueryParams = {
    search: '',
    page: 1,
    perPage: 12,
  }
  const [queryParams, setQueryParams] = useState(listqueryParams)
  const [typingState, setTypingState] = useState({ timeoutId: null })
  const [searchText, setSearchText] = useState(listqueryParams.search ? listqueryParams.search : '')

  const [selectedChildId, setSelectedChildId] = useState('')

  const handleSearchChange = (e) => {
    const search = e.target.value
    setSearchText(search)

    clearTimeout(typingState.timeoutId)

    const timeoutId = setTimeout(() => {
      setTypingState({
        ...typingState,
        timeoutId: null,
      })
      setQueryParams({
        ...queryParams,
        search: search,
        page: 1,
      })
    }, typingTimeout)

    setTypingState({
      ...typingState,
      timeoutId: timeoutId,
    })
  }

  const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/child/list`

  const auth = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  const saveSelectedChildId = (childId) => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/therapy`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        childId: childId,
      }),
    })
  }

  const handleSetActiveChild = (therapy) => {
    saveSelectedChildId(therapy.id)
    setSelectedChildId(therapy.id)
  }

  const getSelectedChildId = () => {
    if (selectedChildId) {
      return selectedChildId
    }
  }

  const [page, setPage] = useState(queryParams.page > 0 ? queryParams.page - 1 : 0)
  const [rowsPerPage, setRowsPerPage] = useState(queryParams.perPage ? queryParams.perPage : 12)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({
      ...queryParams,
      page: newPage + 1,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }

  return (
    <>
      <Breadcrumbs color="dark-purple" ico={ico} title="Dzieci" />

      <form action className="form">
        <div className="form__item">
          <input type="text" className="form__input" value={searchText} onChange={handleSearchChange} />
          <label className="form__label">Filtruj</label>
        </div>
      </form>

      <div className="results">
        {res.state.data?.content?.map((therapy, key) => {
          return (
            <div
              className={classNames(
                'results__item r-item checkbox-tile',
                getSelectedChildId() === therapy.id && 'checked'
              )}
              key={therapy.id}
              onClick={() => handleSetActiveChild(therapy)}
            >
              <h4 className="r-item__title">
                {therapy.firstName} {therapy.lastName}
              </h4>
              <p className="r-item__description">
                {getSelectedChildId() === therapy.id ? 'Utworzono terepie' : 'Utwórz terapie'}
              </p>
            </div>
          )
        })}
      </div>

      <TablePagination
        rowsPerPageOptions={[1, 2, 12]}
        labelRowsPerPage={translate('T_SHOW_ON_PAGE')}
        component="div"
        count={res.state.data?.totalItems !== undefined ? res.state.data?.totalItems : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  )
}
