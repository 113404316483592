import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestActions } from '_actions'
import { requestConstants, gamesConstants } from '_constants'
import { Grid, Dialog, DialogContent, Typography, IconButton, useMediaQuery } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles, useTheme } from '@material-ui/core/styles'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { fetchData } from '_helpers/fetchData'
import { notification } from '_helpers/notification'
import { dataToObj } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import { getProps } from '_helpers/tableProps'
import { useStyles } from 'components/form/FormStyles'
import { StringType } from 'components/form/StringType'
import { SelectType } from 'components/form/SelectType'
import { AutoCompleteType } from 'components/form/AutoCompleteType'
import { practiceStage as schema } from '_schema/practiceStage'
import { element as elementSchema } from '_schema/element'
import { gameEmotion as gameEmotionSchema } from '_schema/gameEmotion'
import { PracticeStagePreview } from './PracticeStagePreview'
import { useScreenshot } from 'use-screenshot-hook'

export const PracticeStageForm = ({
  open,
  game,
  dataResourceHandler,
  reloadPracticeStagesListHandler,
  handleClose,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const dispatch = useDispatch()
  const ref = useRef(null)
  const { image, takeScreenshot, isLoading } = useScreenshot({ ref: ref })

  const getPracticeStage = dataResourceHandler()
  const data = getPracticeStage()
  const elementTypes = { TYPE_PHOTO: '📷', TYPE_AUDIO: '🔊', TYPE_VIDEO: '🎦' }

  const [state, setState] = useState({
    values: {},
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
    elements: [],
    gameEmotions: [],
    image: null,
  })

  useEffect(() => {
    fetchData(gameEmotionSchema.list, 'GET', {}, (resp) => resp.json(), false).then((response) => {
      setState((state) => ({ ...state, gameEmotions: response.content }))
    })

    // tutaj odczytujemy wszystkie elementy dostępne dla tej gry i wrzucamy je do stora
    fetchData(
      elementSchema.listAllByGame.replace('{gameId}', game.id) + '/?order[title]=ASC',
      'GET',
      {},
      (resp) => resp.json(),
      false
    ).then((response) => {
      const elements = response.content.map((row) => {
        return {
          ...row,
          id: '/element/' + row.id,
          title: ((type) => elementTypes[type])(row.type) + ' ' + row.title,
        }
      })
      dispatch({
        type: gamesConstants.SAVE_GAME_ELEMENTS,
        payload: elements,
      })
    })
  }, [])

  const fields = {
    title: {
      type: 'string',
      validate: ['required'],
      label: translate('T_NAME_LABEL'),
      hint: translate('T_NAME_HINT'),
    },
    text: {
      type: 'textarea',
      validate: ['required'],
      label: translate('T_DESCRIPTION_LABEL'),
      hint: translate('T_DESCRIPTION_HINT'),
    },
    gameIdName: {
      value: game.idname,
    },
  }

  if (game.idname === 'GAME_MIMIC_IMITATION') {
    fields.gameEmotionId = {
      type: 'select',
      addEmpty: true,
      validate: ['required'],
      options: state.gameEmotions,
      label: translate('T_GAME_EMOTION_LABEL'),
      hint: translate('T_GAME_EMOTION_HINT'),
    }

    fields.timeLimit = {
      type: 'number',
      min: 0,
      validate: [],
      label: translate('T_TIME_SECONDS_LIMIT_LABEL'),
      hint: translate('T_TIME_SECONDS_LIMIT_HINT'),
    }
  }

  // odczyt stora
  const elements = useSelector((state) => state.game.elements)

  const gameFields = {
    //                               głowny   poprawny              niepoprawny
    '': [],
    GAME_IDENTIFICATION:              ['A1',  'B1','B2',            'C1','C2'                                ],
    GAME_SORTING:                     ['A1',  'B1','B2','B3','B4',  'C1',                 'D1','D2','D3','D4'],
    GAME_MAPPING:                     [       'B1','B2','B3',                             'D1','D2','D3'     ],
    GAME_UNDERSTANDING_SPEECH:        ['A1',  'B1','B2','B3','B4'                                            ],
    GAME_SPEAKING:                    ['A1'                                                                  ],
    GAME_EMOTIONS_IDENTIFICATION:     ['A1',  'B1',                 'C1','C2'                                ],
    GAME_GESTURES_IMITATION:          ['A1'                                                                  ],
    GAME_SUBIECTS_IMITATION:          ['A1'                                                                  ],
    GAME_MIMIC_IMITATION:             ['A1'                                                                  ],
    GAME_VERBAL_COMMUNICATION:        ['A1',  'B1','B2',            'C1','C2'                                ],
  }

  // eslint-disable-next-line array-callback-return
  gameFields[game.idname].map((fieldName) => {
    fields['Element' + fieldName] = {
      type: 'element',
      addEmpty: true,
      validate: [],
      value: (() =>
        data['Element' + fieldName] ? '/element/' + getProps(data, '.Element' + fieldName + '.id') : null)(),
      options: elements,
      label: translate('T_GAME_ELEMENT_LABEL') + ' ' + fieldName,
      hint: translate('T_GAME_ELEMENT_HINT'),
      title: data['Element' + fieldName]?.title,
      image: data['Element' + fieldName]?.photoThumbs?.['128x128'],
    }
  })

  const handleSuccess = (resp) => {
    reloadPracticeStagesListHandler()
    handleClose()
  }

  const handleSubmit = (e) => {
    e && e.preventDefault()

    if (
      Object.keys(state.validators).length &&
      !Object.keys(state.validators).reduce(
        (status, name) =>
          state.validators[name].validator(
            ...Object.keys(state.values)
              .filter((item) => state.validators[name].values.includes(item))
              .map((item) => state.values[item])
          ) && status,
        true
      )
    ) {
      return
    }

    setState((state) => ({ ...state, isProcessing: true }))

    const url = schema.set.replace('{id}', data.id)

    dispatch(requestActions.start(url))

    fetchDataHandleAuthError(
      url,
      'PUT',
      { body: JSON.stringify(dataToObj(state.values)) },
      (resp) => {
        setState((state) => ({ ...state, isProcessing: false }))
        dispatch(requestActions.success())
        notification('success', translate('T_RECORD_UPDATED'), translate('T_SAVED'))
        handleSuccess && handleSuccess(resp)
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))

        notification(
          'error',
          error.response.violations.length ? translate('T_INCORRECT_FORM') : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
    return false
  }

  const setValue = (name, value) => {
    const isInvalid = value instanceof Error
    setState((state) => ({
      ...state,
      values: { ...state.values, [name]: !isInvalid && value },
      errors: { ...state.errors, [name]: isInvalid },
      invalid: state.invalid + (!!state.errors[name] === !isInvalid && -1 + isInvalid * 2),
    }))
  }

  const setValidator = (name, validator, values) => {
    setState((state) => ({
      ...state,
      validators: {
        ...state.validators,
        [name]: { validator, values: values || [name] },
      },
    }))
  }

  const setErrorMessageSetter = (name, errorMessageSetter) => {
    setState((state) => ({
      ...state,
      errorMessageSetters: {
        ...state.errorMessageSetters,
        [name]: errorMessageSetter,
      },
    }))
  }

  const handleScreenshot = (e) => {
    e.preventDefault()
    takeScreenshot()
  }

  useEffect(() => {
    setState((state) => ({
      ...state,
      values: { ...state.values, image: image },
    }))
    image && !isLoading && handleSubmit(null)
  }, [image, isLoading])

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  })

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullScreen={fullScreen} maxWidth="md">
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <DialogTitle onClose={handleClose}>{translate('T_GAME_PRACTICE_STAGE_CONFIGURATION')}</DialogTitle>
            <DialogContent>
              <form className={classes.root}>
                <Grid container spacing={2}>
                  {Object.keys(fields).map((name, key) => {
                    if (!fields[name]) {
                      return null
                    }
                    return (
                      <React.Fragment key={`${key}_fragment`}>
                        {name === 'ElementA1' && fields.gameIdName.value !== 'GAME_SORTING' && (
                          <Grid item xs={12} key={`${key}_grid_separatorA`}>
                            Obiekt główny:
                          </Grid>
                        )}
                        {name === 'ElementA1' && fields.gameIdName.value === 'GAME_SORTING' && (
                          <Grid item xs={12} key={`${key}_grid_separatorA`}>
                            Obiekt w pojemniku nr1:
                          </Grid>
                        )}
                        {name === 'ElementB1' && fields.gameIdName.value === 'GAME_IDENTIFICATION' && (
                          <Grid item xs={12} key={`${key}_grid_separatorB`}>
                            Obiekty prawidłowe:
                          </Grid>
                        )}
                        {name === 'ElementB1' && fields.gameIdName.value === 'GAME_SORTING' && (
                          <Grid item xs={12} key={`${key}_grid_separatorA`}>
                            Obiekty do pojemnika nr1:
                          </Grid>
                        )}
                        {name === 'ElementB1' && fields.gameIdName.value === 'GAME_UNDERSTANDING_SPEECH' && (
                          <Grid item xs={12} key={`${key}_grid_separatorB`}>
                            Obiekty prawidłowe:
                          </Grid>
                        )}
                        {name === 'ElementB1' && fields.gameIdName.value === 'GAME_EMOTIONS_IDENTIFICATION' && (
                          <Grid item xs={12} key={`${key}_grid_separatorB`}>
                            Obiekty prawidłowe:
                          </Grid>
                        )}
                        {name === 'ElementB1' && fields.gameIdName.value === 'GAME_VERBAL_COMMUNICATION' && (
                          <Grid item xs={12} key={`${key}_grid_separatorB`}>
                            Obiekty prawidłowe:
                          </Grid>
                        )}
                        {name === 'ElementB1' && fields.gameIdName.value === 'GAME_MAPPING' && (
                          <Grid item xs={12} key={`${key}_grid_separatorBx`}>
                            Obiekty we wzorze:
                          </Grid>
                        )}
                        {name === 'ElementC1' && fields.gameIdName.value === 'GAME_IDENTIFICATION' && (
                          <Grid item xs={12} key={`${key}_grid_separatorC`}>
                            Pula obiektów błędnych:
                          </Grid>
                        )}
                        {name === 'ElementC1' && fields.gameIdName.value === 'GAME_SORTING' && (
                          <Grid item xs={12} key={`${key}_grid_separatorA`}>
                            Obiekt w pojemniku nr2:
                          </Grid>
                        )}
                        {name === 'ElementC1' && fields.gameIdName.value === 'GAME_EMOTIONS_IDENTIFICATION' && (
                          <Grid item xs={12} key={`${key}_grid_separatorB`}>
                            Pula obiektów błędnych:
                          </Grid>
                        )}
                        {name === 'ElementC1' && fields.gameIdName.value === 'GAME_VERBAL_COMMUNICATION' && (
                          <Grid item xs={12} key={`${key}_grid_separatorB`}>
                            Pula obiektów błędnych:
                          </Grid>
                        )}
                        {name === 'ElementD1' && fields.gameIdName.value === 'GAME_SORTING' && (
                          <Grid item xs={12} key={`${key}_grid_separatorA`}>
                            Obiekty do pojemnika nr2:
                          </Grid>
                        )}
                        {name === 'ElementD1' && fields.gameIdName.value === 'GAME_MAPPING' && (
                          <Grid item xs={12} key={`${key}_grid_separatorDx`}>
                            Obiekty do wyboru:
                          </Grid>
                        )}

                        <Grid item xs={12} key={`${key}_grid`}>
                          {(fields[name].type === 'string' || fields[name].type === 'textarea') && (
                            <StringType
                              key={key}
                              name={fields[name].name ? fields[name].name : name}
                              label={fields[name].label}
                              type={fields[name].type}
                              hint={fields[name].hint}
                              value={
                                data &&
                                (fields[name]['resource'] ? getProps(data, fields[name]['resource']) : data[name])
                              }
                              disabled={fields[name].disabled === true ? true : state.isProcessing}
                              validators={fields[name].validate || []}
                              setValue={setValue}
                              setValidator={setValidator}
                              setErrorMessageSetter={setErrorMessageSetter}
                            />
                          )}

                          {fields[name].type === 'number' && (
                            <StringType
                              key={key}
                              name={fields[name].name ? fields[name].name : name}
                              label={fields[name].label}
                              type={fields[name].type}
                              hint={fields[name].hint}
                              value={
                                data &&
                                (fields[name]['resource']
                                  ? getProps(data, fields[name]['resource'])
                                  : data[name]
                                  ? data[name]
                                  : 0)
                              }
                              disabled={fields[name].disabled === true ? true : state.isProcessing}
                              validators={fields[name].validate || []}
                              setValue={setValue}
                              setValidator={setValidator}
                              setErrorMessageSetter={setErrorMessageSetter}
                            />
                          )}

                          {fields[name].type === 'select' && (
                            <SelectType
                              key={key}
                              value={
                                data &&
                                (fields[name]['resource'] ? getProps(data, fields[name]['resource']) : data[name])
                              }
                              options={fields[name].options}
                              addEmpty={fields[name].addEmpty}
                              multiple={fields[name].multiple}
                              name={name}
                              validators={fields[name].validate || []}
                              label={fields[name].label}
                              hint={fields[name].hint}
                              setValue={setValue}
                              setValidator={setValidator}
                              setErrorMessageSetter={setErrorMessageSetter}
                            />
                          )}

                          {fields[name].type === 'element' && (
                            <AutoCompleteType
                              value={fields[name].value ? fields[name].value : null}
                              inputValue={fields[name].title ? fields[name].title : ''}
                              imageValue={fields[name].image ? fields[name].image : null}
                              key={key}
                              options={fields[name].options}
                              addEmpty={fields[name].addEmpty}
                              multiple={fields[name].multiple}
                              name={name}
                              validators={fields[name].validate || []}
                              label={fields[name].label}
                              hint={fields[name].hint}
                              setValue={setValue}
                              setValidator={setValidator}
                              setErrorMessageSetter={setErrorMessageSetter}
                              custom={true}
                            />
                          )}
                        </Grid>
                      </React.Fragment>
                    )
                  })}
                </Grid>
                <Grid container spacing={3}>
                  <div className="popup__btns">
                    <button onClick={handleClose} className="btn btn--no-fill">
                      {translate('T_CLOSE')}
                    </button>

                    <button
                      type="submit"
                      onClick={(e) => handleScreenshot(e)}
                      className="btn"
                      disabled={state.isProcessing || !!state.invalid}
                    >
                      {translate('T_SAVE')}
                    </button>
                  </div>
                </Grid>
              </form>
            </DialogContent>
          </Grid>

          <Grid item xs={12}>
            <DialogTitle>{translate('T_GAME_PRACTICE_STAGE_PREVIEW')}</DialogTitle>
            <DialogContent>
              <div style={{ maxWidth: 'fit-content' }} ref={ref}>
                <PracticeStagePreview
                  gameIdname={game.idname}
                  fieldsNames={gameFields[game.idname]}
                  fieldsValues={state.values}
                />
              </div>
            </DialogContent>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}
