import React from 'react'
import { GamesCategoryList } from 'components/GamesCategoryList'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-2-2.svg'
import { userActions } from '_actions'
import { useSelector, useDispatch } from 'react-redux'

export const Recognition = () => {
  const ids = ['GAME_UNDERSTANDING_SPEECH', 'GAME_SPEAKING', 'GAME_VERBAL_COMMUNICATION']
  const idsValues = [4, 5, 10]
  const routes = {
    GAME_UNDERSTANDING_SPEECH: {
      url: '/diagnostic-games/recognition/game-understanding-speech/:id',
      name: 'Rozumienie mowy',
    },
    GAME_SPEAKING: { url: '/diagnostic-games/recognition/game-speaking/:id', name: 'Mówienie' },
    GAME_VERBAL_COMMUNICATION: {
      url: '/diagnostic-games/recognition/game-verbal-communication/:id',
      name: 'Komunikacja werbalna',
    },
  }
  const dispatch = useDispatch()
  const checked_R = useSelector((state) => state.user.verifyByParent)

  const handleCheckbox = () => {
    dispatch(userActions.verifyByParent())
  }

  return (
    <>
      <div className="game-area">
        <Breadcrumbs color="green" ico={ico} title="Rozpoznawanie mowy, werbalizacja i komunikacja" />
        <GamesCategoryList ids={ids} routes={routes} idsValues={idsValues} />

        <div className="switch">
          <p className="switch__text">{checked_R ? 'Weryfikuje rodzic ' : 'Weryfikuje aplikacja'}</p>
          <input type="checkbox" className="switch__input" id="switch" checked={checked_R} onChange={handleCheckbox} />
          <label htmlFor="switch" className="switch__label">
            Toggle
          </label>
        </div>
      </div>
    </>
  )
}
