import { combineReducers } from 'redux'
import { authReducer } from './auth.reducer'
import { userReducer } from './user.reducer'
import { menuReducer } from './menu.reducer'
import { schemaReducer } from './schema.reducer'
import { soundReducer } from './sound.reducer'
import quizReducer from './quizSlice'
import newGameReducer from './newGameSlice'
import { gameReducer } from './game.reducer'

const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  menu: menuReducer,
  schema: schemaReducer,
  quiz: quizReducer,
  newGame: newGameReducer,
  sound: soundReducer,
  game: gameReducer,
})

export default reducers
