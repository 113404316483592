import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import { useDispatch } from 'react-redux'
import { menuActions } from '_actions'
import arrow from 'assets/arrow-left.svg'

export const Add = ({ roles }) => {
  const history = useHistory()
  const [send, setSend] = useState(false)
  const dispatch = useDispatch()
  const handleToggleRegister = () => {
    dispatch(menuActions.toggleMobileNavigationRegister())
    history.push('/')
  }

  function Confirm() {
    return (
      <div className="form">
        <div>
          <p>
            <b>Aktywuj konto</b>
          </p>
          <p>Sprawdź swoją skrzynkę mailową i dokończ rejestrację.</p>
        </div>
        <button className="form__btn btn btn--no-fill" onClick={handleToggleRegister}>
          Zamknij
        </button>
      </div>
    )
  }

  const initialState = {
    email: '',
    plainPassword: '',
    firstName: '',
    lastName: '',
    login: '',
    phone: '',
    provinceId: '',
    healthCenterId: '',
    Professions: [],
    dataProcessingAgreement: false,
    rulesAgreement: false,
    isSubmitting: false,
  }
  const [errors, setErrors] = useState({})
  const [data, setData] = useState(initialState)

  const handleInputChange = (event) => {
    setErrors(errors => ({...errors, [event.target.name]: ''}));
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleCheckboxChange = (event) => {
    setErrors(errors => ({...errors, [event.target.name]: ''}));
    setData({
      ...data,
      [event.target.name]: event.target.checked,
    })
  }

  const handleMultiId = (event) => {
    setData({
      ...data,
      healthCenterId: parseInt(event.target.value),
      provinceId: parseInt(event.target.options[event.target.selectedIndex].dataset.id),
    })
  }

  const handleMultiOptions = (event) => {
    const values = Array.from(event.target.selectedOptions, (option) => parseInt(option.value))
    setData({
      ...data,
      Professions: values,
    })
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    setData({
      ...data,
      isSubmitting: true,
    })
    setErrors({})

    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/registration/${roles}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        plainPassword: data.plainPassword,
        phone: data.phone,
        provinceId: data.provinceId,
        healthCenterId: data.healthCenterId,
        Professions: data.Professions,
        dataProcessingAgreement: data.dataProcessingAgreement,
        rulesAgreement: data.rulesAgreement,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw res.json()
      })
      .then(() => {
        setSend(true)
        notification('success', 'Aktywuj konto', 'Wysłano')
      })
      .catch((errorPromise) => {
        setData({
          ...data,
          isSubmitting: false,
        })
        errorPromise.then((error) => {
          if (error.violations) {
              error.violations.forEach((e) => {
                setErrors(errors => ({...errors, [e.propertyPath]: e.message}));
            })
          }
        })
      })
    }

  const [healthCenter, setHealthCenter] = useState([])

  const getHealthCenter = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/healthCenter/list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setHealthCenter(res)
      })
  }

  useEffect(() => {
    getHealthCenter()
  }, [])

  const [professions, setProfessions] = useState([])

  const getProfessions = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/professions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setProfessions(res)
      })
  }

  useEffect(() => {
    getProfessions()
  }, [])

  useEffect(() => {
    setData({
      ...data,
      healthCenterId: healthCenter?.content?.[0]?.id,
      provinceId: healthCenter?.content?.[0]?.provinceId,
      Professions: [professions?.content?.[0]?.id],
    })
  }, [healthCenter, professions])

  return (
    <>
      <Link to className="login-content__link login-bar-back" onClick={handleToggleRegister}>
        <img className="arrow" src={arrow} alt="" /> Rejestracja
        <span className="role">{roles === 'parent' ? 'rodzica' : 'terapeuty'}</span>
      </Link>
      {!send ? (
        <form className="form" noValidate onSubmit={handleFormSubmit}>
          <p>wszystkie pola wymagane</p>
          <div className="form__item">
            <input
              type="email"
              className="form__input"
              required
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={data.email}
              onChange={handleInputChange}
            />
            <label className="form__label">
              {errors.email ? <span className="error">{errors.email}</span> : 'e-mail'}
            </label>
          </div>
          <div className="form__item">
            <input
              className="form__input"
              required
              name="plainPassword"
              label="Hasło"
              type="password"
              id="plainPassword"
              inputProps={{
                autocomplete: 'new-password',
                form: {
                  autocomplete: 'off',
                },
              }}
              value={data.plainPassword}
              onChange={handleInputChange}
            />
            <label className="form__label">
              {errors.plainPassword ? <span className="error">{errors.plainPassword}</span> : 'hasło'}
            </label>
          </div>

          <div className="form__item">
            <input
              type="text"
              className="form__input"
              required
              id="firstName"
              label="firstName"
              name="firstName"
              value={data.firstName}
              onChange={handleInputChange}
            />
            <label className="form__label">
              {errors.firstName ? <span className="error">{errors.firstName}</span> : 'imię'}
            </label>
          </div>
          <div className="form__item">
            <input
              type="text"
              className="form__input"
              required
              id="lastName"
              label="lastName"
              name="lastName"
              value={data.lastName}
              onChange={handleInputChange}
            />
            <label className="form__label">
              {errors.lastName ? <span className="error">{errors.lastName}</span> : 'nazwisko'}
            </label>
          </div>
          <div className="form__item">
            <input
              type="tel"
              className="form__input"
              required
              id="phone"
              label="phone"
              name="phone"
              value={data.phone}
              onChange={handleInputChange}
            />
            <label className="form__label">
              {errors.phone ? <span className="error">{errors.phone}</span> : 'telefon'}
            </label>
          </div>
          {roles === 'therapist' && (
            <>
              <div className="form__item" style={{ display: 'none' }}>
                <select
                  className="custom-select profile-form__select"
                  id="healthCenterId"
                  name="healthCenterId"
                  value={data?.healthCenterId}
                  onChange={handleMultiId}
                >
                  <option selected hidden>
                    Wybierz
                  </option>
                  {healthCenter?.content?.map((item) => (
                    <option key={item.id} value={item.id} data-id={item.provinceId}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <label className="form__label">Ośrodek</label>
              </div>
              <div className="form__item" style={{ display: 'none' }}>
                <select
                  className="custom-select profile-form__select"
                  id="Professions"
                  name="Professions"
                  value={data?.Professions}
                  onChange={handleMultiOptions}
                  multiple
                >
                  <option selected hidden>
                    Wybierz
                  </option>
                  {professions?.content?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                <label className="form__label">Specjalizacja</label>
              </div>
            </>
          )}
          <label className="checkbox-label">
            <input
              id="dataProcessingAgreement"
              name="dataProcessingAgreement"
              onChange={handleCheckboxChange}
              type="checkbox"
              required
            />
            Zgoda na przetwarzanie danych
          </label>

          {errors.dataProcessingAgreement && <span className="error">{errors.dataProcessingAgreement}</span>}

          <label className="checkbox-label">
            <input
              id="rulesAgreement"
              name="rulesAgreement"
              onChange={handleCheckboxChange}
              type="checkbox"
              required
            />
            Akceptuję Regulamin i Politykę prywatności
          </label>

          {errors.rulesAgreement && <span className="error">{errors.rulesAgreement}</span>}

          <button className="btn form__btn" disabled={data.isRegistering} type="submit">
            {data.isRegistering ? (
              <img className="spinner spinner--sm" src="/images/logo192.png" alt="loading icon" />
            ) : (
              'Załóż konto'
            )}
          </button>
        </form>
      ) : (
        <Confirm />
      )}
    </>
  )
}
