import React from 'react'
import MicRecorder from 'mic-recorder-to-mp3'
//import { Link } from 'react-router-dom'
import { notification } from '_helpers/notification'
import classNames from 'classnames'
const Mp3Recorder = new MicRecorder({ bitRate: 128 })


class VoiceRecorder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      answer: false,
      error: null,
    }
  }

  start = () => {
    if (this.state.isBlocked) {
      console.log('Permission Denied')
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({ isRecording: true })
        })
        .catch((e) => console.error(e))
    }
  }

  stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        this.setState({ blobURL, isRecording: false })
      })
      .catch((e) => console.log(e))
  }

  handleClick = (result) => {
    this.setState({ answer: true })

    this.props.onchange(result)
  }

  run = async () => {
    try {
      await navigator.mediaDevices.getUserMedia(
        { audio: true },
        () => {
          console.log('Permission Granted')
          this.setState({ isBlocked: false })
        },
        () => {
          console.log('Permission Denied')
          this.setState({ isBlocked: true })
        }
      )
    } catch (e) {
      this.setState({ error: e })
      this.state.error?.message === 'Requested device not found' &&
        notification('error', 'Nie znaleziono mikrofonu.', null, 8000)
    }
  }

  componentDidMount() {
    this.run()
  }

  render() {
    return (
      <div className="audio">
        <div className="audio__controls">
          {this.state.error?.message === 'Requested device not found' ? (
            <button className="audio__btn btn btn--red" disabled>
              Podłącz mikrofon
            </button>
          ) : (
            <button className="audio__btn btn btn--green" onClick={this.start} disabled={this.state.isRecording}>
              {this.props.play}
            </button>
          )}
          <button className="audio__btn btn btn--no-fill" onClick={this.stop} disabled={!this.state.isRecording}>
            Zatrzymaj
          </button>
        </div>

        <audio className="audio__player" src={this.state.blobURL} controls="controls" />
        {this.state.blobURL && this.props.checked_R && !this.props.diagnostic && (
          <div className={classNames('audio__controls buttons', this.state.answer && 'disabled')}>
            <button className="audio__btn btn btn--green" onClick={() => this.handleClick(true)}>
              Dobrze
            </button>
            <button className="audio__btn btn btn--no-fill" onClick={() => this.handleClick(false)}>
              Źle
            </button>
          </div>
        )}
        {this.state.blobURL && this.props.diagnostic && (
          <div className={classNames('audio__controls buttons', this.state.answer && 'disabled')}>
            <button className="audio__btn btn btn--green" onClick={() => this.handleClick(true)}>
              Dobrze
            </button>
            <button className="audio__btn btn btn--no-fill" onClick={() => this.handleClick(false)}>
              Źle
            </button>
          </div>
        )}
        {/*this.state.blobURL && this.state.answer && (
          <Link
            to={this.props.fromSurvey ? '/diagnostic-games/survey-games' : '/diagnostic-games/recognition'}
            className="btn survey__btn"
          >
            Zakończ
          </Link>
        )}
        {(this.state.blobURL && !this.props.checked_R) ||
          (this.state.blobURL &&
            this.props.diagnostic(
              <Link
                to={this.props.fromSurvey ? '/diagnostic-games/survey-games' : '/diagnostic-games/recognition'}
                className="btn survey__btn"
              >
                Zakończ
              </Link>
            ))*/}
      </div>
    )
  }
}

export default VoiceRecorder
