import React, { useState, useEffect } from 'react'
import { DropTarget } from 'react-drag-drop-container'
import { notification } from '_helpers/notification'
import { useSelector } from 'react-redux'
import useSound from 'use-sound'

export const Animal = (props) => {
  const successSound_R = useSelector((state) => state.sound.successSound)
  const failureSound_R = useSelector((state) => state.sound.failureSound)
  const [playSuccess] = useSound(successSound_R)
  const [playFailure] = useSound(failureSound_R)
  const [state, setState] = useState({ thankYouMessage: '' })

  const dropped = (e) => {
    if (e.dragData.name === props.name) {
      setState({
        thankYouMessage: `Dziękuje za ${e.dragData.label}!`,
      })
      props.onchange(true)
    } else {
      if (!props.diagnostic) {
        notification('error', 'Źle!', 'Błąd')
        playFailure()
      }
      props.onchange(false)
    }
    e.containerElem.style.visibility = 'hidden'
  }

  useEffect(() => {
    if (state.thankYouMessage) {
      if (!props.diagnostic) {
        notification('success', state.thankYouMessage, 'Komunikat')
        playSuccess()
      }
    }
  }, [state.thankYouMessage])

  return (
    <DropTarget onHit={dropped} targetKey={props.targetKey} dropData={{ name: props.name }}>
      <div className="animal">{props.children}</div>
    </DropTarget>
  )
}
