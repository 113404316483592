import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  startedAt: null,
  finishedAt: null,
  activeQuestion: 0,
  result: 0,
  data: {},
  checked: [],
  noGames: false
}

export const slice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    start: (state) => {
      state.startedAt = Date.now()
    },
    finish: (state) => {
      let isChecked
      let points = 0
      let verdict = {}
      let checked = []

      state.data.Questions.forEach((q) => {
        isChecked = q.Answers.filter((a) => a.checked === true)
        const clone = (({ checked, score, text, ...obj }) => obj)(isChecked[0])
        const { id: answerId, ...rest } = clone
        const renamed = { answerId, ...rest }
        const newKey = { questionId: q.id }
        const added = { ...renamed, ...newKey }
        checked.push(added)
        points += isChecked[0].score
      })

      state.data.Verdicts.forEach((q) => {
        if (points >= q.scoreMin && points <= q.scoreMax) {
          verdict = q
        }
      })

      state.checked = checked
      state.points = points
      state.verdict = verdict
      state.finishedAt = Date.now()

      if (state.data.InquiryPractices.length === 0) {
        state.noGames = true
      }
    },
    restart: (state) => {
      state.startedAt = null
      state.finishedAt = null
      state.activeQuestion = 0
      state.data = initialState.data
    },
    next: (state) => {
      state.activeQuestion += 1
    },
    prev: (state) => {
      state.activeQuestion -= 1
    },
    toggleAnswer: (state, action) => {
      const { answer } = action.payload

      const question = state.data.Questions[state.activeQuestion]

      question.Answers.forEach((a) => {
        a.checked = false
      })

      question.Answers[answer].checked = true
    },
    fetchData: (state, action) => {
      state.data = action.payload
    },
    childId: (state, action) => {
      state.childId = action.payload
    },
    inquiryId: (state, action) => {
      state.inquiryId = action.payload
    },
  },
})

export const { start, finish, toggleAnswer, next, prev, restart } = slice.actions

export const selectStartedAt = (state) => state.quiz.startedAt

export const selectFinishedAt = (state) => state.quiz.finishedAt

export const selectData = (state) => state.quiz.data

export const selectNoGames = (state) => state.quiz.noGames

export const selectChildId = (state) => state.quiz.childId

export const selectInquiryId = (state) => state.quiz.inquiryId

export const selectChecked = (state) => state.quiz.checked

export const selectActiveQuestion = (state) => state.quiz.activeQuestion

export const selectPoints = (state) => state.quiz.points

export const selectVerdict = (state) => state.quiz.verdict

export default slice.reducer
