import { authConstants, gamesConstants } from '_constants'

export const gameReducer = (state = [], action) => {
  switch (action.type) {
    case gamesConstants.SAVE_GAME_ELEMENTS:
      // wrzucenie do stora danych
      const elementsById = {}
      // eslint-disable-next-line array-callback-return
      action.payload.map((row, key) => {
        elementsById[row.id] = row
      })

      return {
        // to wpada do stora
        elements: action.payload,
        elementsById: elementsById,
      }

    case authConstants.REFRESH_FAILURE:
    case authConstants.LOGIN_FAILURE:
    case authConstants.LOGOUT:
      // wyszyczesnie pamięci gameReducera podczas wylogowania
      return []

    default:
      return state
  }
}
