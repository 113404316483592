import React from 'react'
import { GamesSurveyList } from 'components/GamesSurveyList'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-2.svg'
export const SurveyGames = () => {
  const routes = {
    GAME_IDENTIFICATION: { url: '/diagnostic-games/observation/game-identification/:id', name: 'Identyfikacja' },
    GAME_SORTING: { url: '/diagnostic-games/observation/game-sorting/:id', name: 'Sortowanie przedmiotów' },
    GAME_MAPPING: { url: '/diagnostic-games/observation/game-mapping/:id', name: 'Odwzorowywanie układów' },
    GAME_UNDERSTANDING_SPEECH: {
      url: '/diagnostic-games/recognition/game-understanding-speech/:id',
      name: 'Rozumienie mowy',
    },
    GAME_SPEAKING: { url: '/diagnostic-games/recognition/game-speaking/:id', name: 'Mówienie' },
    GAME_EMOTIONS_IDENTIFICATION: {
      url: '/diagnostic-games/identification/game-emotions-identification/:id',
      name: 'Identyfikacja emocji',
    },
    GAME_MIMIC_IMITATION: { url: '/diagnostic-games/imitation/game-mimic-imitation/:id', name: 'Naśladowanie mimiki' },
    GAME_GESTURES_IMITATION: {
      url: '/diagnostic-games/imitation/game-gestures-imitation/:id',
      name: 'Naśladowanie gestów',
    },
    GAME_SUBIECTS_IMITATION: {
      url: '/diagnostic-games/imitation/game-subjects-imitation/:id',
      name: 'Naśladowanie z przedmiotami',
    },
    GAME_VERBAL_COMMUNICATION: {
      url: '/diagnostic-games/recognition/game-verbal-communication/:id',
      name: 'Komunikacja werbalna',
    },
  }

  return (
    <div className="game-area">
      <Breadcrumbs color="green" ico={ico} title="Przykładowe gry" />
      <GamesSurveyList routes={routes} />
    </div>
  )
}
