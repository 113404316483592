import React from 'react'
import { Schemable } from 'components/Schemable'
import { Loader } from 'components/Loader'
import { useSelector } from 'react-redux'
import { ProfileForm } from './ProfileForm'
import { ChangePasswordForm } from './ChangePasswordForm'
import { Breadcrumbs } from 'components/Breadcrumbs'
import icoLogin1 from 'assets/ico-login-1.svg'
import { SubscriptionForm } from './SubscriptionForm'
import { PaymentsList } from './PaymentsList'

export const ProfileContainer = () => {
  const profile = useSelector((state) => state.user?.profile)

  return (
    <>
      <Breadcrumbs color="orange" ico={icoLogin1} title="Profil - edycja" backHome={true} />
      <div className="profile-form form">
        <SubscriptionForm />
        <PaymentsList />
        <Schemable>
          {profile ? (
            <>
              <ProfileForm resource={profile} />

              <ChangePasswordForm resource={profile} />
            </>
          ) : (
            <Loader align="center" />
          )}
        </Schemable>
      </div>
    </>
  )
}
