import React, { useState, useEffect } from 'react'
import ico from 'assets/ico-1-orange.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { useSelector, useDispatch } from 'react-redux'
import TableList from './TableList'
import { authHeader } from '_helpers/authHeader'
import { useFetch } from '_helpers/useFetch'
import { setProps } from '_helpers/tableProps'
import { Graph } from './Graph'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { fetchData } from '_helpers/fetchData'
import * as practiceCategorySchema from '_schema/practiceCategory'
import { translate } from '_helpers/translate'

const listCols = () => {
  return {
    thumb: 'Cel',
    title: 'Wprowadzenie',
    text: 'Ustanowienie',
    displayCollumn: 3,
  }
}

const mainData = () => {
  return setProps(listCols())
}
const tabItems = [
  {
    id: 1,
    title: 'Umiejętności',
  },
  {
    id: 2,
    title: 'Wykres',
  },
]
const TabItemComponent = ({
  title = '',
  onItemClicked = () => console.error('You passed no action to the component'),
  isActive = false,
}) => {
  return (
    <button className={isActive ? 'tab-menu-result active' : 'tab-menu-result'} onClick={onItemClicked}>
      <span>{title}</span>
    </button>
  )
}

export const TherapyResults = () => {
  const childId_R = useSelector((state) => state.user.profile?.SelectedChild?.id)
  const therapyId = useSelector((state) => state.user.profile?.SelectedChild?.therapyId)

  const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/practiceSolution/allByChildAndTherapy/${childId_R}/${therapyId}`

  const listqueryParams = {
    page: 1,
    perPage: 12,
    'order[name]': 'ASC',
  }

  const auth = useSelector((state) => state.auth)
  const [queryParams, setQueryParams] = useState(listqueryParams)

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }

  const args = [auth.data.token, dispatch]
  const res = useFetch(endPoint, options, args, queryParams)

  const [active, setActive] = useState(1)

  useEffect(() => {
    res.state.data &&
      setQueryParams((queryParams) => ({
        ...queryParams,
        perPage: active === 1 ? 12 : 25,
      }))
  }, [active])

  const [state, setState] = useState({
    practiceCategories: [],
  })

  useEffect(() => {
    fetchData(practiceCategorySchema.practiceCategory.list, 'GET', {}, (resp) => resp.json(), false).then(
      (response) => {
        setState((state) => ({ ...state, practiceCategories: response.content }))
      }
    )
  }, [])

  const handleFilter = (e) => {
    const value = e.target.value
    const name = e.target.name
    setQueryParams({
      ...queryParams,
      [name]: value,
      page: 1,
    })
  }

  return (
    <>
      <Breadcrumbs color="orange" ico={ico} title="Wyniki i historia" />
      <div className="container-full mob-pad0">
        <div className="tab-menu">
          {tabItems.map(({ id, icon, title }) => (
            <TabItemComponent
              key={title}
              icon={icon}
              title={title}
              onItemClicked={() => setActive(id)}
              isActive={active === id}
            />
          ))}
        </div>
        <div className="tab-bar-content active">
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0' }}>
            <FormControl style={{ minWidth: '243px' }}>
              <InputLabel>{translate('T_PRACTICE_CATEGORY')}</InputLabel>
              <Select
                multiple={false}
                name="Practice.PracticeCategory.id"
                value={queryParams['Practice.PracticeCategory.id']}
                onChange={handleFilter}
              >
                <MenuItem value="">{translate('T_ALL')}</MenuItem>
                {[...state.practiceCategories].map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <TextField
              id="date-1"
              label="Od"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              name="solvedAt[after]"
              onChange={handleFilter}
              value={queryParams['solvedAt[after]']}
            />
            <TextField
              id="date-2"
              label="Do"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              name="solvedAt[before]"
              onChange={handleFilter}
              value={queryParams['solvedAt[before]']}
            />
          </div>
          {active === 1 ? (
            <TableList
              data={res.state.data ? (res.state.data.content ? res.state.data.content : []) : []}
              isLoading={res.state.isLoading}
              error={res.state.error}
              mainData={mainData()}
              dataLength={res.state.data?.totalItems}
              pagination={true}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
            />
          ) : (
            <Graph
              isLoading={res.state.isLoading}
              content={res.state.data ? (res.state.data.content ? res.state.data.content : []) : []}
            />
          )}
        </div>
      </div>
    </>
  )
}
