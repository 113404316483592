import React, { useState, useEffect } from 'react'
import { Link, useLocation, Redirect } from 'react-router-dom'
import ico from 'assets/ico-2-2.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import VoiceRecorder from 'components/VoiceRecorder'
import { VoiceReader } from 'components/VoiceReader'
import ErrorBoundary from 'components/ErrorBoundary'
import {
  selectGameContent,
  restart,
  toggleAnswer,
  fetchGameContent,
  selectTotalItems,
  next,
  selectCurrentStage,
  start,
  finish,
} from '_reducers/newGameSlice'

import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'

export const GameSpeaking = ({ match }) => {
  const {
    params: { id },
  } = match
  const location = useLocation()

  const fromSurvey = location.state?.fromSurvey
  const dispatch = useDispatch()
  const therapeutic = location.state?.therapeutic
  const testMode = location.state?.testMode

  const gameContent = useSelector(selectGameContent)
  const totalItems = useSelector(selectTotalItems)
  const currentStage = useSelector(selectCurrentStage)

  function getData() {
    return (dispatch) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/practiceStage/${id}/listAll`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const content = res.content?.[currentStage]
          const totalItems = res.totalItems
          dispatch(fetchGameContent({ content, totalItems }))
        })
    }
  }
  useEffect(() => {
    dispatch(getData())
  }, [currentStage])

  useEffect(() => {
    dispatch(start())
  }, [])

  const handleNext = () => {
    dispatch(next())
    setAttempt(0)
    setHit(0)
  }

  const checked_R = useSelector((state) => state.user.verifyByParent)

  const [hit, setHit] = useState(0)
  const [attempt, setAttempt] = useState(0)

  const onchange = (result) => {
    if (result) {
      setHit(1)
    }
    setAttempt(1)
  }

  const score = gameContent?.score

  useEffect(() => {
    const result = hit === 1 ? true : false
    if (attempt === 1) {
      dispatch(toggleAnswer({ result, id, score }))
      totalItems - 1 === currentStage && testMode && dispatch(finish())
    }
  }, [attempt])


  return (
    <>
      <div className="game-area">
        <Breadcrumbs color="green" ico={ico} title={gameContent?.Practice?.title} description={gameContent?.Practice?.Game?.title} grandChild={true} />
        <p className="game-description">{gameContent?.Practice?.Game?.text}</p>
        <div className="container">
          {gameContent?.ElementA1?.photoThumbs && (
            <img
              className="img-center"
              src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementA1?.photoThumbs?.['full']}`}
              alt={gameContent?.ElementA1?.title}
              width="100"
            />
          )}
          <VoiceReader text={gameContent?.text} diagnostic={fromSurvey} speaking={true} />
          <ErrorBoundary>
            <VoiceRecorder
              play="Powiedz, co to jest?"
              checked_R={checked_R}
              fromSurvey={fromSurvey}
              //diagnostic={fromSurvey}
              diagnostic={true}
              onchange={(e) => {
                onchange(e)
              }}
              dataAttempt={attempt}
              dataHit={hit}
            />
          </ErrorBoundary>
        </div>
        {attempt === 1 && (
          <>
            {!therapeutic && (
              <Link
                to={fromSurvey ? '/diagnostic-games/survey-games' : '/diagnostic-games/recognition'}
                className="btn survey__btn"
                onClick={() => dispatch(restart())}
              >
                Zakończ
              </Link>
            )}
            {therapeutic && totalItems - 1 > currentStage && (
              <button onClick={handleNext} className="btn survey__btn">
                Następny etap
              </button>
            )}
            {therapeutic && totalItems - 1 === currentStage && !testMode && (
              <Link to={'/therapeutic-games'} className="btn survey__btn" onClick={() => dispatch(restart())}>
                Zakończ ćwiczenie
              </Link>
            )}
            {therapeutic && totalItems - 1 === currentStage && testMode && <Redirect to={`/therapeutic-games/results/${id}`} />}
          </>
        )}
        {therapeutic && (
          <nav className="dots">
            <p className="game-text">Postęp</p>
            {[...Array(totalItems)].map((item, i) =>
              currentStage === i ? <Link to className="dots__item active" /> : <Link to className="dots__item" />
            )}
          </nav>
        )}
      </div>
    </>
  )
}
