import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { AccessDenied } from 'pages/AccessDenied'
import { ProgramList } from './ProgramList'
import { ProgramAdd } from './ProgramAdd'
import { routePath } from '_constants/routes.constants.js'
import { Addto as PracticesAddto } from './Practices/Addto'
import { Editto as PracticesEditto } from './Practices/Editto'

import { translate } from '_helpers/translate'

const routes = () => [
  {
    title: 'Program terapeutyczny',
    type: Route,
    render: () => <ProgramAdd />,
    path: routePath.program.add(''),
    exact: true,
  },
  {
    title: 'Program terapeutyczny',
    type: Route,
    render: () => <ProgramList />,
    path: routePath.program.list(''),
    exact: true,
  },
  {
    title: 'Program terapeutyczny',
    type: Redirect,
    from: '/',
    to: routePath.program.list(''),
    exact: true,
  },
  // GAME KITS

  {
    title: translate('T_GAME_PRACTICE_EDIT'),
    type: Route,
    component: PracticesEditto,
    path: routePath.practices.edit(':gameId', ':id', '/list/practices'),
    exact: true,
  },
  {
    title: translate('T_GAME_PRACTICE_ADD'),
    type: Route,
    component: PracticesAddto,
    path: routePath.practices.add(':gameId', '/list/practices'),
    exact: true,
  },
  {
    title: translate('T_GAME_PRACTICES'),
    type: Redirect,
    from: '/',
    to: routePath.practices.list(':gameId', '/list/practices'),
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Program = (props) => {
  const { match } = props
  const isPremium = useSelector((state) => state.user.profile?.isPremium)
  if (isPremium) {
    return <Page routes={generateRoutes(prefixPaths(match.url, routes(), ['path', 'from', 'to']))} />
  } else {
    return <AccessDenied />
  }
}
