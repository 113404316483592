import { Route } from 'react-router-dom'
import { Home, NotFound, AccessDenied, AccessDeniedGames } from 'pages'
import { Survey } from './pages'
import { DiagnosticGames } from './pages'
import { TherapeuticGames } from './pages'
import { Institutions } from './pages'
import { Settings } from './pages'
import { Children } from './pages'
import { Results } from './pages'
import { About } from './pages'
import { Program } from './pages'
import { MediaLibrary } from './pages'
import { Profile } from './pages'
import { PrivateRoute } from 'components/PrivateRoute'
import { AnonymousRoute } from 'components/AnonymousRoute'
import { routePath } from '_constants/routes.constants'

const routes = () => {
  return [
    {
      type: AnonymousRoute,
      component: Home,
      path: '/',
      exact: true,
    },
    {
      type: AnonymousRoute,
      component: Home,
      path: '/security',
    },
    {
      type: PrivateRoute,
      component: Profile,
      path: '/profile',
    },
    {
      type: AnonymousRoute,
      component: AccessDenied,
      path: '/access-denied',
    },
    {
      type: AnonymousRoute,
      component: AccessDeniedGames,
      path: '/access-denied-games',
    },
    {
      type: AnonymousRoute,
      component: Survey,
      path: routePath.survey.main,
    },
    {
      type: AnonymousRoute,
      component: DiagnosticGames,
      path: routePath.dgames.main,
    },
    {
      type: PrivateRoute,
      component: TherapeuticGames,
      path: routePath.tgames.main,
    },
    {
      type: AnonymousRoute,
      component: Institutions,
      path: routePath.institutions.main,
    },
    {
      type: AnonymousRoute,
      component: Settings,
      path: routePath.settings.main,
    },
    {
      type: PrivateRoute,
      component: Children,
      path: routePath.children.main,
    },
    {
      type: PrivateRoute,
      component: Results,
      path: routePath.results.main,
    },
    {
      type: AnonymousRoute,
      component: About,
      path: routePath.about.main,
    },
    {
      type: PrivateRoute,
      component: Program,
      path: routePath.program.main,
    },
    {
      type: PrivateRoute,
      component: MediaLibrary,
      path: routePath.medialibrary.main,
    },
    {
      type: Route,
      component: NotFound,
      path: '*',
    },
  ]
}

export default routes
