import React from 'react'
import * as faceApi from 'face-api.js'
import { notification } from '_helpers/notification'
import calm from 'assets/game/calm.jpg'
import sad from 'assets/game/sad.jpg'
import angry from 'assets/game/angry.jpg'
import happy from 'assets/game/happy.jpg'
import fearful from 'assets/game/fearful.jpg'
import disgusted from 'assets/game/disgusted.jpg'
import surprised from 'assets/game/surprised.jpg'
//import { Link } from 'react-router-dom'

const expressionMap = {
  neutral: calm,
  happy: happy,
  sad: sad,
  angry: angry,
  fearful: fearful,
  disgusted: disgusted,
  surprised: surprised,
}

const expressionTranslations = {
  neutral: 'spokój',
  happy: 'szczęście',
  sad: 'smutek',
  angry: 'złość',
  fearful: 'strach',
  disgusted: 'oburzenie',
  surprised: 'zaskoczenie',
}

class FaceRecognition extends React.Component {
  constructor(props) {
    super(props)
    this.video = React.createRef()
    this.canvas = React.createRef()
    this.pageBottom = React.createRef()
    this.state = {
      expressions: [],
      error: null,
      playing: false,
      loading_m1: true,
      loading_m2: true,
      obj: {},
      recognized: false,
    }
  }

  componentDidMount() {
    this.run()
  }

  log = (...args) => {
    console.log(...args)
  }
  componentWillUnmount() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer)
    }
  }

  updateAndNotify = () => {
    if (this.updateTimer) return
    this.updateTimer = setTimeout(() => {
      const expressions =
        this.state.obj &&
        Object.keys(this.state.obj).reduce((acc, curr) => {
          this.state.obj[curr] > 0.01 && acc.push([expressionMap[curr], this.state.obj[curr], curr])
          return acc
        }, [])

      const emotion = Object.keys(this.state.obj).reduce((acc, curr) => {
        curr === this.props.emotion && this.state.obj[curr] > 0.9 && acc.push([this.state.obj[curr]])
        return acc
      }, [])

      if (emotion.length) {
        this.setState(() => ({ recognized: true }))
        this.video.current.pause()
        this.props.onchange()
      }

      if (this.props.ended) {
        this.video.current.pause()
        this.props.onchange()
      }

      this.setState(() => ({ expressions }))
      this.updateTimer = null
    }, 1000)
  }

  componentDidUpdate(prevProps) {
    this.updateAndNotify()
    if (prevProps.clear !== this.props.clear) {
      this.stream()
    }
  }

  run = async () => {
    try {
      await faceApi.nets.tinyFaceDetector.loadFromUri('/models/')
      await faceApi.nets.faceLandmark68Net.loadFromUri('/models')
      await faceApi.nets.faceExpressionNet.loadFromUri('/models')
      this.mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: { facingMode: 'user' },
      })

      this.video.current.srcObject = this.mediaStream
      this.setState({ loading_m1: false })
    } catch (e) {
      this.log(e.name, e.message, e.stack)
      this.setState({ error: e })
      this.state.error?.message === 'Requested device not found' &&
        notification('error', 'Nie znaleziono kamery.', null, 8000)
    }
  }

  onPlay = async () => {
    try {
      if (this.video.current.paused || this.video.current.ended || !faceApi.nets.tinyFaceDetector.params) {
        setTimeout(() => this.onPlay())
        return
      }

      const options = new faceApi.TinyFaceDetectorOptions({
        inputSize: 224,
        scoreThreshold: 0.5,
      })

      const result = await faceApi
        .detectSingleFace(this.video.current, options)
        .withFaceLandmarks()
        .withFaceExpressions()
      this.setState({ loading_m2: false })

      if (result) {
        this.setState(() => ({ obj: result?.expressions }))
        const canvas = this.canvas.current.getContext('2d')
        const dims = { width: this.video.current.clientWidth, height: this.video.current.clientHeight }
        //const _dims = faceApi.matchDimensions(canvas, this.video.current, true)
        const resizedResult = faceApi.resizeResults(result, dims)
        this.setState(() => ({ canvasWidth: dims.width, canvasHeight: dims.height }))
        resizedResult && canvas.clearRect(0, 0, dims.width, dims.height)
        //faceApi.draw.drawDetections(canvas, resizedResult)
        faceApi.draw.drawFaceLandmarks(canvas, resizedResult)
        //faceApi.draw.drawFaceExpressions(canvas, resizedResult)
      }

      setTimeout(() => this.onPlay())
    } catch (e) {
      this.log(e.name, e.message, e.stack)
    }
  }

  stream = () => {
    this.video.current.play()
    this.setState(() => ({ playing: true, recognized: false }))
    this.scrollToBottom()
    this.props.onstart()
  }
  scrollToBottom = () => {
    this.pageBottom.current.scrollIntoView({ behavior: 'smooth' })
  }
  render() {
    return (
      <>
        <div className="video">
          <video ref={this.video} muted playsInline onPlay={this.onPlay} />
          <canvas ref={this.canvas} width={this.state.canvasWidth} height={this.state.canvasHeight} />
          {this.state.error?.message === 'Requested device not found' ? (
            <button className="video__btn btn btn--red" disabled>
              Podłącz kamere
            </button>
          ) : (
            !this.state.playing && (
              <button className="video__btn btn btn--green" onClick={this.stream}>
                Włącz kamere
              </button>
            )
          )}
        </div>
        <div className="expressions" ref={this.pageBottom}>
          {this.state.recognized ? (
            <>
              <p className="expressions__item expressions__item expressions__item--info">
                Rozpoznano emocję {expressionTranslations[this.props.emotion]}!
              </p>
              {/*<Link
                to={this.props.fromSurvey ? '/diagnostic-games/survey-games' : '/diagnostic-games/imitation'}
                className="btn btn survey__btn expressions__item expressions__item--info"
              >
                Zakończ
              </Link>*/}
            </>
          ) : this.props.ended ? (
            <>
              <p className="expressions__item  expressions__item--info">Koniec czasu</p>
              {/*<Link
                to={this.props.fromSurvey ? '/diagnostic-games/survey-games' : '/diagnostic-games/imitation'}
                className="btn btn survey__btn expressions__item expressions__item--info"
              >
                Zakończ
              </Link>*/}
            </>
          ) : (
            this.state.expressions?.length > 0 && (
              <p className="expressions__item  expressions__item--info">
                <img src={expressionMap[this.props.emotion]} width="70" alt="" />
                Naśladuj
              </p>
            )
          )}
          {this.state.expressions?.length ? (
            this.state.expressions
              .sort((a, b) => b[1] - a[1])
              .filter((_, i) => i < 3)
              .map(([e, w]) => (
                <p className="expressions__item" key={e + w}>
                  <img src={e} width="55" alt="" />
                  {Math.round(w * 100)}%
                </p>
              ))
          ) : this.state.playing ? (
            <>
              {this.state.loading_m2 && (
                <>
                  <p className="expressions__item expressions__item--info">Analiza obrazu</p>
                  <div className={`progress progress--m2 ${!this.state.loading_m2 ? 'hidden' : ''}`}>
                    <div className="indeterminate"></div>
                  </div>
                </>
              )}
            </>
          ) : (
            this.state.loading_m1 && (
              <>
                <p className="expressions__item expressions__item--info">Ładowanie modeli...</p>
                <div className={`progress progress--m1 ${!this.state.loading_m1 ? 'hidden' : ''}`}>
                  <div className="indeterminate"></div>
                </div>
              </>
            )
          )}
        </div>
      </>
    )
  }
}

export default FaceRecognition
