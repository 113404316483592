import React, { useState, useEffect } from 'react'
import ico from 'assets/ico-5.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { useDispatch } from 'react-redux'
import { soundActions } from '_actions'
import { authHeader } from '_helpers/authHeader'
import { useSelector } from 'react-redux'
import useSound from 'use-sound'
import sound2 from 'assets/sound/fanfare.mp3'
import sound1 from 'assets/sound/rising-pops.mp3'
import sound3 from 'assets/sound/disable-sound.mp3'
import sound4 from 'assets/sound/dun-dun-dun.mp3'
import sound5 from 'assets/sound/calming-sea-sounds.mp3'
import sound6 from 'assets/sound/forest-birds-singing-in-windy-morning.mp3'

export const SettingsIndex = () => {
  const dispatch = useDispatch()

  const setVoice = (e) => {
    const value = e.target.value
    setGender((prevState) => ({ ...prevState, voice: value }))

    dispatch(soundActions.setVoice(value))
    localStorage.setItem('voice', value)
  }

  const handleChange = (e) => {
    const value = e.target.value
    const name = e.target.name
    setSoundEffect((prevState) => ({ ...prevState, [name]: value }))
    if (name === 'success') {
      dispatch(soundActions.setSuccessSound(value))
      localStorage.setItem('successSound', value)
    }
    if (name === 'failure') {
      dispatch(soundActions.setFailureSound(value))
      localStorage.setItem('failureSound', value)
    }
    if (name === 'background') {
      dispatch(soundActions.setBackgroundSound(value))
      localStorage.setItem('backgroundSound', value)
    }
  }

  const gender_R = useSelector((state) => state.sound.voice)
  const volume_R = useSelector((state) => state.sound.volume)
  const playbackRate_R = useSelector((state) => state.sound.playbackRate)
  const successSound_R = useSelector((state) => state.sound.successSound)
  const failureSound_R = useSelector((state) => state.sound.failureSound)
  const [gender, setGender] = useState({ voice: gender_R })
  const [sound, setSound] = useState({ value: '' })
  const [soundEffect, setSoundEffect] = useState({ success: successSound_R, failure: failureSound_R })
  const [clicked, setClicked] = useState(false)
  const [selected, setSelected] = useState(false)
  const getSound = (voice) => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/voice/${voice}/test dźwięku`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw res.json()
      })
      .then((res) => {
        setSound((prevState) => ({ ...prevState, value: `data:audio/mpeg;base64,${res?.content?.[0]?.audio}` }))
      })
  }

  const playVoice = () => {
    setClicked(true)
  }

  const playSound = (e) => {
    const value = e.target.children[0].value
    const name = e.target.children[0].name
    e.preventDefault()
    setSoundEffect((prevState) => ({ ...prevState, [name]: value }))
    setSelected({ ...selected, status: true, name: name })
  }

  const useSlider = (min, max, step, defaultState, id) => {
    const [state, setSlide] = useState(defaultState)
    const handleChange = (e) => {
      setSlide(e.target.value)
      if (id === 'volume') {
        dispatch(soundActions.setVolume(e.target.value))
        localStorage.setItem('volume', e.target.value)
      } else {
        dispatch(soundActions.setPlaybackRate(e.target.value))
        localStorage.setItem('playbackRate', e.target.value)
      }
    }
    const props = {
      type: 'range',
      id,
      min,
      max,
      step: step,
      value: state,
      style: {
        background:
          'linear-gradient(to right, #8f88d9, #7b6ded ' +
          ((state - min) / (max - min)) * 100 +
          '%, #d3edff ' +
          ((state - min) / (max - min)) * 100 +
          '%, #dee1e2 100%)',
      },
      onChange: handleChange,
    }
    return props
  }
  const playbackRate = useSlider(0.7, 1.5, 0.1, playbackRate_R, 'playbackRate')
  const volume = useSlider(0.1, 1, 0.1, volume_R, 'volume')

  const [play] = useSound(sound.value, {
    volume: volume.value,
  })

  const [playSuccess] = useSound(soundEffect.success)

  const [playFailure] = useSound(soundEffect.failure)

  const [playBackground, { stop }] = useSound(soundEffect.background)

  useEffect(() => {
    getSound(gender.voice)
  }, [])

  useEffect(() => {
    getSound(gender.voice)
  }, [gender])

  useEffect(() => {
    setClicked(false)
    play({ playbackRate: playbackRate.value })
  }, [clicked])

  useEffect(() => {
    setSelected({ ...selected, status: false })
    if (selected.name === 'success') {
      playSuccess()
    }
    if (selected.name === 'failure') {
      playFailure()
    }
    if (selected.name === 'background') {
      playBackground()
    }
  }, [selected.status])

  useEffect(() => {
    return () => {
      stop()
    }
  }, [stop])

  return (
    <>
      <Breadcrumbs color="red" ico={ico} title="Ustawienia dźwięków" backHome={true} />
      <div action="" className="form">
        <div className="form__item">
          <form className="form__item" onSubmit={playSound}>
            <select
              className="custom-select form__select"
              name="success"
              onChange={handleChange}
              value={soundEffect.success}
            >
              <option value={sound1}>Sukces 1</option>
              <option value={sound2}>Sukces 2</option>
            </select>
            <label className="form__label">Zestaw dźwięków na sukces</label>
            <button className="btn btn--light form__btn">Test dźwięku odgłosów</button>
          </form>
          <form className="form__item" onSubmit={playSound}>
            <select
              className="custom-select form__select"
              name="failure"
              onChange={handleChange}
              value={soundEffect.failure}
            >
              <option value={sound3}>Porażka 1</option>
              <option value={sound4}>Porażka 2</option>
            </select>
            <label className="form__label">Zestaw dźwięków na porażkę</label>
            <button className="btn btn--light form__btn">Test dźwięku odgłosów</button>
          </form>
          <form className="form__item" onSubmit={playSound}>
            <select
              className="custom-select form__select"
              name="background"
              onChange={handleChange}
              value={soundEffect.background}
            >
              <option value={sound5}>Szum morza</option>
              <option value={sound6}>Szum lasu</option>
            </select>
            <label className="form__label">Zestaw dźwięków w tyle</label>
            <button className="btn btn--light form__btn">Test dźwięku odgłosów</button>
          </form>
          <select className="custom-select form__select" onChange={setVoice} value={gender.voice}>
            <option value="female">Kobieta</option>]<option value="male">Mężczyzna</option>
          </select>
          <label className="form__label">Lektor</label>
        </div>
        <div className="form__item">
          <div>
            <input {...playbackRate} />
          </div>
          <label className="form__label">Barwa głosu</label>
        </div>
        <div className="form__item">
          <div>
            <input {...volume} />
          </div>
          <label className="form__label">Głośność</label>
        </div>
        <button className="btn form__btn" onClick={playVoice}>
          Test dźwięku lektora
        </button>
      </div>
    </>
  )
}
