import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectDataG, selectStartedAt, selectGamesPlayed } from '_reducers/newGameSlice'
import { selectData, selectNoGames } from '_reducers/quizSlice'
import { start } from '_reducers/newGameSlice'
import { formatRoute } from 'react-router-named-routes'
import classNames from 'classnames'

export const GamesSurveyList = ({ routes }) => {
  const dispatch = useDispatch()
  const dataQ = useSelector(selectData)
  const data = useSelector(selectDataG)
  const startedAt = useSelector(selectStartedAt)
  const gamesPlayed = useSelector(selectGamesPlayed)
  const started = useSelector(selectStartedAt)
  const noGames = useSelector(selectNoGames)

  function getKeyByValue(object, val, type) {
    for (const [key, value] of Object.entries(object)) {
      if (key === val) {
        return value[type]
      }
    }
  }

  const handleClick = () => {
    dispatch(start())
    dispatch({
      type: 'newGame/fetchData',
      payload: dataQ.InquiryPractices,
    })
  }

  return (
    <>
      <ol>
        <p>
          {!noGames ? 'Zagraj w kolejne gry, by poznać wynik diagnozy.' : 'Brak gier dla tego przedziału wiekowego'}
        </p>
        {!startedAt && !noGames && (
          <button className="btn btn--game" onClick={handleClick}>
            Zacznij grać
          </button>
        )}
      </ol>
      <ol>
        {data?.length && started
          ? data?.map((i, j) => (
              <li key={j} className="list-item">
                <span className="btn-start">
                  {/*getKeyByValue(routes, i.Practice.Game.idname, 'name')*/}
                  {i.Practice.title}
                  <Link
                    className={classNames('btn btn--green', i.active === false && 'disabled')}
                    to={{
                      pathname: formatRoute(getKeyByValue(routes, i.Practice.Game.idname, 'url'), {
                        id: i.Practice.id,
                      }),
                      state: { fromSurvey: true },
                    }}
                  >
                    {i.active === false ? 'Ukończono' : 'Rozpocznij'}
                  </Link>
                </span>
              </li>
            ))
          : startedAt && <p>Brak gier dla tego przedziału wiekowego</p>}
        {data?.length > 0 && data?.length === gamesPlayed && (
          <Link to="/survey/results" className="btn survey__btn">
            Zobacz wyniki
          </Link>
        )}
        {noGames && (
          <Link to="/survey/results" className="btn survey__btn">
            Zobacz wyniki
          </Link>
        )}
      </ol>
    </>
  )
}
