import { authConstants } from '_constants'
import { authService } from '_services'
import { notification } from '_helpers/notification'
import { tokenRefresh } from '_helpers/tokenRefresh'
import { processError } from '_helpers/processError'
import { translate } from '_helpers/translate'

const login = (email, password) => (dispatch, getState) => {
  dispatch({ type: authConstants.LOGIN_REQUEST, payload: { email } })

  return authService.login(email, password).then(
    (token) => {
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: { token },
      })
      tokenRefresh.start(dispatch, getState, logout, refresh)
      notification('success', 'Zalogowano', 'Komunikat')
    },
    (error) => {
      dispatch({ type: authConstants.LOGIN_FAILURE })
      notification('error', translate(error.toString()), translate('Error'))
    }
  )
}

const refresh = () => (dispatch) => {
  dispatch({ type: authConstants.REFRESH_REQUEST })

  return authService.refresh().then(
    (token) => {
      dispatch({
        type: authConstants.REFRESH_SUCCESS,
        payload: { token },
      })
    },
    (error) => {
      dispatch({
        type: authConstants.REFRESH_FAILURE,
        payload: { error: processError(error).response },
      })
      tokenRefresh.clear()
    }
  )
}

const restore = (token) => (dispatch, getState) => {
  dispatch({
    type: authConstants.LOGIN_RESTORE,
    payload: { token },
  })
  tokenRefresh.start(dispatch, getState, logout, refresh)
}

const logout = () => (dispatch) => {
  dispatch({ type: authConstants.LOGOUT })
  tokenRefresh.clear()
  localStorage.clear()
  notification('info', 'Wylogowano', 'Komunikat')
}

export const authActions = {
  login,
  refresh,
  restore,
  logout,
}
