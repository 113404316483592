import React from 'react'
import ico from 'assets/ico-3.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Maintenance } from 'components/Maintenance'
export const AboutIndex = () => {
  return (
    <>
      <Breadcrumbs color="dark" ico={ico} title="Informacje o aplikacji" backHome={true} />
      <Maintenance />
    </>
  )
}
