import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { ResultsIndex } from './ResultsIndex'
import { SurveyResults } from './SurveyResults'
import { GameResults } from './GameResults'
import { TherapyResults } from './TherapyResults'
import { routePath } from '_constants/routes.constants.js'

const routes = () => [
  {
    title: 'Wyniki i historia',
    type: Route,
    render: () => <ResultsIndex />,
    path: routePath.results.index(''),
    exact: true,
  },
  {
    title: 'Wyniki i historia',
    type: Route,
    render: () => <SurveyResults />,
    path: routePath.results.survey(''),
    exact: true,
  },
  {
    title: 'Wyniki i historia',
    type: Route,
    render: () => <GameResults />,
    path: routePath.results.game(''),
    exact: true,
  },
  {
    title: 'Wyniki i historia',
    type: Route,
    render: () => <TherapyResults />,
    path: routePath.results.therapy(''),
    exact: true,
  },
  {
    title: 'Wyniki i historia',
    type: Redirect,
    from: '/',
    to: routePath.results.index(''),
    exact: true,
  },
  {
    title: 'Wyniki i historia',
    type: Redirect,
    from: '/*/',
    to: routePath.results.index(''),
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Results = (props) => {
  const { match } = props

  return <Page routes={generateRoutes(prefixPaths(match.url, routes(), ['path', 'from', 'to']))} />
}
