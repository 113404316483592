import React from 'react'
import { useSelector } from 'react-redux'
import { FormFactory } from 'components/factory/FormFactory'
import * as schema from '_schema/user'
import { getDefinitionProperties } from '_helpers/getDefinitionProperties'

export const ChangePasswordForm = ({ resource }) => {
  const definitions = useSelector((state) => state.schema?.definitions)
  const properties = getDefinitionProperties(
    definitions[schema.changePassword.definition],
    schema.changePassword.properties
  )

  return (
    <FormFactory
      title="Zmiana hasła"
      url={schema.changePassword.set}
      method="PUT"
      properties={properties}
      resource={resource}
    />
  )
}
