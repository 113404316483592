import React from 'react'
import maintenance from 'assets/maintenance.svg'

export const Maintenance = () => {
  return (
    <>
      <div className="container">
        <h2 className="page-heading">Strona w budowie</h2>
        <img src={maintenance} alt="" />
        <div className="copy">
          <a href="https://iconscout.com/illustrations/website-development" target="_blank" rel="noreferrer">
            Website development Illustration
          </a>
          by
          <a href="https://iconscout.com/contributors/delesign" target="_blank" rel="noreferrer">
            Delesign Graphics
          </a>
        </div>
      </div>
    </>
  )
}
