import { authHeader } from './authHeader'
import { handleResponse as defaultHandleResponse } from './handleResponse'

export const fetchData = (
  url,
  method = 'GET',
  request = {},
  handleResponse = null,
  absoluteUrl = false,
  headers = {},
  ldJson = true
) => {
  const isFormData = headers['Content-Type'] === 'multipart/form-data'
  delete headers['Content-Type']

  return fetch(absoluteUrl ? url : `${process.env.REACT_APP_API_ENTRYPOINT}${url}`, {
    method,
    headers: {
      accept: ldJson ? 'application/ld+json' : 'application/json',
      ...(isFormData
        ? {}
        : {
            'Content-Type':
              method === 'PATCH' ? 'application/merge-patch+json' : ldJson ? 'application/ld+json' : 'application/json',
          }),
      ...headers,
      ...authHeader(),
    },
    ...request,
  }).then(handleResponse || defaultHandleResponse)
}
