import React from 'react'
import ico from 'assets/ico-login-1.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'
import { Link } from 'react-router-dom'

export const ChildrenIndex = () => {
  return (
    <>
      <Breadcrumbs color="dark-purple" ico={ico} title="Dzieci" backHome={true} />

      {hasRole(authConstants.ROLE_THERAPIST) && (
        <div className="results">
          <div className="buttons">
            <Link className="buttons__item btn btn--game" to="/children/list">
              Utwórz terapie
            </Link>
            <Link className="buttons__item btn btn--green btn--game" to="/children/therapy">
              Lista terapi
            </Link>
          </div>
        </div>
      )}
    </>
  )
}
