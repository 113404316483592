import React, { useState } from 'react'
import icoP from 'assets/ico-login-2.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Link } from 'react-router-dom'
import { routePath } from '_constants/routes.constants.js'
import { useFetch } from '_helpers/useFetch'
import { authHeader } from '_helpers/authHeader'
import { useSelector, useDispatch } from 'react-redux'
import ico_0 from 'assets/ico-2-1.svg'
import ico_1 from 'assets/ico-2-2.svg'
import ico_2 from 'assets/ico-2-3.svg'
import ico_3 from 'assets/ico-2-4.svg'
import classNames from 'classnames'
import { AutoCompleteType } from 'components/form/AutoCompleteType'

export const ProgramAdd = () => {
  const iconsMap = {
    0: ico_0,
    1: ico_0,
    2: ico_0,
    3: ico_1,
    4: ico_1,
    5: ico_2,
    6: ico_3,
    7: ico_3,
    8: ico_3,
    9: ico_1,
  }

  const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/games/list`

  const endPointTemplate = `${process.env.REACT_APP_API_ENTRYPOINT}/practice/therapistAvailableTemplatesList`

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }
  const auth = useSelector((state) => state.auth)

  const listqueryParams =
    localStorage.listGameQueryParams === undefined
      ? {
          search: '',
          page: 1,
          perPage: 12,
          'order[name]': 'ASC',
        }
      : JSON.parse(localStorage.listGameQueryParams)

  const [queryParams] = useState(listqueryParams)

  const args = [auth.data.token, dispatch]

  const res = useFetch(endPoint, options, args, queryParams)

  const data = res.state.data ? res.state.data?.content : []

  const resTemplate = useFetch(endPointTemplate, options, args, queryParams)

  const dataTemplate = resTemplate.state.data ? resTemplate.state.data?.content : []

  const isLoading = res.state.isLoading

  const [checkedValue, setIsChecked] = useState(null)

  const [state, setState] = useState({
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
  })

  const fields = {
    dataTemplate: {
      options: dataTemplate,
      label: 'Szablony',
      hint: 'Wybierz z szablonu',
      name: 'Template',
    },
  }

  const setValue = (name, value, id) => {
    const isInvalid = value instanceof Error
    setState((state) => ({
      ...state,
      values: { ...state.values, [name]: !isInvalid && value, id: id },
      errors: { ...state.errors, [name]: isInvalid },
      invalid: state.invalid + (!!state.errors[name] === !isInvalid && -1 + isInvalid * 2),
    }))
  }

  const setValidator = (name, validator, values) => {
    setState((state) => ({
      ...state,
      validators: {
        ...state.validators,
        [name]: { validator, values: values || [name] },
      },
    }))
  }

  const setErrorMessageSetter = (name, errorMessageSetter) => {
    setState((state) => ({
      ...state,
      errorMessageSetters: {
        ...state.errorMessageSetters,
        [name]: errorMessageSetter,
      },
    }))
  }

  return (
    <>
      <Breadcrumbs color="grass-green" ico={icoP} title="Program terapeutyczny - lista" backHome={true} />
      <div className="container-full">
        <h2 className="page-heading">Dodaj ćwiczenie z szablonu (będziesz mógł je edytować)</h2>
        <AutoCompleteType
          name={fields.dataTemplate.name}
          options={fields.dataTemplate?.options}
          addEmpty={fields.dataTemplate.addEmpty}
          validators={fields.dataTemplate.validate || []}
          label={fields.dataTemplate.label}
          hint={fields.dataTemplate.hint}
          setValue={setValue}
          setValidator={setValidator}
          setErrorMessageSetter={setErrorMessageSetter}
        />
        <h2 className="page-heading">
          Lub stwórz nowe ćwiczenie z wybranego rodzaju gry (będziesz mógł je zapisać jako szablon):{' '}
        </h2>

        <div className="boxes-grid boxes-grid--fs">
          {!isLoading &&
            data &&
            data.map((row, index) => {
              return (
                <div className="boxes-grid__item box" key={`${index}_link`}>
                  <label
                    htmlFor={`${index}_checkbox`}
                    className="box__visuals--square-sm box__visuals box box__visuals--green"
                  >
                    <img className="box__ico" src={iconsMap[index]} alt="" />

                    <span className="box__checkbox">
                      <input
                        id={`${index}_checkbox`}
                        type="radio"
                        value={row.id}
                        checked={checkedValue === row.id}
                        onChange={() => setIsChecked(row.id)}
                        required
                      />
                    </span>
                  </label>
                  <h2 className="box__title">{row.title}</h2>
                </div>
              )
            })}
        </div>
      </div>
      <Link
        className={classNames(
          'btn survey__btn btn--green',
          checkedValue === null && !state.values?.Template && 'disabled'
        )}
        to={
          state.values?.Template
            ? `list/practices/${state.values?.id}/list/edit/${state.values?.Template}`
            : routePath.practices.add(checkedValue)
        }
      >
        Dalej
      </Link>
    </>
  )
}
