import React, { useState, useEffect } from 'react'
import ico from 'assets/ico-1-orange.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Link } from 'react-router-dom'
import arrowRight from 'assets/arrow-right.svg'
import { useSelector } from 'react-redux'
import { authHeader } from '_helpers/authHeader'

export const GameResults = () => {
  const [emotions, setEmotions] = useState([])
  const parentId = useSelector((state) => state.user.profile?.id)

  const getEmotions = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/parent_datas/?Parent.id=${parentId}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setEmotions(res.content)
      })
  }

  useEffect(() => {
    parentId && getEmotions()
  }, [parentId])

  const [activeIndex, setActiveIndex] = useState(null)

  return (
    <>
      <Breadcrumbs color="orange" ico={ico} title="Wyniki i historia" />

      <div className="games-list">
        {emotions?.length !== 0 ? (
          emotions?.map((item, index) => (
            <>
              <div className="games-list__item gl-item gl-item--results" key={index}>
                <h3 className="gl-item__title">
                  {item.gameTitle} -{' '}
                  {new Date(item.createdAt).toLocaleDateString('pl-PL', { hour: '2-digit', minute: '2-digit' })}
                </h3>

                <div
                  className="gl-item__expand"
                  onClick={(event) => setActiveIndex(activeIndex === index ? null : index)}
                >
                  Zobacz wyniki
                  <img className="gl-item__arrow" src={arrowRight} alt="" />
                </div>
              </div>

              {activeIndex === index ? (
                <div className="games-list__item-content open">
                  <div className="gl-item gl-item--results">
                    <ol>
                      <li className="list-item">
                        <h3 className="gl-item__title">Wybrana emocja: </h3>
                        <p className="gl-item__description">{item.emotion}</p>
                        <p className="gl-item__description">
                          Dziecko: {item.Child.firstName} {item.Child.lastName}
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              ) : null}
            </>
          ))
        ) : (
          <div className="games-list__item gl-item gl-item--results">
            <h3 className="gl-item__title">Gra nie została rozergrana</h3>
            <Link to="/diagnostic-games/identification" className="gl-item__expand">
              Zagraj w grę
            </Link>
          </div>
        )}
      </div>
    </>
  )
}
