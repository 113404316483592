import React, { useState, useEffect } from 'react'
import ico from 'assets/ico-login-2.svg'
import edit from 'assets/edit.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Link } from 'react-router-dom'
import { routePath } from '_constants/routes.constants.js'
import { useSelector, useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { makeStyles } from '@material-ui/core/styles'
import { DragIndicator } from '@material-ui/icons'
import { fetchData } from '_helpers/fetchData'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { dataToObj } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import { sortableContainer, sortableElement, sortableHandle, arrayMove } from 'react-sortable-hoc'

export const ProgramList = () => {
  const therapyId = useSelector((state) => state.user.profile?.SelectedChild?.therapyId)

  const endPoint = therapyId ? `/practice/listAllByTherapy/${therapyId}?order[ord]=ASC` : null

  const dispatch = useDispatch()

  const PracticeSortableList = sortableContainer(({ children }) => <ul className="program-list">{children}</ul>)

  const DragHandle = sortableHandle(() => {
    return <DragIndicator />
  })

  const PracticeSortableElement = sortableElement(({ value, element }) => {
    return (
      <li className="list-item list-item--program list-item--grass-green">
        <DragHandle />
        {element.title}
        <Link className="profile__edit" to={`list/practices/${element.gameId}/list/edit/${element.id}`}>
          edycja <img src={edit} alt="" />
        </Link>
      </li>
    )
  })

  const useStyles = makeStyles({
    dragHelper: {
      border: '1px solid silver',
      borderRadius: '7px',
      backgroundColor: '#D2DAFF',
    },
  })

  const classes = useStyles()
  const [saveOrd, setSaveOrd] = useState(0)

  const [state, setState] = useState({
    items: [],
    movedItemId: null,
    isProcessing: false,
    errorMessageSetters: {},
  })

  const selectedChild = useSelector((state) => state.user.profile?.SelectedChild)

  useEffect(() => {
    loadElements()
  }, [selectedChild])

  useEffect(() => {
    if (saveOrd > 0) {
      handleSaveOrd()
    }
  }, [saveOrd])

  const loadElements = () => {
    fetchData(endPoint, 'GET', {}, (resp) => resp.json(), false).then((response) => {
      setState({ ...state, items: response.content })
    })
  }

  const performSort = ({ oldIndex, newIndex }) => {
    setState(({ items }) => ({
      movedItemId: state.items[oldIndex].id,
      items: arrayMove(items, oldIndex, newIndex),
    }))

    setSaveOrd(saveOrd + 1)
  }

  const handleSaveOrd = () => {
    var ordData = []
    // eslint-disable-next-line array-callback-return
    state.items.map((element, key) => {
      ordData.push({
        id: element.id,
        newOrd: 1 + key,
      })
    })

    const requestData = JSON.stringify(
      dataToObj({
        ordData: ordData,
      })
    )

    setState((state) => ({ ...state, isProcessing: true }))
    dispatch(requestActions.start(state.url))

    fetchDataHandleAuthError(
      `/practice/${state.movedItemId}`,
      'PUT',
      { body: requestData },
      (response) => {
        setState((state) => ({ ...state, isProcessing: false }))
        notification('success', translate('T_RECORD_UPDATED'), translate('T_SAVED'))
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        notification(
          'error',
          error.response.violations.length ? translate('T_INCORRECT_FORM') : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
  }

  const arrayLength = state.items.length > 5 ? 0 : 6 - state.items.length

  return (
    <>
      <Breadcrumbs color="grass-green" ico={ico} title="Program terapeutyczny" backHome={true} />
      <div className="container-full">
        <PracticeSortableList onSortEnd={performSort} useDragHandle helperClass={classes.dragHelper}>
          {state.items.map((element, key) => (
            <PracticeSortableElement key={`item-${key}`} index={key} value={key} element={element} />
          ))}
          {[...Array(arrayLength)].map((element, key) => (
            <li key={`item-${key}`} className="list-item list-item--program list-item--grass-green">
              <DragHandle />
              puste
              <Link className="profile__edit" to={routePath.program.add()}>
                dodaj <img src={edit} alt="" />
              </Link>
            </li>
          ))}

          <span className="vertical-item">Bieżące</span>
          <span
            className="vertical-item"
            style={{ gridRow: state.items.length > 5 ? `5/calc(${state.items.length} + 1)` : `5/7` }}
          >
            W kolejce
          </span>
        </PracticeSortableList>
      </div>
    </>
  )
}
