import React, { useState, useEffect } from 'react'
import { authHeader } from '_helpers/authHeader'
import useSound from 'use-sound'
import { useSelector } from 'react-redux'

export const VoiceReader = (props) => {
  const gender_R = useSelector((state) => state.sound.voice)
  const volume_R = useSelector((state) => state.sound.volume)
  const playbackRate_R = useSelector((state) => state.sound.playbackRate)
  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    setClicked(true)
  }

  const [sound, setSound] = useState()

  const textToRead = props.speaking
    ? props.diagnostic
      ? `Powiedz co widzisz na obrazku`
      : `${props.text}`
    : `${props.text}`

  const getSound = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/voice/${gender_R}/${textToRead}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw res.json()
      })
      .then((res) => {
        setSound(`data:audio/mpeg;base64,${res?.content?.[0]?.audio}`)
      })
  }

  useEffect(() => {
    props.text !== undefined && getSound()
  }, [props.text])

  const [play] = useSound(sound, {
    volume: volume_R,
  })

  useEffect(() => {
    setClicked(false)
    play({ playbackRate: playbackRate_R })
  }, [clicked])

  return (
    <button className="btn btn--read btn--light" onClick={handleClick}>
      {props.speaking ? (props.diagnostic ? 'Czytaj polecenie' : 'Czytaj podpowiedź') : props.text}
    </button>
  )
}
