import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { SurveyIndex } from './SurveyIndex'
import { TempResults } from './TempResults'
import { routePath } from '_constants/routes.constants.js'

const routes = () => [
  {
    title: 'Ankieta obszarów ryzyka',
    type: Route,
    render: () => <SurveyIndex />,
    path: routePath.survey.index(''),
    exact: true,
  },
  {
    title: 'Ankieta obszarów ryzyka',
    type: Route,
    render: () => <TempResults />,
    path: routePath.survey.tempresults(''),
    exact: true,
  },
  {
    title: 'Ankieta obszarów ryzyka',
    type: Redirect,
    from: '/',
    to: routePath.survey.index(''),
    exact: true,
  },
  {
    title: 'Ankieta obszarów ryzyka',
    type: Redirect,
    from: '/*/',
    to: routePath.survey.index(''),
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Survey = (props) => {
  const { match } = props

  return <Page routes={generateRoutes(prefixPaths(match.url, routes(), ['path', 'from', 'to']))} />
}
