import React from 'react'
import { useState, useEffect } from 'react'

const Timer = (props) => {
  const { initialSeconds = 0 } = props
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
        if (props.ended) {
          setSeconds(0)
          setMinutes(0)
          clearInterval(myInterval)
        }
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
          !props.ended && props.onend()
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  useEffect(() => {
    setMinutes(Math.floor(initialSeconds / 60))
    setSeconds(initialSeconds % 60)
  }, [props.initialSeconds])

  return minutes === 0 && seconds === 0 ? null : (
    <div className="timer">
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </div>
  )
}

export default Timer
