import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  root: {
    marginTop: '1.5em',
    marginBottom: '1.5em',
    display: 'block',
    width: '100%',
  },
  save: {
    marginTop: '1.5em',
  },
  select: {
    '& .MuiList-root': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    '& .MuiMenuItem-root': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  groupUl: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  option: {
    display: 'flex',
    flexDirection: 'column',
  },
  img: {
    width: '80px',
  },
  imgTemplate: {
    width: '80px',
    marginRight: '20px',
  },
  category: {
    fontWeight: 700,
    marginRight: '10px',
  },
}))
