import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

const useLocalCss = makeStyles(() => ({
  fieldsBox: {
    display: 'grid',
    gridTemplateColumns: '85px 1fr',
  },
  fieldsWrap: {
    '&:empty': {
      display: 'none',
    },
    '&:empty + .horizontal-item': {
      display: 'none',
    },
  },
  field: {
    width: '75px',
    height: '75px',
    backgroundColor: '#fff',
    textAlign: 'center',
    display: 'table-cell',
    verticalAlign: 'middle',
    padding: '5px',
  },
  img: {
    width: '55px',
    height: '55px',
    display: 'inline-block',
  },
}))

export const PracticeStagePreview = ({ gameIdname, fieldsNames, fieldsValues }) => {
  const css = useLocalCss()

  const availableElements = useSelector((state) => {
    return state.game.elementsById
  })

  const fieldPreview = (fieldName) => {
    const elementIri = fieldsValues['Element' + fieldName] ? fieldsValues['Element' + fieldName] : null
    const element = availableElements[elementIri] ? availableElements[elementIri] : null

    if (!fieldsNames.includes(fieldName)) {
      return null
    }

    if (!element) {
      return null
    }

    if (element && element.type === 'TYPE_PHOTO') {
      return (
        <React.Fragment key={'key_' + fieldName + '_preview'}>
          <div className={css.field}>
            <img
              className={css.img}
              src={process.env.REACT_APP_API_ENTRYPOINT + element.photoThumbs['128x128']}
              alt=""
            />
          </div>
        </React.Fragment>
      )
    }

    if (element && element.type === 'TYPE_AUDIO') {
      return (
        <React.Fragment key={'key_' + fieldName + '_preview'}>
          <div className={css.field}>audio</div>
        </React.Fragment>
      )
    }

    if (element && element.type === 'TYPE_VIDEO') {
      return (
        <React.Fragment key={'key_' + fieldName + '_preview'}>
          <div className={css.field}>video</div>
        </React.Fragment>
      )
    }
  }

  return (
    <>
      <div className={css.fieldsBox}>
        <div className={css.fieldsWrap}>{fieldPreview('A1')}</div>
        {gameIdname !== 'GAME_SORTING' && gameIdname !== 'GAME_MAPPING' && (
          <span className="horizontal-item">Obiekt główny:</span>
        )}
        {gameIdname === 'GAME_SORTING' && (
          <span className="horizontal-item">Obiekt w pojemniku nr1:</span>
        )}

        <div className={css.fieldsWrap}>
          {fieldPreview('B1')}

          {fieldPreview('B2')}

          {fieldPreview('B3')}

          {fieldPreview('B4')}
        </div>
        {gameIdname === 'GAME_IDENTIFICATION' && <span className="horizontal-item">Obiekty prawidłowe:</span>}
        {gameIdname === 'GAME_MAPPING' && <span className="horizontal-item">Obiekty we wzorze:</span>}
        {gameIdname === 'GAME_SORTING' && <span className="horizontal-item">Obiekty do pojemnika nr1:</span>}
        {gameIdname === 'GAME_UNDERSTANDING_SPEECH' && <span className="horizontal-item">Obiekty prawidłowe:</span>}
        {gameIdname === 'GAME_EMOTIONS_IDENTIFICATION' && <span className="horizontal-item">Obiekty prawidłowe:</span>}
        {gameIdname === 'GAME_VERBAL_COMMUNICATION' && <span className="horizontal-item">Obiekty prawidłowe:</span>}

        <div className={css.fieldsWrap}>
          {fieldPreview('C1')}

          {fieldPreview('C2')}

          {fieldPreview('C3')}

          {fieldPreview('C4')}
        </div>
        {gameIdname === 'GAME_IDENTIFICATION' && <span className="horizontal-item">Pula obiektów błędnych:</span>}
        {gameIdname === 'GAME_SORTING' && <span className="horizontal-item"> Obiekt w pojemniku nr2:</span>}
        {gameIdname === 'GAME_EMOTIONS_IDENTIFICATION' && (
          <span className="horizontal-item">Pula obiektów błędnych:</span>
        )}
        {gameIdname === 'GAME_VERBAL_COMMUNICATION' && <span className="horizontal-item">Pula obiektów błędnych:</span>}

        <div className={css.fieldsWrap}>
          {fieldPreview('D1')}

          {fieldPreview('D2')}

          {fieldPreview('D3')}

          {fieldPreview('D4')}
        </div>
        {gameIdname === 'GAME_MAPPING' && <span className="horizontal-item">Obiekty do wyboru:</span>}
        {gameIdname === 'GAME_SORTING' && <span className="horizontal-item">Obiekty do pojemnika nr2:</span>}
      </div>
    </>
  )
}
