import React from 'react'
import { useDispatch } from 'react-redux'
import { soundActions } from '_actions'
import { useSelector } from 'react-redux'
import { notification } from '_helpers/notification'
export const SoundToggle = () => {
  const dispatch = useDispatch()
  const volume_R = useSelector((state) => state.sound.volume)

  const handleChange = (e) => {
    const value = e.currentTarget.dataset.value

    if (value > 0) {
      dispatch(soundActions.setVolume(0))
      localStorage.setItem('volume', 0)
      notification('info', 'Dźwięk wyciszony', 'Komunikat')
    } else {
      dispatch(soundActions.setVolume(1))
      localStorage.setItem('volume', 1)
      notification('info', 'Dźwięk włączony', 'Komunikat')
    }
  }

  return (
    <svg
      style={{ marginLeft: '20px' }}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 18 18"
      fill="none"
      onClick={handleChange}
      data-value={volume_R}
    >
      <path d="M8.25 3.75L4.5 6.75H1.5V11.25H4.5L8.25 14.25V3.75Z" fill="#2f2f2f"></path>
      <path
        d="M14.3025 3.69751
            C15.7086 5.10397 16.4984 7.01128 16.4984 9.00001
            C16.4984 10.9887 15.7086 12.8961 14.3025 14.3025"
        style={{ opacity: volume_R > 0 ? 1 : 0, transitionDelay: volume_R > 0 ? '150ms' : '0ms' }}
        className="sound-toggle"
        stroke="#2f2f2f"
      ></path>
      <path
        d="M11.655 6.34501
            C12.358 7.04824 12.753 8.00189 12.753 8.99626
            C12.753 9.99063 12.358 10.9443 11.655 11.6475"
        style={{ opacity: volume_R > 0 ? 1 : 0, transitionDelay: volume_R > 0 ? '0ms' : '150ms' }}
        className="sound-toggle"
        stroke="#2f2f2f"
      ></path>
    </svg>
  )
}
