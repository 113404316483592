import React, { useState, useEffect } from 'react'
import { Select, FormHelperText, MenuItem } from '@material-ui/core'
import { validate } from '_helpers/validate'
import { useStyles } from 'components/form/FormStyles'

export const SelectType = ({
  name,
  value,
  validators,
  label,
  hint,
  disabled,
  setValue,
  setValidator,
  setErrorMessageSetter,
  fullWidth = true,
  options = [],
  addEmpty = false,
  multiple = false,
  custom = false,
  template = false,
}) => {
  const classes = useStyles()

  const [state, setState] = useState({
    value: value || '',
    validators: {},
    errors: {},
    error: { status: false, message: false },
  })

  const handleChange = (e) => {
    const value = e.target.value
    const id = parseInt(e.currentTarget.dataset.id)
    setState((state) => ({ ...state, value }))
    validator(value, id)
  }

  const validator = (value, id) => {
    const valid = validate(validators, value)
    setState((state) => ({
      ...state,
      error: { status: !valid.result, message: valid.message },
    }))
    setValue(name, !valid.result ? new Error(valid.message) : value, id)

    return valid.result
  }

  const setErrorMessage = (message) => {
    setState((state) => ({
      ...state,
      error: { status: true, message },
    }))
  }

  useEffect(() => {
    setValue(name, value)
    setValidator(name, validator)
    setErrorMessageSetter(name, setErrorMessage)
  }, [])

  return (
    <div>
      <FormHelperText component="legend">{label + (validators.includes('required') ? ' *' : '')}</FormHelperText>
      <Select
        name={name}
        onChange={handleChange}
        multiple={multiple}
        value={state.value}
        error={state.error.status}
        disabled={disabled}
        fullWidth={fullWidth}
        MenuProps={custom ? { classes: { paper: classes.select } } : {}}
      >
        {addEmpty && <MenuItem value={null}>{hint}</MenuItem>}
        {[...options].map((item) => {
          return (
            <MenuItem value={item.id} key={item.id} data-id={item.Game?.id}>
              {template && (
                <>
                  <img className={classes.imgTemplate} src={item.image} alt="" />
                  <span className={classes.category}>{item.Game?.title}</span>
                </>
              )}
              {item.title}{' '}
              {custom && (
                <img
                  className={classes.img}
                  src={process.env.REACT_APP_API_ENTRYPOINT + item.photoThumbs?.['128x128']}
                  alt=""
                />
              )}
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText>{state.error.status ? state.error.message : hint} </FormHelperText>
    </div>
  )
}
