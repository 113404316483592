import React, { useState, useEffect } from 'react'
import { FormHelperText, TextField } from '@material-ui/core'
import { validate } from '_helpers/validate'
import { useStyles } from 'components/form/FormStyles'
import Autocomplete from '@material-ui/lab/Autocomplete'

export const AutoCompleteType = ({
  name,
  value,
  inputValue,
  imageValue,
  validators,
  label,
  hint,
  disabled,
  setValue,
  setValidator,
  setErrorMessageSetter,
  fullWidth = true,
  options = [],
  custom = false,
}) => {
  const classes = useStyles()

  const [state, setState] = useState({
    value: value || '',
    validators: {},
    errors: {},
    error: { status: false, message: false },
  })

  const handleChange = (event, newValue) => {
    const value = newValue !== null ? newValue?.id : null
    const id = parseInt(newValue?.gameId)
    setState((state) => ({ ...state, value }))
    validator(value, id)

    setValueItem(newValue)
    custom && setImage(newValue?.photoThumbs['128x128'])
  }

  const handleInputChange = (event, newInputValue) => {
    if (event && event.type !== 'blur') {
      setNewInputValue(newInputValue)
    }
  }

  const validator = (value, id) => {
    const valid = validate(validators, value)
    setState((state) => ({
      ...state,
      error: { status: !valid.result, message: valid.message },
    }))
    setValue(name, !valid.result ? new Error(valid.message) : value, id)

    return valid.result
  }

  const setErrorMessage = (message) => {
    setState((state) => ({
      ...state,
      error: { status: true, message },
    }))
  }

  useEffect(() => {
    setValue(name, value)
    setValidator(name, validator)
    setErrorMessageSetter(name, setErrorMessage)
    setNewInputValue(inputValue)
  }, [])

  const optionsSorted = options.map((option) => {
    return {
      category: option.Game?.title,
      ...option,
    }
  })

  const [valueItem, setValueItem] = useState('')
  const [newInputValue, setNewInputValue] = useState('')

  const [image, setImage] = useState(imageValue)

  return (
    <div>
      <Autocomplete
        name={name}
        value={valueItem === null && ''}
        inputValue={newInputValue}
        onChange={(event, newValue) => {
          handleChange(event, newValue)
        }}
        onInputChange={(event, newInputValue) => {
          handleInputChange(event, newInputValue)
        }}
        error={state.error.status}
        disabled={disabled}
        fullWidth={fullWidth}
        classes={custom ? { groupUl: classes.groupUl, option: classes.option } : {}}
        options={optionsSorted.sort((a, b) => -b.category.localeCompare(a.category))}
        groupBy={(option) => option.category}
        autoHighlight
        getOptionLabel={(option) => option.title}
        renderOption={(option) => (
          <React.Fragment>
            {custom ? (
              <img
                className={classes.img}
                src={process.env.REACT_APP_API_ENTRYPOINT + option.photoThumbs?.['128x128']}
                alt=""
              />
            ) : (
              <img className={classes.imgTemplate} src={option.image} alt="" />
            )}
            {option.title}{' '}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label + (validators.includes('required') ? ' *' : '')}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
      <FormHelperText>{state.error.status ? state.error.message : hint} </FormHelperText>
      {custom && <img className={classes.img} src={process.env.REACT_APP_API_ENTRYPOINT + image} alt="" />}
    </div>
  )
}
