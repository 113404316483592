import React, { useState, useEffect } from 'react'
import { DropTarget } from 'react-drag-drop-container'
import shortid from 'shortid'

export const Box = (props) => {
  const [state, setState] = useState({ items: [] })

  const handleDrop = (e) => {
    let items = state.items.slice()
    items.push({
      label: e.dragData.label,
      image: e.dragData.image,
      name: e.dragData.name,
      uid: shortid.generate(),
    })
    e.containerElem.style.visibility = 'hidden'
    setState({ ...items, items: items })
    props.onchange({ name: items[0].name, index: props.index })
  }

  useEffect(() => {
    props.clear && setState({ items: props.data })
  }, [props.clear])

  return (
    <div className="component_box">
      <DropTarget onHit={handleDrop} targetKey={props.targetKey} dropData={{ name: props.name }}>
        <div className="box">
          {state.items.map((item) => {
            return (
              <div key={item.uid}>
                {item.label}
                <img src={item.image} alt="" />
              </div>
            )
          })}
        </div>
      </DropTarget>
    </div>
  )
}
