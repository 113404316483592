import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-2-4.svg'
import { Link, useLocation, Redirect } from 'react-router-dom'
import {
  selectGameContent,
  restart,
  toggleAnswer,
  fetchGameContent,
  selectTotalItems,
  next,
  selectCurrentStage,
  start,
  finish,
} from '_reducers/newGameSlice'
import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import classNames from 'classnames'

export const GameSubjectsImitation = ({ match }) => {
  const {
    params: { id },
  } = match
  const location = useLocation()
  const fromSurvey = location.state?.fromSurvey
  const therapeutic = location.state?.therapeutic
  const testMode = location.state?.testMode
  const dispatch = useDispatch()
  const gameContent = useSelector(selectGameContent)
  const totalItems = useSelector(selectTotalItems)
  const currentStage = useSelector(selectCurrentStage)

  function getData() {
    return (dispatch) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/practiceStage/${id}/listAll`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const content = res.content?.[currentStage]
          const totalItems = res.totalItems
          dispatch(fetchGameContent({ content, totalItems }))
        })
    }
  }
  useEffect(() => {
    dispatch(getData())
  }, [currentStage])

  useEffect(() => {
    dispatch(start())
  }, [])

  const [enabled, setEnabled] = useState(false)
  const [done, setDone] = useState(false)

  useEffect(() => {
    gameContent?.ElementA1?.type === 'TYPE_PHOTO' && setEnabled(true)
  }, [gameContent?.ElementA1?.type])

  const handlePlayVideo = () => {
    setEnabled(true)
  }

  const score = gameContent?.score

  const handleClick = (result) => {
    setDone(true)
    dispatch(toggleAnswer({ result, id, score }))
    totalItems - 1 === currentStage && testMode && dispatch(finish())
  }

  const handleNext = () => {
    dispatch(next())
    setDone(false)
  }

  return (
    <>
      <div className="game-area">
        <Breadcrumbs color="green" ico={ico} title={gameContent?.Practice?.title} description={gameContent?.Practice?.Game?.title} grandChild={true} />
        <p className="game-description">{gameContent?.Practice?.Game?.text}</p>
        {gameContent?.ElementA1?.type === 'TYPE_VIDEO' && (
          <div className="video video--wide">
            <video
              src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementA1?.videoUrl}`}
              muted
              playsInline
              controls
              onPlay={handlePlayVideo}
            />
          </div>
        )}
        {gameContent?.ElementA1?.type === 'TYPE_PHOTO' && (
          <img
            className="photo"
            src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementA1?.photoThumbs?.full}`}
            alt=""
          />
        )}
        <div className="container">
          <div className={classNames('buttons', !enabled && 'disabled', done && 'disabled')}>
            <button className="buttons__item btn btn--green" onClick={() => handleClick(true)}>
              Dobrze
            </button>
            <button className="buttons__item btn btn--no-fill" onClick={() => handleClick(false)}>
              Źle
            </button>
          </div>

          {done && (
            <>
              {!therapeutic && (
                <Link
                  to={fromSurvey ? '/diagnostic-games/survey-games' : '/diagnostic-games/imitation'}
                  className="btn survey__btn"
                  onClick={() => dispatch(restart())}
                >
                  Zakończ
                </Link>
              )}
              {therapeutic && totalItems - 1 > currentStage && (
                <button onClick={handleNext} className="btn survey__btn">
                  Następny etap
                </button>
              )}
              {therapeutic && totalItems - 1 === currentStage && !testMode && (
                <Link to={'/therapeutic-games'} className="btn survey__btn" onClick={() => dispatch(restart())}>
                  Zakończ ćwiczenie
                </Link>
              )}
              {therapeutic && totalItems - 1 === currentStage && testMode && <Redirect to={`/therapeutic-games/results/${id}`} />}
            </>
          )}
        </div>
        {therapeutic && (
          <nav className="dots">
            <p className="game-text">Postęp</p>
            {[...Array(totalItems)].map((item, i) =>
              currentStage === i ? <Link to className="dots__item active" /> : <Link to className="dots__item" />
            )}
          </nav>
        )}
      </div>
    </>
  )
}
