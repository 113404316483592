export const menuConstants = {
  TOOGLE_MOBILE_NAVIGATION_MENU: 'TOOGLE_MOBILE_NAVIGATION_MENU',
  TOOGLE_MOBILE_NAVIGATION_LOGIN: 'TOOGLE_MOBILE_NAVIGATION_LOGIN',
  TOOGLE_MOBILE_NAVIGATION_REGISTER: 'TOOGLE_MOBILE_NAVIGATION_REGISTER',
  HIDE_MOBILE_NAVIGATION_MENU: 'HIDE_MOBILE_NAVIGATION_MENU',
  HIDE_MOBILE_NAVIGATION_LOGIN: 'HIDE_MOBILE_NAVIGATION_LOGIN',
  HIDE_MOBILE_NAVIGATION_REGISTER: 'HIDE_MOBILE_NAVIGATION_REGISTER',
  SHOW_MOBILE_NAVIGATION_REGISTER: 'SHOW_MOBILE_NAVIGATION_REGISTER',
  SET_ROLES: 'SET_ROLES'
}
