import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { menuActions } from '_actions'
import { translate } from '_helpers/translate'

export const Login = ({ values, handlers, status, validate }) => {
  const dispatch = useDispatch()
  const handleToggleRegister = () => dispatch(menuActions.toggleMobileNavigationRegister())

  return (
    <form className="form" noValidate onSubmit={handlers.handleSubmit}>
      <div className="form__item">
        <input
          type="email"
          className="form__input"
          variant="outlined"
          margin="normal"
          required
          id="login"
          label={'T_LOGIN_LABEL'}
          name="login"
          autoComplete="login"
          value={values.login}
          onChange={handlers.handleChange}
        />
        <label className="form__label">
          {status.submitted && !validate(values.login) ? <span className="error">{translate('T_LOGIN_ERROR')}</span> : 'e-mail'}
        </label>
      </div>
      <div className="form__item">
        <input
          className="form__input"
          variant="outlined"
          margin="normal"
          required
          name="password"
          label={'T_PASSWORD_LABEL'}
          type="password"
          id="password"
          autoComplete="current-password"
          value={values.password}
          onChange={handlers.handleChange}
        />
        <div className="form__labels">
          <label className="form__label">
            {status.submitted && !values.password ? <span className="error">{translate('T_PASSWORD_ERROR')}</span> : 'hasło'}
          </label>
          <Link to="/security/startResettingPassword" className="form__label" onClick={handleToggleRegister}>
            Nie pamiętam hasła
          </Link>
        </div>
      </div>
      <button className="btn form__btn" disabled={status.isLogging} type="submit">
        Zaloguj
      </button>
    </form>
  )
}

Login.propTypes = {
  values: PropTypes.shape({
    login: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
  handlers: PropTypes.shape({
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  }).isRequired,
  status: PropTypes.shape({
    submitted: PropTypes.bool,
    isLogging: PropTypes.bool,
  }).isRequired,
}
