import { userConstants } from '_constants'
const SelectedChild = JSON.parse(localStorage.getItem('SelectedChild'))

const DEFAULT_STATE = {
  profile: {
    SelectedChild: SelectedChild ? SelectedChild : {},
  },
}

export const userReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case userConstants.PROFILE_SUCCESS:
    case userConstants.PROFILE_UPDATE:
      return {
        profile: {
          ...state.profile,
          ...action.payload,
        },
      }
    case userConstants.PROFILE_FAILURE:
    case userConstants.PROFILE_ERASE:
      return {}
    case userConstants.SET_CHILD_ID:
      return {
        profile: {
          ...state.profile,
          SelectedChild: {
            ...state.SelectedChild,
            ...action.payload,
          },
        },
      }
    case userConstants.VERIFY_BY_PARENT:
      return { ...state, verifyByParent: !state.verifyByParent }
    default:
      return state
  }
}
