import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { Grid } from '@material-ui/core'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { fetchData } from '_helpers/fetchData'
import { notification } from '_helpers/notification'
import { dataToObj, getProps } from '_helpers/tableProps'
import { translate } from '_helpers/translate'
import { StringType } from 'components/form/StringType'
import { SelectType } from 'components/form/SelectType'
import { CheckboxType } from 'components/form/CheckboxType'
import { useStyles } from 'components/form/FormStyles'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { useHistory } from 'react-router-dom'
import { practice as schema } from '_schema/practice'
import { practiceCategory as practiceCategorySchema } from '_schema/practiceCategory'
import { PracticeStagesTable } from './PracticeStagesTable'
import { routePath } from '_constants/routes.constants.js'
import ico from 'assets/ico-login-2.svg'

export const Addto = ({ match, dataResource }) => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    params: { id, gameId },
  } = match

  const url = id ? schema.set : schema.add
  const method = id ? 'PUT' : 'POST'
  const data = id
    ? dataResource
    : {
        stat: false,
      }

  const therapyId = useSelector((state) => state.user.profile?.SelectedChild?.therapyId)

  const [state, setState] = useState({
    values: { gameId: parseInt(gameId) },
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
    url: url.includes('{id}') ? url.replace('{id}', id) : url,
    practiceCategories: [],
  })

  const [activateBtn, setActivateBtn] = useState(false)

  useEffect(() => {
    dataResource?.stagesCount > 0 && setActivateBtn(true)
  }, [dataResource?.stagesCount])

  const onchange = (image, stagesLength) => {
    image &&
      setState((state) => ({
        ...state,
        values: { ...state.values, image: image },
      }))
    stagesLength > 0 ? setActivateBtn(true) : setActivateBtn(false)
  }

  useEffect(() => {
    fetchData(practiceCategorySchema.list, 'GET', {}, (resp) => resp.json(), false).then((response) => {
      setState((state) => ({ ...state, practiceCategories: response.content }))
    })
  }, []) // bez parametrów odpala sie tylko raz

  const fields = {
    title: {
      type: 'string',
      validate: ['required'],
      label: translate('T_NAME_LABEL'),
      hint: translate('T_NAME_HINT'),
    },
    text: {
      type: 'textarea',
      validate: ['required'],
      label: translate('T_DESCRIPTION_LABEL'),
      hint: translate('T_DESCRIPTION_HINT'),
    },
    practiceCategoryId: {
      type: 'select',
      addEmpty: true,
      validate: ['required'],
      options: state.practiceCategories,
      label: translate('T_GAME_PRACTICE_CATEGORY_LABEL'),
      hint: translate('T_GAME_PRACTICE_CATEGORY_HINT'),
    },
    stat: {
      type: 'check',
      validate: [],
      label: 'dodaj ćwiczenie do aktualnej terapii',
    },
  }

  const handleSuccess = (resp, redirect) => {
    if (id) {
      redirect && history.push(routePath.program.list())
    } else {
      history.push(routePath.practices.edit(resp.gameId, resp.id))
    }
  }

  const handleSubmit = (redirect) => {
    if (
      Object.keys(state.validators).length &&
      !Object.keys(state.validators).reduce(
        (status, name) =>
          state.validators[name].validator(
            ...Object.keys(state.values)
              .filter((item) => state.validators[name].values.includes(item))
              .map((item) => state.values[item])
          ) && status,
        true
      )
    ) {
      return
    }

    setState((state) => ({ ...state, isProcessing: true }))

    dispatch(requestActions.start(state.url))

    fetchDataHandleAuthError(
      state.url,
      method,
      { body: JSON.stringify(dataToObj(state.values)) },
      (resp) => {
        setState((state) => ({ ...state, isProcessing: false }))
        dispatch(requestActions.success())
        notification(
          'success',
          method === 'PUT' ? translate('T_RECORD_UPDATED') : translate('T_RECORD_ADDED'),
          translate('T_SAVED')
        )
        handleSuccess && handleSuccess(resp, redirect)
      },
      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        error.response.violations.map((item) => state.errorMessageSetters[item.propertyPath](translate(item.message)))
        notification(
          'error',
          error.response.violations.length ? translate('T_INCORRECT_FORM') : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE
    )
    return false
  }

  const setValue = (name, value) => {
    const isInvalid = value instanceof Error
    setState((state) => ({
      ...state,
      values: {
        ...state.values,
        [name]: !isInvalid && value,
        therapyId: value === true ? therapyId : id ? null : 0,
      },

      errors: { ...state.errors, [name]: isInvalid },
      invalid: state.invalid + (!!state.errors[name] === !isInvalid && -1 + isInvalid * 2),
    }))
  }

  const setValidator = (name, validator, values) => {
    setState((state) => ({
      ...state,
      validators: {
        ...state.validators,
        [name]: { validator, values: values || [name] },
      },
    }))
  }

  const setErrorMessageSetter = (name, errorMessageSetter) => {
    setState((state) => ({
      ...state,
      errorMessageSetters: {
        ...state.errorMessageSetters,
        [name]: errorMessageSetter,
      },
    }))
  }

  const saveAsTemplate = (e) => {
    e.preventDefault()
    fetchData(`/practice/performCloning/${id}/${therapyId}`, 'GET', {}, (resp) => resp.json(), false).then((resp) => {
      history.push(routePath.practices.edit(resp.gameId, resp.id))
      notification('success', translate('T_RECORD_UPDATED'), translate('T_SAVED'))
    })
  }

  useEffect(() => {
    state.values.image && handleSubmit(false)
  }, [state.values.image])

  return (
    <>
      <Breadcrumbs color="grass-green" ico={ico} title="Program terapeutyczny" grandChild={true} />

      <div className="container-full">
        <h3 className="page-heading">
          {translate('T_GAME_PRACTICE_OF_GAME')}: {data.Game && data.Game.title}
        </h3>
        <Grid container spacing={0}>
          <form className={classes.root}>
            <Grid container spacing={3}>
              {Object.keys(fields).map((name, key) => {
                if (!fields[name]) return null

                return (
                  <Grid item xs={12} key={`${key}_grid`}>
                    {(fields[name].type === 'string' || fields[name].type === 'textarea') && (
                      <StringType
                        key={key}
                        name={fields[name].name ? fields[name].name : name}
                        label={fields[name].label}
                        type={fields[name].type}
                        hint={fields[name].hint}
                        value={
                          data && (fields[name]['resource'] ? getProps(data, fields[name]['resource']) : data[name])
                        }
                        disabled={fields[name].disabled === true ? true : state.isProcessing}
                        validators={fields[name].validate || []}
                        setValue={setValue}
                        setValidator={setValidator}
                        setErrorMessageSetter={setErrorMessageSetter}
                      />
                    )}

                    {fields[name].type === 'check' && (
                      <CheckboxType
                        key={key}
                        name={fields[name].name ? fields[name].name : name}
                        label={fields[name].label}
                        hint={fields[name].hint}
                        checked={
                          data &&
                          (fields[name]['resource']
                            ? getProps(data, fields[name]['resource'])
                            : data[name]
                            ? data[name]
                            : false)
                        }
                        disabled={fields[name].disabled === true ? true : state.isProcessing}
                        validators={fields[name].validate || []}
                        setValue={setValue}
                        setValidator={setValidator}
                        setErrorMessageSetter={setErrorMessageSetter}
                      />
                    )}

                    {fields[name].type === 'select' && (
                      <SelectType
                        value={
                          data && (fields[name]['resource'] ? getProps(data, fields[name]['resource']) : data[name])
                        }
                        options={fields[name].options}
                        addEmpty={fields[name].addEmpty}
                        name={name}
                        validators={fields[name].validate || []}
                        label={fields[name].label}
                        hint={fields[name].hint}
                        setValue={setValue}
                        setValidator={setValidator}
                        setErrorMessageSetter={setErrorMessageSetter}
                      />
                    )}
                  </Grid>
                )
              })}
              <div className="full">
                <img className="mt20" src={state.values.image ? state.values.image : data.image} alt="" />
              </div>
              <Grid item sm={8} xs={12}>
                <div className="buttons">
                  {data.therapyId && (
                  <button
                    onClick={handleSubmit}
                    className="buttons__item btn btn--game"
                    disabled={state.isProcessing || !!state.invalid}
                  >
                    {id ? translate('T_SAVE') : translate('T_ADD_SAVE')}
                  </button>
                  )}
                  {activateBtn && (
                    <button className="buttons__item btn btn--green btn--game" onClick={saveAsTemplate}>
                      Zapisz jako szablon
                    </button>
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        </Grid>

        {(state.canUpload || data.id > 0) && (
          <>
            <PracticeStagesTable
              practice={dataResource}
              onchange={(image, stagesLength) => {
                onchange(image, stagesLength)
              }}
            />
          </>
        )}
      </div>
    </>
  )
}
