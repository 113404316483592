import React, { useState, useEffect } from 'react'
import ico from 'assets/ico-1.svg'
import arrowLeft from 'assets/arrow-left-color.svg'
import arrowRight from 'assets/arrow-right-color.svg'
import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import {
  selectActiveQuestion,
  selectData,
  selectStartedAt,
  selectFinishedAt,
  toggleAnswer,
  start,
  prev,
  next,
  restart,
  finish,
} from '_reducers/quizSlice'
//import { clearData } from '_reducers/newGameSlice'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Redirect } from 'react-router-dom'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'

export const SurveyIndex = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectData)
  const activeQuestion = useSelector(selectActiveQuestion)
  const startedAt = useSelector(selectStartedAt)
  const finishedAt = useSelector(selectFinishedAt)
  const question = data?.Questions?.[activeQuestion]

  const handleToggle = (i) => () => {
    dispatch(toggleAnswer({ answer: i }))
  }

  const handleNext = () => {
    let leastOneChecked = question.Answers.some((a) => a.checked === true)
    if (leastOneChecked) {
      if (data?.Questions?.[activeQuestion + 1]) {
        dispatch(next())
      } else {
        dispatch(finish())
      }
    } else {
      notification('warning', 'Zaznacz odpowiedź', 'Komunikat')
    }
  }

  const handlePrev = () => {
    if (data?.Questions?.[activeQuestion - 1]) {
      dispatch(prev())
    } else {
      dispatch(restart())
    }
  }

  const inquiryId_R = useSelector((state) => state.user.profile?.SelectedChild?.ageMatchedInquiryId)
  const childId_R = useSelector((state) => state.user.profile?.SelectedChild?.id)

  const [inquiries, setInquiries] = useState([])
  const [inquiryId, setInquiryId] = useState(inquiryId_R)

  const getInquiries = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/inquiries/active`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setInquiries(res)
      })
  }

  useEffect(() => {
    getInquiries()
    //dispatch(clearData())
  }, [])

  useEffect(() => {
    setInquiryId(inquiryId_R)
    dispatch({
      type: 'quiz/inquiryId',
      payload: parseInt(inquiryId_R),
    })
    setChildId(childId_R)
    dispatch({
      type: 'quiz/childId',
      payload: childId_R,
    })
  }, [inquiryId_R])

  const handleChangeAge = (value) => {
    setInquiryId(parseInt(value))
    dispatch({
      type: 'quiz/inquiryId',
      payload: parseInt(value),
    })
  }

  function getData() {
    return (dispatch) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/inquiries/whole/${inquiryId}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          dispatch({
            type: 'quiz/fetchData',
            payload: res,
          })
        })
    }
  }

  const handleStart = () => {
    if (inquiryId || childId) {
      dispatch(start())
      dispatch(getData())
    } else {
      notification('warning', 'Wybierz opcję', 'Komunikat')
    }
  }

  const [children, setChildren] = useState([])
  const [childId, setChildId] = useState(childId_R)

  const parentId = useSelector((state) => state.user.profile?.id)

  const getChildren = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/parentChilds/${parentId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setChildren(res)
      })
  }

  useEffect(() => {
    parentId && getChildren()
  }, [parentId])

  const handleChange = (e) => {
    setChildId(e.target.value)
    dispatch({
      type: 'quiz/childId',
      payload: e.target.value,
    })
    setInquiryId(parseInt(e.target.options[e.target.selectedIndex].dataset.id))
    dispatch({
      type: 'quiz/inquiryId',
      payload: parseInt(e.target.options[e.target.selectedIndex].dataset.id),
    })
  }

  return (
    <>
      <Breadcrumbs color="purple" ico={ico} title="Ankieta obszarów ryzyka" backHome={true} />
      <div className="survey">
        {finishedAt && (
          <>
            <Redirect to="/diagnostic-games/survey-games" />
          </>
        )}

        {!startedAt && !finishedAt && (
          <>
            <p className="survey__description">
              Jeśli zastanawiasz się czy Twoje dziecko rozwija się prawidłowo - nasza aplikacja jest po to aby Ci pomóc!
              Wypełnij ankietę dostosowaną do wieku dziecka. Niektóre pytania będą wymagały obserwacji dziecka. W
              przypadku dziecka powyżej 2 roku życia - po udzieleniu wszystkich odpowiedzi przygotowaliśmy także
              specjalny zestaw gier i zadań, który pomoże uzyskać wiarygodną diagnozę.
            </p>
            {hasRole(authConstants.ROLE_PARENT) ? (
              <select className="custom-select survey__select" onChange={(e) => handleChange(e)}>
                <option hidden>Wybierz dziecko:</option>
                {children?.content?.map((item) => (
                  <option
                    key={item.id}
                    value={item.id}
                    data-id={item.ageMatchedInquiryId}
                    selected={item.id === childId ? 'selected' : ''}
                  >
                    {item.firstName}
                  </option>
                ))}
              </select>
            ) : (
              <select className="custom-select survey__select" onChange={(e) => handleChangeAge(e.target.value)}>
                <option selected hidden>
                  Wybierz wiek dziecka:
                </option>
                {inquiries?.content?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.label}
                  </option>
                ))}
              </select>
            )}
            <button onClick={handleStart} className="btn survey__btn">
              Rozpocznij
            </button>
          </>
        )}

        {startedAt && !finishedAt && (
          <>
            <nav className="survey__nav s-top-nav">
              <img src={arrowLeft} alt="" onClick={handlePrev} />
              <span className="s-top-nav__counter">
                {activeQuestion + 1} / {data?.Questions?.length}
              </span>
              <img src={arrowRight} alt="" onClick={handleNext} />
            </nav>

            <div className="survey__question s-question">
              <h4 className="s-question__title">{question?.title}</h4>
              <p className="s-question__description">{question?.text}</p>

              {question?.Answers.map((answer, i) => (
                <div className="s-question__radio" id={i} key={i} onClick={handleToggle(i)}>
                  <input checked={answer.checked} tabIndex={-1} aria-labelledby={i} name="test" type="radio" />
                  {answer.text}
                </div>
              ))}
            </div>

            <nav className="survey__nav survey__nav--bottom">
              <button className="btn btn--no-fill survey__btn" onClick={handlePrev}>
                Wstecz
              </button>
              <button className="btn survey__btn" onClick={handleNext}>
                Dalej
              </button>
            </nav>
          </>
        )}
      </div>
    </>
  )
}
