import { tokenExpiration } from './tokenExpiration'
// import { tokenRoles } from './tokenRoles'

export const tokenVerify = (token) => {
  if (!token) {
    return null
  }
  const now = new Date()
  const exp = tokenExpiration(token)

  // const isAdmin = tokenRoles(token, role)
  // if(!isAdmin) { return false; }

  if (now > exp) {
    return false
  }

  return true
}
