import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { Route } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { Add } from './Child/Add'
import { Edit } from './Child/Edit'
import { ProfileContainer } from './Profile'

const routes = () => [
  {
    title: 'Profil',
    type: Route,
    component: ProfileContainer,
    path: '/',
    exact: true,
  },
  {
    title: 'Dodawanie dziecka',
    type: Route,
    component: Add,
    path: '/add-child',
    exact: true,
  },
  {
    title: 'Edycja dziecka',
    type: Route,
    component: Edit,
    path: '/add-child/:childId',
    exact: true,
  },

  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Profile = (props) => {
  const { match } = props
  return <Page routes={generateRoutes(prefixPaths(match.url, routes(), ['path', 'from', 'to']))} />
}
