import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { SettingsIndex } from './SettingsIndex'
import { routePath } from '_constants/routes.constants.js'

const routes = () => [
  {
    title: 'Ustawienia dźwięków',
    type: Route,
    render: () => <SettingsIndex />,
    path: routePath.settings.index(''),
    exact: true,
  },

  {
    title: 'Ustawienia dźwięków',
    type: Redirect,
    from: '/',
    to: routePath.settings.index(''),
    exact: true,
  },
  {
    title: 'Ustawienia dźwięków',
    type: Redirect,
    from: '/*/',
    to: routePath.settings.index(''),
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Settings = (props) => {
  const { match } = props

  return <Page routes={generateRoutes(prefixPaths(match.url, routes(), ['path', 'from', 'to']))} />
}
