import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { AccessDenied } from 'pages/AccessDenied'
import { ChildrenIndex } from './ChildrenIndex'
import { TherapistTherapiesList } from './ChildrenLists/TherapistTherapiesList'
import { ChildrenList } from './ChildrenLists/ChildrenList'
import { routePath } from '_constants/routes.constants.js'
import { hasRole } from '_helpers/hasRole'
import { authConstants } from '_constants/auth.constants'

const routes = () => [
  {
    title: 'Dzieci',
    type: Route,
    render: () => <ChildrenIndex />,
    path: routePath.children.index(''),
    exact: true,
  },
  {
    title: 'Dzieci - lista',
    type: Route,
    render: () => <ChildrenList />,
    path: routePath.children.list(''),
    exact: true,
  },
  {
    title: 'Dzieci - terapie',
    type: Route,
    render: () => <TherapistTherapiesList />,
    path: routePath.children.therapy(''),
    exact: true,
  },
  {
    title: 'Dzieci',
    type: Redirect,
    from: '/',
    to: routePath.children.index(''),
    exact: true,
  },
  {
    title: 'Dzieci',
    type: Redirect,
    from: '/*/',
    to: routePath.children.index(''),
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const Children = (props) => {
  const { match } = props
  if (hasRole(authConstants.ROLE_THERAPIST)) {
    return <Page routes={generateRoutes(prefixPaths(match.url, routes(), ['path', 'from', 'to']))} />
  } else {
    return <AccessDenied />
  }
}
