import React from 'react'
import { useSelector } from 'react-redux'

export const PaymentsList = () => {
  const payments = useSelector((state) => state.user.profile?.Payments)

  return (
    <>
      {payments !== undefined && (
        <div className="payment-list">
          <h4 className="login-content__link">Lista płatności</h4>
          {payments?.map(({ payuOrderId, createdAt, orderPrice, payuResult }) => {
            return (
              <div key={payuOrderId}>
                {new Date(createdAt).toLocaleDateString('pl-PL')} - <b>{orderPrice}zł</b> -{' '}
                {payuResult === 'COMPLETED' ? 'potwierdzona' : 'niepotwierdzona'}
              </div>
            )
          })}
        </div>
      )}
    </>
  )
}
