import { soundConstants } from '_constants'

const setVoice = (voice) => ({
  type: soundConstants.SET_VOICE,
  payload: voice,
})

const setVolume = (volume) => ({
  type: soundConstants.SET_VOLUME,
  payload: volume,
})

const setPlaybackRate = (playbackRate) => ({
  type: soundConstants.SET_PLAYBACK_RATE,
  payload: playbackRate,
})

const setSuccessSound = (successSound) => ({
  type: soundConstants.SET_SUCCESS_SOUND,
  payload: successSound,
})

const setFailureSound = (failureSound) => ({
  type: soundConstants.SET_FAILURE_SOUND,
  payload: failureSound,
})

const setBackgroundSound = (backgroundSound) => ({
  type: soundConstants.SET_BACKGROUND_SOUND,
  payload: backgroundSound,
})

export const soundActions = {
  setVoice,
  setVolume,
  setPlaybackRate,
  setSuccessSound,
  setFailureSound,
  setBackgroundSound,
}
