import React, { useState, useEffect, useRef } from 'react'
import { Line } from 'react-chartjs-2'
import { Settings } from 'luxon'
import 'chartjs-adapter-luxon'
import { authHeader } from '_helpers/authHeader'

export const Graph = ({ content }) => {
  Settings.defaultLocale = 'pl'

  const [state, setState] = useState({})

  let count = 0

  const solved = [...content].reverse().map((item) => {
    return {
      x: new Date(item.solvedAt),
      y: item.solved === true ? (count += 1) : count,
    }
  })

  const setDates = () => {
    const data = {
      datasets: [
        {
          data: solved,
          fill: false,
          borderColor: 'orange',
        },
      ],
    }
    const [lastNumber] = solved.slice(-1)
    setState((state) => ({
      ...state,
      data,
      key: Date.now(),
      lastNumber: lastNumber?.y,
    }))
  }

  useEffect(() => {
    setDates()
  }, [content])

  const ref = useRef()

  const canvas = ref?.current?.chartInstance?.canvas
  const dataURL = canvas?.toDataURL()

  const handlePrint = () => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/practiceSolution/pdfDownloadChart/${content[0].id}`, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          ...authHeader(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          base64: dataURL,
        }),
      })
      const blob = await response.blob()

      const file = new Blob([blob], { type: 'application/pdf' })

      const fileURL = URL.createObjectURL(file)

      setState((state) => ({
        ...state,
        pdf: fileURL,
      }))
    }
    fetchData()
  }

  useEffect(() => {
    state.pdf && window.open(state.pdf)
  }, [state.pdf])

  return (
    <div>
      <Line
        ref={ref}
        data={state.data}
        options={{
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                scaleLabel: {
                  display: true,
                  labelString: 'Zakres',
                },
              },
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Opanowane',
                },
                ticks: {
                  precision: 0,
                },
              },
            ],
          },
        }}
      />
      <p className="counter">
        opanowane: <b>{state.lastNumber}</b>
      </p>
      <button className="btn survey__btn" onClick={handlePrint}>
        Drukuj
      </button>
    </div>
  )
}
