import React, { useState } from 'react'
import ico from 'assets/ico-3.svg'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Link } from 'react-router-dom'
import { useFetch } from '_helpers/useFetch'
import { authHeader } from '_helpers/authHeader'
import { useSelector, useDispatch } from 'react-redux'
import ico_0 from 'assets/ico-2-1.svg'
import ico_1 from 'assets/ico-2-2.svg'
import ico_2 from 'assets/ico-2-3.svg'
import ico_3 from 'assets/ico-2-4.svg'

export const MediaLibraryIndex = () => {
  const iconsMap = {
    0: ico_0,
    1: ico_0,
    2: ico_0,
    3: ico_1,
    4: ico_1,
    5: ico_2,
    6: ico_3,
    7: ico_3,
    8: ico_3,
    9: ico_1,
  }

  const endPoint = `${process.env.REACT_APP_API_ENTRYPOINT}/games/list`

  const dispatch = useDispatch()

  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      ...authHeader(),
    },
  }
  const auth = useSelector((state) => state.auth)

  const listqueryParams =
    localStorage.listGameQueryParams === undefined
      ? {
          search: '',
          page: 1,
          perPage: 12,
          'order[name]': 'ASC',
        }
      : JSON.parse(localStorage.listGameQueryParams)

  const [queryParams] = useState(listqueryParams)

  const args = [auth.data.token, dispatch]

  const res = useFetch(endPoint, options, args, queryParams)

  const data = res.state.data ? res.state.data?.content : []

  const isLoading = res.state.isLoading

  return (
    <>
      <Breadcrumbs color="blue-green" ico={ico} title="Biblioteka" backHome={true} />
      <div className="container-full">
        <div className="boxes-grid boxes-grid--fs">
          {!isLoading &&
            data &&
            data.map((row, index) => {
              return (
                <Link className="boxes-grid__item box" key={`${index}_link`} to={`/media-library/elements/${row.id}`}>
                  <span className="box__visuals--square-sm box__visuals box box__visuals--green">
                    <img className="box__ico" src={iconsMap[index]} alt="" />
                  </span>
                  <h2 className="box__title">{row.title}</h2>
                </Link>
              )
            })}
        </div>
      </div>
    </>
  )
}
