import React, { useEffect, useState } from 'react'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-2-3.svg'
import useSound from 'use-sound'
import { notification } from '_helpers/notification'
import { Link, useLocation, Redirect } from 'react-router-dom'
import classNames from 'classnames'
import {
  selectGameContent,
  restart,
  toggleAnswer,
  fetchGameContent,
  selectTotalItems,
  next,
  selectCurrentStage,
  start,
  finish,
} from '_reducers/newGameSlice'
import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'

export const GameEmotionsIdentification = ({ match }) => {
  const {
    params: { id },
  } = match
  const location = useLocation()
  const fromSurvey = location.state?.fromSurvey
  const therapeutic = location.state?.therapeutic
  const testMode = location.state?.testMode
  const dispatch = useDispatch()
  const gameContent = useSelector(selectGameContent)
  const totalItems = useSelector(selectTotalItems)
  const currentStage = useSelector(selectCurrentStage)
  const parentId = useSelector((state) => state.user.profile?.id)
  const childId = useSelector((state) => state.user.profile?.SelectedChild?.id)

  function getData() {
    return (dispatch) => {
      fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/practiceStage/${id}/listAll`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          ...authHeader(),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          const content = res.content?.[currentStage]
          const totalItems = res.totalItems
          dispatch(fetchGameContent({ content, totalItems }))
        })
    }
  }
  useEffect(() => {
    dispatch(getData())
  }, [currentStage])

  useEffect(() => {
    dispatch(start())
  }, [])

  const [resultset, setResultset] = useState([])

  function randomUniqueNum(range, outputCount) {
    let arr = []
    for (let i = 0; i <= range; i++) {
      arr.push(i)
    }

    let result = []

    for (let i = 0; i <= outputCount; i++) {
      const random = Math.floor(Math.random() * (range - i))
      result.push(arr[random])
      arr[random] = arr[range - i]
    }

    return result
  }

  useEffect(() => {
    setResultset(randomUniqueNum(3, 3))
  }, [])

  const volume_R = useSelector((state) => state.sound.volume)

  const [play, { stop }] = useSound(`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementA1?.audioUrl}`, {
    volume: volume_R,
  })

  useEffect(() => {
    return () => {
      stop()
    }
  }, [stop])

  const [enabled, setEnabled] = useState(false)
  const [done, setDone] = useState(false)

  useEffect(() => {
    gameContent?.ElementA1?.type === 'TYPE_PHOTO' && setEnabled(true)
  }, [gameContent?.ElementA1?.type])

  const score = gameContent?.score

  const successSound_R = useSelector((state) => state.sound.successSound)
  const failureSound_R = useSelector((state) => state.sound.failureSound)
  const [playSuccess] = useSound(successSound_R)
  const [playFailure] = useSound(failureSound_R)

  const postData = (emotion) => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/parent_datas`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        emotion: emotion,
        gameTitle: gameContent?.title,
        Parent: `user/${parentId}`,
        Child: `child/${childId}`,
      }),
    })
  }

  const setEmotion = (e, result) => {
    const value = e.currentTarget.dataset.emotion
    postData(value)

    if (!fromSurvey) {
      notification(result ? 'success' : 'error', `Czuje ${value}`)
      result ? playSuccess() : playFailure()
    }
    setDone(true)

    dispatch(toggleAnswer({ result, id, score }))
    totalItems - 1 === currentStage && testMode && dispatch(finish())
    stop()
  }

  const handlePlay = () => {
    play()
    setEnabled(true)
  }

  const handlePlayVideo = () => {
    setEnabled(true)
  }

  const handleNext = () => {
    dispatch(next())
    setDone(false)
    setEnabled(false)
  }

  return (
    <>
      <div className="game-area">
        <Breadcrumbs
          color="green"
          ico={ico}
          title={gameContent?.Practice?.title}
          description={gameContent?.Practice?.Game?.title}
          grandChild={true}
        />
        <p className="game-description">{gameContent?.Practice?.Game?.text}</p>
        {gameContent?.ElementA1?.type === 'TYPE_AUDIO' && (
          <button className="btn btn--light form__btn" onClick={handlePlay}>
            Odtwórz dźwięk
          </button>
        )}
        {gameContent?.ElementA1?.type === 'TYPE_VIDEO' && (
          <div className="video video--wide">
            <video
              src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementA1?.videoUrl}`}
              muted
              playsInline
              controls
              onPlay={handlePlayVideo}
            />
          </div>
        )}
        {gameContent?.ElementA1?.type === 'TYPE_PHOTO' && (
          <img
            className="photo"
            src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementA1?.photoThumbs?.full}`}
            alt=""
          />
        )}

        {resultset && (
          <div className={classNames('emotions', !enabled && 'disabled', done && 'disabled')}>
            {gameContent?.ElementB1?.photoThumbs && (
              <div
                className="emotions__item"
                style={{ order: resultset[0] }}
                data-emotion={gameContent?.ElementB1.title}
                onClick={(e) => setEmotion(e, true)}
              >
                <img
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementB1?.photoThumbs?.['128x128']}`}
                  width="70"
                  alt=""
                />
                <h6 className="emotions__title">{gameContent?.ElementB1.title}</h6>
              </div>
            )}
            {gameContent?.ElementC1?.photoThumbs && (
              <div
                className="emotions__item"
                style={{ order: resultset[1] }}
                data-emotion={gameContent?.ElementC1.title}
                onClick={(e) => setEmotion(e, false)}
              >
                <img
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementC1?.photoThumbs?.['128x128']}`}
                  width="70"
                  alt=""
                />
                <h6 className="emotions__title">{gameContent?.ElementC1.title}</h6>
              </div>
            )}
            {gameContent?.ElementC2?.photoThumbs && (
              <div
                className="emotions__item"
                style={{ order: resultset[2] }}
                data-emotion={gameContent?.ElementC2.title}
                onClick={(e) => setEmotion(e, false)}
              >
                <img
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}${gameContent?.ElementC2?.photoThumbs?.['128x128']}`}
                  width="70"
                  alt=""
                />
                <h6 className="emotions__title">{gameContent?.ElementC2.title}</h6>
              </div>
            )}
          </div>
        )}
        {done && (
          <>
            {!therapeutic && (
              <Link
                to={fromSurvey ? '/diagnostic-games/survey-games' : '/diagnostic-games/identification'}
                className="btn survey__btn survey__btn--mt0"
                onClick={() => dispatch(restart())}
              >
                Zakończ
              </Link>
            )}
            {therapeutic && totalItems - 1 > currentStage && (
              <button onClick={handleNext} className="btn survey__btn survey__btn--mt0">
                Następny etap
              </button>
            )}
            {therapeutic && totalItems - 1 === currentStage && !testMode && (
              <Link
                to={'/therapeutic-games'}
                className="btn survey__btn survey__btn--mt0"
                onClick={() => dispatch(restart())}
              >
                Zakończ ćwiczenie
              </Link>
            )}
            {therapeutic && totalItems - 1 === currentStage && testMode && (
              <Redirect to={`/therapeutic-games/results/${id}`} />
            )}
          </>
        )}
        {therapeutic && (
          <nav className="dots">
            <p className="game-text">Postęp</p>
            {[...Array(totalItems)].map((item, i) =>
              currentStage === i ? <Link to className="dots__item active" /> : <Link to className="dots__item" />
            )}
          </nav>
        )}
      </div>
    </>
  )
}
