import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { AccessDeniedGames } from 'pages/AccessDenied'
import { TherapeuticGamesIndex } from './TherapeuticGamesIndex'
import { Results } from './Results'
import { routePath } from '_constants/routes.constants.js'

const routes = () => [
  {
    title: 'Gry terapeutyczne',
    type: Route,
    render: () => <TherapeuticGamesIndex />,
    path: routePath.tgames.index(''),
    exact: true,
  },
  {
    title: 'Gry terapeutyczne - wyniki',
    type: Route,
    component: Results,
    path: routePath.tgames.results(':id', ''),
    exact: true,
  },
  {
    title: 'Gry terapeutyczne',
    type: Redirect,
    from: '/',
    to: routePath.tgames.index(''),
    exact: true,
  },
  {
    title: 'Gry terapeutyczne',
    type: Redirect,
    from: '/*/',
    to: routePath.tgames.index(''),
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const TherapeuticGames = (props) => {
  const { match } = props
  const isPremium = useSelector((state) => state.user.profile?.isPremium)
  if (isPremium) {
    return <Page routes={generateRoutes(prefixPaths(match.url, routes(), ['path', 'from', 'to']))} />
  } else {
    return <AccessDeniedGames />
  }
}
