import React from 'react'
import { prefixPaths } from '_helpers/prefixPaths'
import { generateRoutes } from '_helpers/generateRoutes'
import { Route, Redirect } from 'react-router-dom'
import { Page } from 'pages/Page'
import { NotFound } from 'pages/NotFound'
import { DiagnosticGamesIndex } from './DiagnosticGamesIndex'
import { DiagnosticGamesInProgress } from './DiagnosticGamesInProgress'
import { Observation } from './Observation/Observation'
import { GameIdentification } from './Observation/GameIdentification/GameIdentification'
import { GameSorting } from './Observation/GameSorting/GameSorting'
import { GameMapping } from './Observation/GameMapping/GameMapping'

import { Recognition } from './Recognition/Recognition'
import { GameUnderstandingSpeech } from './Recognition/GameUnderstandingSpeech/GameUnderstandingSpeech'
import { GameSpeaking } from './Recognition/GameSpeaking/GameSpeaking'
import { GameVerbalCommunication } from './Recognition/GameVerbalCommunication/GameVerbalCommunication'

import { Identification } from './Identification/Identification'
import { GameEmotionsIdentification } from './Identification/GameEmotionsIdentification/GameEmotionsIdentification'

import { Imitation } from './Imitation/Imitation'
import { GameMimicImitation } from './Imitation/GameMimicImitation/GameMimicImitation'
import { GameGesturesImitation } from './Imitation/GameGesturesImitation/GameGesturesImitation'
import { GameSubjectsImitation } from './Imitation/GameSubjectsImitation/GameSubjectsImitation'

import { SurveyGames } from './SurveyGames'
import { routePath } from '_constants/routes.constants.js'

const routes = () => [
  {
    title: 'Przykładowe gry',
    type: Route,
    render: () => <DiagnosticGamesIndex />,
    path: routePath.dgames.index(''),
    exact: true,
  },
  {
    title: 'Przykładowe gry',
    type: Route,
    render: () => <DiagnosticGamesInProgress />,
    path: routePath.dgames.inprogress(''),
    exact: true,
  },
  {
    title: 'Przykładowe gry - ankieta',
    type: Route,
    component: SurveyGames,
    path: routePath.dgames.SurveyGames(''),
    exact: true,
  },
  {
    title: 'Bystre oko - lista',
    type: Route,
    component: Observation,
    path: routePath.dgames.Observation(''),
    exact: true,
  },
  {
    title: 'Bystre oko - identyfikacja',
    type: Route,
    component: GameIdentification,
    path: routePath.dgames.GameIdentification(':id', ''),
    exact: true,
  },
  {
    title: 'Bystre oko - sortowanie',
    type: Route,
    component: GameSorting,
    path: routePath.dgames.GameSorting(':id', ''),
    exact: true,
  },
  {
    title: 'Bystre oko - odwzorowanie',
    type: Route,
    component: GameMapping,
    path: routePath.dgames.GameMapping(':id', ''),
    exact: true,
  },
  {
    title: 'Werbalizacja i komunikacja - lista',
    type: Route,
    component: Recognition,
    path: routePath.dgames.Recognition(''),
    exact: true,
  },
  {
    title: 'Werbalizacja i komunikacja - rozumienie mowy',
    type: Route,
    component: GameUnderstandingSpeech,
    path: routePath.dgames.GameUnderstandingSpeech(':id', ''),
    exact: true,
  },
  {
    title: 'Werbalizacja i komunikacja - mówienie',
    type: Route,
    component: GameSpeaking,
    path: routePath.dgames.GameSpeaking(':id', ''),
    exact: true,
  },
  {
    title: 'Komunikacja werbalna',
    type: Route,
    component: GameVerbalCommunication,
    path: routePath.dgames.GameVerbalCommunication(':id', ''),
    exact: true,
  },
  {
    title: 'Identyfikacja emocji - lista',
    type: Route,
    component: Identification,
    path: routePath.dgames.Identification(''),
    exact: true,
  },
  {
    title: 'Identyfikacja emocji',
    type: Route,
    component: GameEmotionsIdentification,
    path: routePath.dgames.GameEmotionsIdentification(':id', ''),
    exact: true,
  },
  {
    title: 'Naśladowanie - lista',
    type: Route,
    component: Imitation,
    path: routePath.dgames.Imitation(''),
    exact: true,
  },
  {
    title: 'Naśladowanie - mimika',
    type: Route,
    component: GameMimicImitation,
    path: routePath.dgames.GameMimicImitation(':id', ''),
    exact: true,
  },
  {
    title: 'Naśladowanie - gesty',
    type: Route,
    component: GameGesturesImitation,
    path: routePath.dgames.GameGesturesImitation(':id', ''),
    exact: true,
  },
  {
    title: 'Naśladowanie - z przedmiotami',
    type: Route,
    component: GameSubjectsImitation,
    path: routePath.dgames.GameSubjectsImitation(':id', ''),
    exact: true,
  },
  {
    title: 'Przykładowe gry',
    type: Redirect,
    from: '/',
    to: routePath.dgames.index(''),
    exact: true,
  },
  {
    title: 'Przykładowe gry',
    type: Redirect,
    from: '/*/',
    to: routePath.dgames.index(''),
    exact: true,
  },
  {
    type: Route,
    component: NotFound,
    path: '/*',
  },
]

export const DiagnosticGames = (props) => {
  const { match } = props
  return <Page routes={generateRoutes(prefixPaths(match.url, routes(), ['path', 'from', 'to']))} />
}
