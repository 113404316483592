import React, { useEffect } from 'react'
import ico from 'assets/ico-1.svg'
import { useSelector, useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import {
  selectChecked,
  //selectFinishedAt,
  //selectPoints,
  selectVerdict,
  selectInquiryId,
  selectChildId,
  restart,
} from '_reducers/quizSlice'
import { clearData } from '_reducers/newGameSlice'
//import { selectPointsG } from '_reducers/newGameSlice'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Link } from 'react-router-dom'

export const TempResults = () => {
  const checked = useSelector(selectChecked)
  //const finishedAt = useSelector(selectFinishedAt)
  //const pointsQ = useSelector(selectPoints)
  //const points = useSelector(selectPointsG)
  const verdict = useSelector(selectVerdict)
  const childId = useSelector(selectChildId)
  const inquiryId = useSelector(selectInquiryId)
  const dispatch = useDispatch()

  const { isLogged } = useSelector((state) => state.auth)

  const handleSubmit = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/childInquiry`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        inquiryId: inquiryId,
        Child: `/child/${childId}`,
        Answers: checked,
      }),
    }).then((res) => {
      if (res.ok) {
        notification('success', 'Wysłano ankietę', 'Komunikat')
        return res.json()
      } else {
        notification('error', 'Błąd, spróbuj ponownie', 'Błąd')
      }
      throw res.json()
    })
  }

  useEffect(() => {
    isLogged && handleSubmit()
    dispatch(clearData())
    dispatch(restart())
  }, [])

  return (
    <>
      <Breadcrumbs color="purple" ico={ico} title="Ankieta obszarów ryzyka" backHome={true} />
      <div className="survey">
     
            {/*<div className="survey__nav survey__nav--center s-top-nav">
              <span className="s-top-nav__counter">
                Twój wynik: {pointsQ} z ankiety + {points} z gier pkt. <br />
              </span>
        </div>*/}

            <div className="survey__nav survey__nav--center s-top-nav">
              <span className="s-top-nav__counter">
                {verdict.title}
                <br />
                <br />
                {verdict.text}
              </span>
            </div>

            {verdict.showHealthCenterList && (
              <Link to="/institutions" className="btn survey__btn survey__btn--m0auto btn--no-fill">
                Pokaż listę ośrodków
              </Link>
            )}
            {isLogged && (
              <Link to="/results/survey" className="btn survey__btn btn--no-fill">
                Zobacz historię wyników
              </Link>
            )}
        
      </div>
    </>
  )
}
