import React, { useState } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import Skeleton from '@material-ui/lab/Skeleton'
import Alert from '@material-ui/lab/Alert'
import Tooltip from '@material-ui/core/Tooltip'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import Link from '@material-ui/core/Link'
import { translate } from '_helpers/translate'
import classNames from 'classnames'

export default function TableList({
  data,
  isLoading,
  error,
  mainData,
  dataLength,
  pagination,
  queryParams,
  setQueryParams = () => {},
}) {
  const [page, setPage] = useState(queryParams.page > 0 ? queryParams.page - 1 : 0)
  const [rowsPerPage, setRowsPerPage] = useState(queryParams.perPage ? queryParams.perPage : 12)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({
      ...queryParams,
      page: newPage + 1,
    })
  }

  const handleChangeOrder = (event, newOrder, dir = 'ASC') => {
    event.preventDefault()
    let uqueryParams = queryParams

    if (uqueryParams[`order[${newOrder}]`]) {
      if (uqueryParams[`order[${newOrder}]`] === 'ASC') {
        uqueryParams[`order[${newOrder}]`] = 'DESC'
      } else {
        uqueryParams[`order[${newOrder}]`] = 'ASC'
      }
    } else {
      Object.keys(uqueryParams).map((i) => {
        if (i.indexOf('order') > -1) {
          delete uqueryParams[i]
        }
        return null
      })
      uqueryParams[`order[${newOrder}]`] = dir
    }
    setQueryParams({
      ...uqueryParams,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {mainData.slice(0, mainData[mainData.length - 1].name).map((x, i) => {
              const xkey = x.key.substring(1)
              return (
                <TableCell key={`thc-${i}`}>
                  <Tooltip title={translate('T_SORT_ASC_DESC')}>
                    <Link
                      href="#"
                      onClick={(e) => {
                        handleChangeOrder(e, xkey)
                      }}
                      rel="noreferrer"
                      color="inherit"
                    >
                      <span style={{ verticalAlign: 'middle' }}>{x.name}</span>
                      {queryParams[`order[${xkey}]`] && queryParams[`order[${xkey}]`] === 'DESC' && (
                        <ArrowDropDownIcon style={{ verticalAlign: 'middle' }} />
                      )}
                      {queryParams[`order[${xkey}]`] && queryParams[`order[${xkey}]`] === 'ASC' && (
                        <ArrowDropUpIcon style={{ verticalAlign: 'middle' }} />
                      )}
                    </Link>
                  </Tooltip>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading &&
            [...Array(rowsPerPage)].map((i, key) => (
              <TableRow key={key}>
                <TableCell colSpan={6} key={key}>
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            ))}
          {error && (
            <TableRow>
              <TableCell colSpan={6} style={{ padding: 0 }}>
                <Alert severity="error">some error occurred, while fetching api</Alert>
              </TableCell>
            </TableRow>
          )}
          {!isLoading &&
            data &&
            data.map((row, index) => {
              return (
                <TableRow key={`${index}_row`} className={classNames('table-row', row.solved && 'solved')}>
                  <TableCell key={`${index}_0`}>{row.Practice.title}</TableCell>

                  <TableCell key={`${index}_1`}>{new Date(row.startedAt).toLocaleDateString('en-CA')}</TableCell>

                  <TableCell key={`${index}_2`}>{new Date(row.solvedAt).toLocaleDateString('en-CA')}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[1, 2, 12]}
          labelRowsPerPage={translate('T_SHOW_ON_PAGE')}
          component="div"
          count={dataLength !== undefined ? dataLength : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  )
}
