import { menuConstants } from '_constants'

function toggleMobileNavigationMenu() {
  return {
    type: menuConstants.TOOGLE_MOBILE_NAVIGATION_MENU,
  }
}
function hideMobileNavigationMenu() {
  return {
    type: menuConstants.HIDE_MOBILE_NAVIGATION_MENU,
  }
}

function toggleMobileNavigationLogin() {
  return {
    type: menuConstants.TOOGLE_MOBILE_NAVIGATION_LOGIN,
  }
}

function hideMobileNavigationLogin() {
  return {
    type: menuConstants.HIDE_MOBILE_NAVIGATION_LOGIN,
  }
}

function toggleMobileNavigationRegister() {
  return {
    type: menuConstants.TOOGLE_MOBILE_NAVIGATION_REGISTER,
  }
}

function hideMobileNavigationRegister() {
  return {
    type: menuConstants.HIDE_MOBILE_NAVIGATION_REGISTER,
  }
}

function showMobileNavigationRegister() {
  return {
    type: menuConstants.SHOW_MOBILE_NAVIGATION_REGISTER,
  }
}

function setRoles(roles) {
  return {
    type: menuConstants.SET_ROLES,
    payload: roles,
  }
}

export const menuActions = {
  toggleMobileNavigationMenu,
  hideMobileNavigationMenu,
  toggleMobileNavigationLogin,
  hideMobileNavigationLogin,
  toggleMobileNavigationRegister,
  hideMobileNavigationRegister,
  showMobileNavigationRegister,
  setRoles
}
