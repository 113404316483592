import React from 'react'
import accessdenied from 'assets/access-denied.svg'
import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <>
      <div className="container">
        <h2 className="page-heading">404 Nie znaleziono strony</h2>
        <img src={accessdenied} alt="" />
        <div className="copy">
          <a href="https://iconscout.com/illustrations/website-development" target="_blank" rel="noreferrer">
            Website development Illustration
          </a>
          by
          <a href="https://iconscout.com/contributors/delesign" target="_blank" rel="noreferrer">
            Delesign Graphics
          </a>
        </div>
        <Link to="/" className="btn form__btn">
          Powrót na stronę główną
        </Link>
      </div>
    </>
  )
}
