import React from 'react'
import { GamesCategoryList } from 'components/GamesCategoryList'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-2-1.svg'
export const Observation = () => {
  const ids = ['GAME_IDENTIFICATION', 'GAME_SORTING', 'GAME_MAPPING']
  const idsValues = [1, 2, 3]
  const routes = {
    GAME_IDENTIFICATION: { url: '/diagnostic-games/observation/game-identification/:id', name: 'Identyfikacja' },
    GAME_SORTING: { url: '/diagnostic-games/observation/game-sorting/:id', name: 'Sortowanie przedmiotów' },
    GAME_MAPPING: { url: '/diagnostic-games/observation/game-mapping/:id', name: 'Odwzorowywanie układów' },
  }

  return (
    <div className="game-area">
      <Breadcrumbs color="green" ico={ico} title="Bystre oko" />
      <GamesCategoryList ids={ids} routes={routes} idsValues={idsValues} />
    </div>
  )
}
