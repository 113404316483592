import React, { useRef, useEffect } from 'react'
import { DragDropContainer } from 'react-drag-drop-container'
export const Food = (props) => {
  const fooRef = useRef(null)

  useEffect(() => {
    if (props.clear) {
      fooRef.current.containerElem.style.visibility = 'visible'
    }
  }, [props.clear])
  const landedOn = () => {}
  return (
    <DragDropContainer
      targetKey={props.targetKey}
      dragClone={props.dragClone || false}
      dragData={{ label: props.label, tastes: props.tastes, name: props.name }}
      onDrop={landedOn}
      render={() => {
        return <img src={props.image} alt={props.name} />
      }}
      ref={fooRef}
    />
  )
}
