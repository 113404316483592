import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import Grid from '@material-ui/core/Grid'
import { translate } from '_helpers/translate'
import { useStyles } from 'components/form/FormStyles'
import add from 'assets/add.svg'
import classNames from 'classnames'

export const MediaUpload = ({ mediaType, uploadUrl, uploadName, photoUrl, audioUrl, videoUrl }) => {
  const audioRef = useRef()
  const videoRef = useRef()

  const classes = useStyles()
  const dispatch = useDispatch()

  const [state, setState] = useState({
    values: {},
    errors: {},
    validators: {},
    errorMessageSetters: {},
    invalid: 0,
    isProcessing: false,
    mediaFile: '',
    photoUrl: photoUrl,
    audioUrl: audioUrl,
    videoUrl: videoUrl,
    preview: false,
  })

  const fields = {
    title: {
      type: 'file',
      validate: ['required'],
      label: translate('T_FILE_UPLOAD_LABEL'),
      hint: translate('T_FILE_UPLOAD_HINT'),
    },
  }

  useEffect(() => {
    if (mediaType === 'TYPE_AUDIO') {
      audioRef.current.pause()
      audioRef.current.load()
    }

    if (mediaType === 'TYPE_VIDEO') {
      videoRef.current.pause()
      videoRef.current.load()
    }
  }, [state])

  const handleSubmit = (e) => {
    e && e.preventDefault()

    if (
      Object.keys(state.validators).length &&
      !Object.keys(state.validators).reduce(
        (status, name) =>
          state.validators[name].validator(
            ...Object.keys(state.values)
              .filter((item) => state.validators[name].values.includes(item))
              .map((item) => state.values[item])
          ) && status,
        true
      )
    ) {
      return
    }

    setState((state) => ({ ...state, isProcessing: true }))

    var formData = new FormData()
    formData.append(uploadName, state.mediaFile)

    fetchDataHandleAuthError(
      uploadUrl,
      'POST',
      { body: formData },
      (resp) => {
        setState((state) => ({
          ...state,
          isProcessing: false,
          photoUrl: resp.photoThumbs.full ? resp.photoThumbs.full : null,
          audioUrl: resp.audioUrl,
          videoUrl: resp.videoUrl,
          preview: false,
        }))

        dispatch(requestActions.success())
        notification('success', translate('T_FILE_UPLOADED_SUCCESS'), translate('T_SAVED'))
      },

      (error) => {
        setState((state) => ({ ...state, isProcessing: false }))
        notification(
          'error',
          error.response.violations.length ? error.response.violations[0].message : error.response.detail,
          error.response.title
        )
      },
      dispatch,
      requestConstants.FAILURE,
      { 'Content-Type': 'multipart/form-data' }
    )
    return false
  }

  const handleMediaFileChange = (event) => {
    const files = Array.from(event.target.files)

    setState((state) => ({ ...state, mediaFile: files[0] }))

    var reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.onload = function (e) {
      setState((state) => ({
        ...state,
        photoUrl: e.target.result,
        preview: true,
      }))
    }
  }

  useEffect(() => {
    state.mediaFile && handleSubmit()
  }, [state.mediaFile])

  const noPhotoSrc = '/images/no-photo.png'

  return (
    <React.Fragment>
      <Grid container spacing={0}>
        <form className={classes.root}>
          <Grid container spacing={0}>
            <div className={classNames('profile-form__grid', mediaType !== 'TYPE_PHOTO' && 'wrap')}>
              {Object.keys(fields).map((name, key) => {
                if (!fields[name]) return null

                return (
                  <>
                    {fields[name].type === 'file' && (
                      <label className="profile__add profile__add--sm">
                        <input type="file" onChange={handleMediaFileChange} />
                        <img src={add} alt="" /> Wybierz plik
                      </label>
                    )}
                  </>
                )
              })}

              <>
                {mediaType === 'TYPE_PHOTO' && (
                  <img
                    className="profile-form__photo"
                    src={
                      state.preview
                        ? state.photoUrl
                        : state.photoUrl
                        ? process.env.REACT_APP_API_ENTRYPOINT + state.photoUrl
                        : process.env.REACT_APP_API_ENTRYPOINT + noPhotoSrc
                    }
                    height="150"
                    alt=""
                  />
                )}

                {mediaType === 'TYPE_AUDIO' && (
                  <audio className="profile-form__photo" height="150" controls ref={audioRef}>
                    <source src={process.env.REACT_APP_API_ENTRYPOINT + state.audioUrl} type="audio/mp3" />
                  </audio>
                )}

                {mediaType === 'TYPE_VIDEO' && (
                  <video className="profile-form__photo" height="150" controls ref={videoRef}>
                    <source src={process.env.REACT_APP_API_ENTRYPOINT + state.videoUrl} type="video/mp4" />
                  </video>
                )}
              </>
            </div>
          </Grid>
        </form>
      </Grid>
    </React.Fragment>
  )
}
