import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import TablePagination from '@material-ui/core/TablePagination'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Link from '@material-ui/core/Link'
import { Link as RouterLink } from 'react-router-dom'
import { translate } from '_helpers/translate'
import { AlertDialog } from 'components/AlertDialog'
import { requestActions } from '_actions'
import { requestConstants } from '_constants'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { element as elementSchema } from '_schema/element'
import Skeleton from '@material-ui/lab/Skeleton'

export default function TableList({
  data,
  isLoading,
  dataLength,
  pagination,
  queryParams,
  setQueryParams = () => {},
  gameId,
}) {
  const [page, setPage] = useState(queryParams.page > 0 ? queryParams.page - 1 : 0)
  const [rowsPerPage, setRowsPerPage] = useState(queryParams.perPage ? queryParams.perPage : 12)
  const [open, setOpen] = useState(false)

  const [rowId, setRowId] = useState(null)
  const dispatch = useDispatch()

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setQueryParams({
      ...queryParams,
      page: newPage + 1,
    })
  }

  const handleChangeRowsPerPage = (event) => {
    const perPage = +event.target.value
    setRowsPerPage(perPage)
    setPage(0)
    setQueryParams({
      ...queryParams,
      perPage: perPage,
      page: 1,
    })
  }

  // dialog z usunieciem - otwarcie
  const handleOpenDialogDelete = (event, row) => {
    event.preventDefault()
    setOpen(true)
    setRowId(row)
  }

  // dialog z usunieciem - zamkniecie || akcja == CANCEL
  const handleDialogClose = () => {
    setOpen(false)
    setRowId(null)
  }

  // dialog z usunieciem - akcja == OK
  const handleDialogAgreeDelete = () => {
    const url = `${elementSchema.delete.replace('{id}', rowId)}`
    const method = 'DELETE'

    dispatch(requestActions.start(url))

    fetchDataHandleAuthError(
      url,
      method,
      { body: [] },
      () => {
        dispatch(requestActions.success())
        notification('success', translate('T_RECORD_DELETED'), translate('T_SAVED'))
        setPage(0)
        setQueryParams({
          ...queryParams,
          rowdeleted: rowId,
          page: 1,
        })
      },
      (error) => {
        notification('error', translate('T_INCORRECT_FORM'), translate('T_RECORD_DELETE_ERROR'))
      },
      dispatch,
      requestConstants.FAILURE
    )

    handleDialogClose()
  }

  return (
    <React.Fragment>
      <div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(158px, 1fr)',
            gap: '10px',
            margin: '20px 0',
          }}
        >
          {isLoading &&
            [...Array(rowsPerPage)].map((i, key) => (
              <Skeleton key={key} animation="wave" variant="rect" height={189} />
            ))}
          {!isLoading &&
            data &&
            data.map((row, index) => {
              return (
                <React.Fragment key={`${index}_frag`}>
                  <div key={`${index}_row`} style={{ display: 'grid' }}>
                    <div key={`${index}_0`}>
                      <Link
                        component={RouterLink}
                        to={`/media-library/elements/${gameId}/${row.id}/edit`}
                        rel="noreferrer"
                      >
                        {row.type === 'TYPE_PHOTO' && (
                          <img
                            src={process.env.REACT_APP_API_ENTRYPOINT + row.photoThumbs['full']}
                            alt=""
                            style={{ width: '100%' }}
                          />
                        )}

                        {row.type === 'TYPE_VIDEO' && (
                          <div className="media-container">
                            <video preload="metadata" style={{ width: '100%' }}>
                              <source src={process.env.REACT_APP_API_ENTRYPOINT + row.videoUrl} type="video/mp4" />
                            </video>
                            <span className="media-name">{row.title}</span>
                          </div>
                        )}
                        {row.type === 'TYPE_AUDIO' && (
                          <div className="media-container">
                            <img src="/images/audio.png" alt="" style={{ width: '100%' }} />
                            <span className="media-name">{row.title}</span>
                          </div>
                        )}
                      </Link>
                    </div>

                    <div key={`${index}_6`} style={{ position: 'absolute', background: '#fff' }}>
                      <Tooltip title={translate('T_DELETE')}>
                        <Link
                          href="#"
                          onClick={(event) => {
                            handleOpenDialogDelete(event, row.id)
                          }}
                          rel="noreferrer"
                        >
                          <DeleteForeverIcon />
                        </Link>
                      </Tooltip>
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
        </div>
      </div>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[1, 2, 12]}
          labelRowsPerPage={translate('T_SHOW_ON_PAGE')}
          component="div"
          count={dataLength !== undefined ? dataLength : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      <AlertDialog
        key="del-dlg"
        open={open}
        handleClose={handleDialogClose}
        handleAgree={() => {
          handleDialogAgreeDelete()
        }}
        title={translate('T_ALERT_DELETE_TITLE')}
        text={translate('T_ALERT_DELETE_TEXT')}
      />
    </React.Fragment>
  )
}
