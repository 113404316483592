import React from 'react'
import { GamesCategoryList } from 'components/GamesCategoryList'
import { Breadcrumbs } from 'components/Breadcrumbs'
import ico from 'assets/ico-2-3.svg'
export const Identification = () => {
  const ids = ['GAME_EMOTIONS_IDENTIFICATION']
  const idsValues = [6]
  const routes = {
    GAME_EMOTIONS_IDENTIFICATION: {
      url: '/diagnostic-games/identification/game-emotions-identification/:id',
      name: 'Identyfikacja emocji',
    },
  }

  return (
    <div className="game-area">
      <Breadcrumbs color="green" ico={ico} title="Identyfikacja emocji" />
      <GamesCategoryList ids={ids} routes={routes} idsValues={idsValues} />
    </div>
  )
}
