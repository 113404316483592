import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { authHeader } from '_helpers/authHeader'
import { notification } from '_helpers/notification'
import add from 'assets/add.svg'
import addKid from 'assets/add-kid.svg'
import confirm from 'assets/confirm.svg'
import therapistToConfirm from 'assets/therapist-to-confirm.svg'
import therapistCurrent from 'assets/therapist-current.svg'
import close from 'assets/close.svg'
import { AlertDialog as ChildPhotoRemoveDialog } from 'components/AlertDialog'
import { AlertDialog } from 'components/AlertDialog'
import * as childSchema from '_schema/child'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { Breadcrumbs } from 'components/Breadcrumbs'
import icoLogin1 from 'assets/ico-login-1.svg'
import { userActions } from '_actions'
import { useHistory } from 'react-router-dom'

export const Add = ({ match, dataResource }) => {
  const {
    params: { childId },
  } = match

  const initialEmpty = {
    firstName: '',
    lastName: '',
    birthDate: '',
    gender: '',
    isSubmitting: false,
    errorMessage: null,
  }

  const history = useHistory()
  const url = childId ? childSchema.childs.set : childSchema.childs.add
  const method = childId ? 'PUT' : 'POST'

  const initialState = childId ? dataResource : initialEmpty

  const [data, setData] = useState(initialState)

  const handleInputChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    })
  }

  const handleFormSubmit = (event) => {
    event.preventDefault()
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    })
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}${url.replace('{id}',childId)}`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        firstName: data.firstName,
        lastName: data.lastName,
        birthDate: data.birthDate,
        gender: data.gender,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw res.json()
      })
      .then((res) => {
        notification('success', method === 'PUT' ? 'Zaktualizowano' : 'Dodano')
        setData(res)
        localStorage.setItem(
          'SelectedChild',
          JSON.stringify({
            firstName: data.firstName,
            lastName: data.lastName,
          })
        )
        dispatch(
          userActions.setChildId_R({
            firstName: data.firstName,
            lastName: data.lastName,
          })
        )
      })
      .catch((errorPromise) => {
        setData({
          ...data,
          isSubmitting: false,
          errorMessage: errorPromise.message || errorPromise.statusText,
        })
        errorPromise.then((error) => {
          if (error.violations) {
            let errors = error.violations.map((e) => {
              return JSON.stringify(`${e.propertyPath} : ${e.message}`)
            })

            errors = errors.join('\r\n')

            notification('error', errors, 'Błąd')
          }
        })
      })
  }

  const [open, setOpen] = useState(false)

  const noPhotoSrc = '/images/no-photo.png'

  const [childPhotoFile, setChildPhotoFile] = useState({})

  const initialPhoto = {
    src: data?.childPhotoThumbs?.full ? process.env.REACT_APP_API_ENTRYPOINT + data.childPhotoThumbs.full : noPhotoSrc,
    dialogOpen: false,
    child: {},
    canUpload: false,
    canRemove: false,
    removeDialogOpen: false,
  }
  const [childPhoto, setChildPhoto] = useState(initialPhoto)

  useEffect(() => {
    setData({ ...dataResource })
  }, [dataResource])

  useEffect(() => {
    setChildPhoto({ ...initialPhoto })
  }, [data])

  // dialog z usunieciem - otwarcie
  const handleOpenDialogDelete = (event) => {
    event.preventDefault()
    setOpen(true)
  }

  // dialog z usunieciem - zamkniecie || akcja == CANCEL
  const handleDialogClose = () => {
    setOpen(false)
  }

  // dialog z usunieciem - akcja == OK
  const handleDialogAgreeDelete = () => {
    const url = `${childSchema.childs.delete.replace('{id}', data?.id)}`

    fetchDataHandleAuthError(
      url,
      'DELETE',
      { body: [] },
      () => {
        notification('success', 'Rekord usunięty', 'Zapisano poprawnie')
        localStorage.setItem(
          'SelectedChild',
          JSON.stringify({})
        )
        dispatch(
          userActions.setChildId_R({})
        )
        history.push('/profile/add-child')

      },
      () => {
        notification('error', 'Niepoprawny formularz', 'Nie usunięto rekordu')
      }
    )
    handleDialogClose()
  }

  // CHILD PHOTO

  // wyświetlenie dialogu wgrywania foto
  const handleChildPhotoDialogOpen = (event, child) => {
    event.preventDefault()
    setChildPhoto({
      ...childPhoto,
      src: child?.childPhotoThumbs?.['full']
        ? process.env.REACT_APP_API_ENTRYPOINT + child.childPhotoThumbs['full']
        : noPhotoSrc,
      dialogOpen: true,
      child: child,
      canRemove: child?.childPhotoThumbs?.['full'] !== undefined,
      canUpload: false,
    })
  }
  const dispatch = useDispatch()
  // wgranie zdjęcia na serwer
  const handleChildPhotoUploadSubmit = () => {
    const url = `${childSchema.childs.photoUpload.replace('{childId}', childPhoto.child.id)}`
    var formData = new FormData()

    formData.append('childPhotoFile', childPhotoFile)

    fetchDataHandleAuthError(
      url,
      'POST',
      { body: formData },
      () => {
        notification('success', 'Zapisano poprawnie', 'Zapis')
        setChildPhoto({ ...childPhoto, canUpload: false, canRemove: true })
      },
      () => {
        notification('error', 'Wystąpił błąd zapisu', 'Błąd')
      },
      dispatch,
      '',
      { 'Content-Type': 'multipart/form-data' }
    )
  }
  // handler zmiany pola childPhotoFile
  const handleChildPhotoFileChange = (event) => {
    const files = Array.from(event.target.files)

    setChildPhotoFile(files[0])

    var reader = new FileReader()
    reader.readAsDataURL(files[0])
    reader.onload = function (e) {
      setChildPhoto({
        ...childPhoto,
        src: e.target.result,
        canUpload: files[0].name && files[0].name.length > 0,
        canRemove: files[0].name && files[0].name.length > 0,
      })
    }
  }

  // zamkniecie okienka wgrywania foto na serwer
  const handleChildPhotoDialogClose = () => {
    setChildPhoto({ ...childPhoto, dialogOpen: false })
  }

  // pokazanie dialogu z usunięciem foto
  const handleChildPhotoRemoveDialogAlert = () => {
    setChildPhoto({ ...childPhoto, removeDialogOpen: true })
  }

  // Zamknięcie dialogu z potwierdzeniem usunięcia foto
  const handleChildPhotoRemoveDialogClose = () => {
    setChildPhoto({ ...childPhoto, removeDialogOpen: false })
  }

  // dialog usuwania foto
  const handleChildPhotoRemoveDialogConfirm = () => {
    if (childPhoto.child.childPhotoThumbs['full'] !== undefined) {
      const url = `${childSchema.childs.photoDelete.replace('{childId}', childPhoto.child.id)}`

      fetchDataHandleAuthError(
        url,
        'POST',
        { body: {} },
        () => {
          setChildPhoto({
            ...childPhoto,
            src: noPhotoSrc,
            removeDialogOpen: false,
            canRemove: false,
            canUpload: false,
          })
          notification('success', 'Zapisano poprawnie', 'Zapis')
        },
        () => {
          setChildPhoto({ ...childPhoto, removeDialogOpen: false })
          notification('error', 'Wystąpił błąd zapisu', 'Błąd')
        }
      )
    } else {
      setChildPhoto({
        ...childPhoto,
        src: noPhotoSrc,
        removeDialogOpen: false,
        canUpload: false,
        canRemove: false,
      })
    }
  }

  const [childTherapies, setChildTherapies] = useState([])

  const getChildTherapies = () => {
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/childTherapies/${childId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setChildTherapies(res)
      })
  }

  useEffect(() => {
    childId && getChildTherapies()
  }, [])

  const confirmed = childTherapies?.content?.filter((cT) => cT.parentConfirmed === true)
  const notConfirmed = childTherapies?.content?.filter((cT) => cT.parentConfirmed === false)

  const confirmTherapist = (event, id) => {
    event.preventDefault()
    fetch(`${process.env.REACT_APP_API_ENTRYPOINT}/therapy/${id}/confirm`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...authHeader(),
      },
      body: JSON.stringify({
        parentConfirmed: true,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }
        throw res.json()
      })
      .then(() => {
        notification('success', 'Zatwierdzono', 'Komunikat')
        getChildTherapies()
      })
  }

  return (
    <>
      <Breadcrumbs color="orange" ico={icoLogin1} title="Profil - edycja" backHome={true} />
      <form className="profile-form form" noValidate >
        <span className="login-content__link">
          <img src={addKid} alt="" /> Dodawanie profilu dziecka
        </span>
        <div className="form__item">
          <input
            type="text"
            className="form__input"
            id="firstName"
            name="firstName"
            value={data?.firstName}
            onChange={handleInputChange}
          />
          <label className="form__label">Imię dziecka</label>
        </div>
        <div className="form__item">
          <input
            type="text"
            className="form__input"
            id="lastName"
            name="lastName"
            value={data?.lastName}
            onChange={handleInputChange}
          />
          <label className="form__label">Nazwisko dziecka</label>
        </div>
        <div className="profile-form__grid">
          <div className="form__item">
            <input
              type="date"
              className="form__input"
              id="birthDate"
              name="birthDate"
              value={new Date(data?.birthDate).toLocaleDateString('en-CA')}
              onChange={handleInputChange}
            />
            <label className="form__label">Data urodzenia</label>
          </div>
          <div className="form__item">
            <select
              className="custom-select profile-form__select"
              id="gender"
              name="gender"
              value={data?.gender}
              onChange={handleInputChange}
            >
              <option selected hidden>
                Wybierz
              </option>
              <option value="F">Dziewczynka</option>
              <option value="M">Chłopiec</option>
            </select>
            <label className="form__label">Płeć</label>
          </div>

          <span className="profile-form__grid-col-1">Zdjęcie dziecka</span>
          <img className="profile-form__photo" src={childPhoto.src} alt="" />
          <button type="button"
            disabled={!data?.id}
            className="profile__add profile__add--sm"
            onClick={(event) => {
              handleChildPhotoDialogOpen(event, data)
            }}
          >
            <img src={add} alt="" /> dodaj zdjęcie
          </button>

          {/*<button type="button" className="btn btn--no-fill">Anuluj</button>*/}
          <button type="button" className="btn btn--save" disabled={data?.isSubmitting} onClick={handleFormSubmit}>
            {data?.isSubmitting ? (
              <img className="spinner spinner--sm" src="/images/logo192.png" alt="loading icon" />
            ) : (
              'Zapisz'
            )}
          </button>

          {childId && (
            <button type="button"
              className="btn btn--light"
              onClick={(event) => {
                handleOpenDialogDelete(event)
              }}
            >
              usuń trwale
            </button>
          )}
        </div>
        {childId && (
          <>
            <div className="therapist">
              {notConfirmed?.length !== 0 && (
                <span className="login-content__link therapist__group-name">
                  <img src={therapistToConfirm} alt="" /> Terapeuci do zatwierdzenia
                </span>
              )}
              {notConfirmed?.map((item) => (
                <>
                  <div className="therapist__description" key={item.id}>
                    <b>
                      {item.Therapist.firstName} {item.Therapist.lastName}
                    </b>
                    <br />
                    Telefon: +48 {item.Therapist.phone} <br />
                    {item.Therapist.HealthCenter?.Province.title}
                    <br />
                    <br />
                    <b>Ośrodek:</b> <br />
                    {item.Therapist.HealthCenter?.name} <br />
                    {item.Therapist.HealthCenter?.address} <br />
                    {item.Therapist.HealthCenter?.postcode} {item.Therapist.HealthCenter?.city}
                    <br />
                    Telefon: {item.Therapist.HealthCenter?.phone} <br />
                    Email: {item.Therapist.HealthCenter?.email}
                    <br />
                  </div>

                  <button type="button"
                    className="therapist__btn therapist__btn--confirm"
                    onClick={(event) => confirmTherapist(event, item.id)}
                  >
                    <img src={confirm} alt="" /> Zatwierdź
                  </button>
                </>
              ))}
            </div>
            <div className="therapist">
              <span className="login-content__link therapist__group-name">
                <img src={therapistCurrent} alt="" /> Terapeuci Bieżący
              </span>
              {confirmed?.map((item) => (
                <div className="therapist__description" key={item.id}>
                  <b>
                    {item.Therapist.firstName} {item.Therapist.lastName}
                  </b>
                  <br />
                  Telefon: +48 {item.Therapist.phone} <br />
                  {item.Therapist.HealthCenter?.Province.title}
                  <br />
                  <br />
                  <b>Ośrodek:</b> <br />
                  {item.Therapist.HealthCenter?.name} <br />
                  {item.Therapist.HealthCenter?.address} <br />
                  {item.Therapist.HealthCenter?.postcode} {item.Therapist.HealthCenter?.city}
                  <br />
                  Telefon: {item.Therapist.HealthCenter?.phone} <br />
                  Email: {item.Therapist.HealthCenter?.email}
                  <br />
                </div>
              ))}
            </div>
          </>
        )}
      </form>

      <div className={`popup ${childPhoto.dialogOpen ? 'shown' : ''}`}>
        <img className="popup__close" src={close} alt="" onClick={handleChildPhotoDialogClose} />
        <p className="popup__text">
          <b>{'Wgrywanie zdjęcia dziecka'}</b>
          <br />
          {'Wybierz plik ze zdjęciem'}
        </p>

        <label className="btn btn--green btn--upload">
          <input type="file" accept="image/*" onChange={handleChildPhotoFileChange} />
          Wybierz plik
        </label>

        <img className="profile-form__photo popup__photo" src={childPhoto.src} alt="" />

        <div className="popup__btns">
          <button type="button" className="btn btn--no-fill" onClick={handleChildPhotoDialogClose}>
            {'Zamknij'}
          </button>
          <button type="button" className="btn" onClick={handleChildPhotoUploadSubmit} disabled={!childPhoto.canUpload}>
            {'Zapisz'}
          </button>
          <button type="button"
            className="btn btn--light"
            onClick={handleChildPhotoRemoveDialogAlert}
            disabled={!childPhoto.canRemove}
          >
            {'Usuń'}
          </button>
        </div>
      </div>

      <ChildPhotoRemoveDialog
        open={childPhoto.removeDialogOpen}
        handleClose={handleChildPhotoRemoveDialogClose}
        handleAgree={handleChildPhotoRemoveDialogConfirm}
        title={'Usuwanie zdjęcia'}
        text={'Czy na pewno usunąć zdjęcie?'}
      />

      <AlertDialog
        open={open}
        handleClose={handleDialogClose}
        handleAgree={() => {
          handleDialogAgreeDelete()
        }}
        title={'Usuwanie'}
        text={'Czy na pewno chcesz usunać wybrany rekord ?'}
      />
    </>
  )
}
